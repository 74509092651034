.scroll-arrow {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  animation: fadeBounce 2.5s infinite;
}
.rentPlatformContentOuterCon {
  width: 100%;
  padding: 40px 22%;
  line-height: 1.6;
}
.headerTitleRentPlatform {
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.RentPlatformHeadIcon {
  color: #ffa500;
  opacity: 0.8;
  font-size: 50px;
  margin-bottom: 0;
  animation: fadeInUp 0.5s ease-in-out;
}

.RentPlatformHeadIcon1 {
  color: white;
  opacity: 0.8;
  font-size: 25px;
  margin-bottom: 0;
  animation: fadeInUp 0.5s ease-in-out;
}

.headerTitleRentPlatform h4 {
  font-family: "DM Serif Display", serif;
  font-size: 70px;
  color: #ffa500;
  opacity: 1;
  margin-bottom: 0;
  padding: 0;
  animation: fadeInUp 0.5s ease-in-out 0.5s;
  animation-fill-mode: both;
  text-align: center;
}

.headerTitleRentPlatform p {
  margin-top: 0;
  font-size: 22px;
  text-align: center;
  animation: fadeInUp 0.5s ease-in-out 1s;
  animation-fill-mode: both;
}

.consultant {
  animation: fadeInUp 0.5s ease-in-out 1s;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(140px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeBounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }

  40% {
    opacity: 1;
    transform: translateY(0);
  }
}
.rentPlatformHeadCon {
  width: 100%;
  height: 45vh;
  padding-top: 40px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: lightgray;
}

.downArrowCon {
  position: absolute;
  width: 100%;
  height: 47vh;
}

.rentSaleAddLink {
  position: absolute;
  left: 15%;
  margin-top: 20px;
}

.rentSaleAddLink a {
  color: #035aa6;
  font-weight: bold;
  cursor: pointer;
}

.RentSaleSearchInputs {
  width: 100%;
  padding: 30px 200px;
  display: flex;
  justify-content: space-between;
}
.RentSaleSearchInputs .firstinputCon {
  width: 50%;
}

.RentSaleSearchInputs .firstinputCon select {
  width: 50%;
  cursor: pointer;
  /* padding: '30px 15px', */
  font-size: 20px;
  font-weight: 700;
  color: black;
  opacity: 0.7;
  height: 60px;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 255, 0.2);
}

.RentSaleSearchInputs .secondInputCon {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.RentSaleSearchInputs .secondInputCon input {
  width: 70%;
  padding: 30px 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 255, 0.2);
}

.searchIconContainer {
  width: 10%;
  height: 100%;
  background: #035aa6;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rentSaleH1Topic {
  text-align: center;
  font-weight: bold;
  font-family: "DM Serif Display", serif;
  letter-spacing: 5px;
  margin: 50px 0 0 0;
  opacity: 0.8;
}

.rentSaleBodyCon {
  width: 100%;
  padding: 10px 200px 50px 200px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
}

.rentSaleBodyCon .theDisplayContainer {
  width: 50%;
}

.rentSaleDisplayContainer {
  width: 96%;
  box-shadow: 6px 12px 12px 12px #00000029;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
  flex-direction: column;
}

.rentSaleDisplayContainer .imageContainer {
  width: 100%;
  height: 500px;
}

.rentSaleContentContainer {
  width: 100%;
  height: 200px;
  padding: 20px 10px;
}

.rentSaleContentContainer h2 {
  font-weight: bold;
  font-size: 25px;
  opacity: 0.9;
  word-spacing: 5px;
  letter-spacing: 1px;
}

.rentSaleContentContainer p {
  line-height: 2;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
}

.rentSaleFooter {
  width: 100%;
  background: grey;
  opacity: 0.5;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.rentSaleDateCon {
  margin-left: 7px;
  color: #035aa6;
  font-weight: 700px;
  opacity: 1;
}
.rentSaleScom {
  color: white;
  opacity: 1;
  margin: 0 7px;
}

.rentSaleIconDelete {
  color: red;
  opacity: 0.8;
  font-size: 16px;
  z-index: 5;
  cursor: pointer;
  right: 5px;
}

.rentSaleIconEdit {
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-left: 2px;
}

@media (max-width: 991px) {
  .rentPlatformContentOuterCon {
    padding: 40px 1%;
  }
  .rentPlatformHeadCon {
    height: 60vh;
  }
  .downArrowCon {
    height: 60vh;
  }

  .headerTitleRentPlatform h4 {
    font-size: 20px;
  }
  .headerTitleRentPlatform p {
    font-size: 16px;
  }
  .RentPlatformHeadIcon {
    font-size: 25px;
  }

  .rentSaleBodyCon {
    flex-direction: column;
    padding: 0px 0px 0px 0px;
  }
  .rentSaleBodyCon .theDisplayContainer {
    width: 100%;
  }

  .rentSaleDisplayContainer .imageContainer {
    height: 300px;
  }
  .rentSaleDisplayContainer {
    width: 100%;
  }
  .rentSaleContentContainer h2 {
    font-size: 16px;
  }

  .rentSaleContentContainer p {
    font-size: 14px;
  }

  .RentSaleSearchInputs {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
  }

  .RentSaleSearchInputs .firstinputCon {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }
  .RentSaleSearchInputs .firstinputCon select {
    width: 100%;
  }
  .RentSaleSearchInputs .secondInputCon {
    width: 100%;
    padding: 0 10px;
  }
  .RentSaleSearchInputs .secondInputCon input {
    justify-content: center;
  }
  .searchIconContainer {
    width: 30%;
    padding: 20px 15px;
  }
}
