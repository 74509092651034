
.propHeadContainer h1{
opacity: 1;
font-weight: bolder;
font-family: 'DM Serif Display', serif;  
font-size: 45px;
color: white;
margin-bottom: 0;
}
.propManListCon{
 width: 100%; 
 padding: 5px 22% 
}
.propHeadContainer{
background: black;
width: 100%;
height: 40vh;
position: absolute;
opacity: 0.8;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 0 20%;
text-align: center                           
}
.propHeadContainer .icon{     
color: white;
font-size: 70px;
opacity: 0.8;
margin-right: 7px                           
}
.propHeadContainer h2{
opacity: 1;
font-weight: bolder;
font-family: 'DM Serif Display', serif;  
font-size: 45px;
color: white;
margin-top: 0;
font-style: italic;
}

.propBodyTitle{
width: 100%;
padding: 0 15%;
font-size: 22px;
color: #035aa6;
text-align: center;
}

.propBodyTitle p::before{
content: open-quote;   
color: #035aa6;    
font-size: 30px;  
}
.propBodyTitle p::after{
content: close-quote;   
color: #035aa6;    
font-size: 30px;                  
}
          
.PropManHeadOuterCon{
 width: 100%;
 height: 40vh;
 background: black
}

.propManArrowCon{
position: absolute;
width: 100%;
height: 50vh
}

.propManBodyCon{       
width: 100%;
padding: 50px 10px;
background: white;
opacity: 0.8;
                       
}
.propManCardOuterCon{     
width: 70%;
height: 300px;
margin: 0 auto 20px auto;
display: flex;
flex-direction: rows;
/* box-shadow: 3px 6px 6px 6px #00000029;
border: 1px solid #00000029; */
border-radius: 5px;
overflow: hidden;
}
.propManCardOuterCon1{
width: 70%;
height: 300px;
margin: 0 auto 20px auto;
display: flex;
flex-direction: rows;
/* box-shadow: 3px 6px 6px 6px #00000029;
border: 1px solid #00000029; */
border-radius: 5px;
overflow: hidden;               
}

.propManCardOuterCon .imageCon{
width: 32%;
padding: 10px;
box-shadow:0 0 10px 10px #00000029;
border: 1px solid #00000029;
}
.propManCardOuterCon1 .imageCon{
width: 32%;
padding: 10px;
box-shadow:0 0 10px 10px #00000029;
border: 1px solid #00000029;             
}

.propManCardContentCon{
width: 68%;
padding-top: 20px;
padding-left: 40px;
padding-right: 20px;
padding-bottom: 20px;
display: flex;
justify-content: center;
align-items: center              
}

.propManJoinCon{      
width: 100%;
display: flex;
justify-content: center;
flex-direction: column;
background-position: center center;
background-repeat: no-repeat;
background-size: cover;      
}

.propManJoinInnerCon{
width: 100%;
height: 500px;
background: black;
opacity: 0.9;                
padding: 20px 20%;
color: white;
}
.propManJoinInnerCon h2{
color: white;
text-align: center;
margin-bottom: 20px
}
.propManJoinInnerCon a{      
margin: 70px 33%;
background: #035aa6;
color: white;
padding: 10px 25px;
border-radius: 10px                 
}

.propManJoinInnerCon button:hover{
border: 1px solid #035aa6;
background: white;
color:#035aa6
}

@media (max-width: 991px) {
.propManListCon{
width: 100%; 
/* padding: 50px 1%  */
 }
.PropManHeadOuterCon{
height: 60vh;
}
.propHeadContainer{
padding: 50px 2%;
height: 60vh;
}
.propHeadContainer h1{
font-size: 20px;
}
.propHeadContainer h2{
font-size: 20px;
}
.propHeadContainer .icon{
font-size: 35px;
}

.propBodyTitle{
width: 100%;
padding: 0 2%;
font-size: 16px;
color: #035aa6;
text-align: center;
}

.propManCardOuterCon{     
width: 100%;
height: auto;
margin: 0 auto 20px auto;
display: flex;
flex-direction: column;
/* box-shadow: 3px 6px 6px 6px #00000029;
border: 1px solid #00000029; */
border-radius: 5px;
overflow: hidden;
}
.propManCardOuterCon1{     
width: 100%;
height: auto;
margin: 0 auto 20px auto;
display: flex;
flex-direction: column-reverse;
box-shadow: 3px 6px 6px 6px #00000029;
border: 1px solid #00000029;
border-radius: 5px;
overflow: hidden;
}
.propManCardOuterCon .imageCon{
width: 100%;
}
.propManCardOuterCon1 .imageCon{
                width: 100%;
                }
.propManCardContentCon{
width: 100%;
font-size: 14px;
padding: 5px;
text-align: center;
}

.propManJoinInnerCon{
width: 100%;
height: auto;
background: black;
opacity: 0.9;                
padding: 20px 2%;
}
.propManJoinInnerCon button{      
margin: 70px 5%;
background: #035aa6;
color: white;
padding: 10px 25px;
border-radius: 10px                 
}
.propManArrowCon{
height: 70vh;
}         
}
