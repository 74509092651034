.newsroomHeadingTextContainer h1{        
font-size: 70px;
color: #035aa6;
font-family: 'DM Serif Display', serif;
margin-bottom: 0;
text-align: center;            
}

.newsroomHeadingTextContainer{
flex: 5;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
height: 30vh;
}

.newsroomHeadingTextContainer b{
font-size: 18px;
}

.newsroomOuterCon{
margin: 45px 0;
padding: 0px 0;
font-family: "Montserrat", sans-serif;
}

.newsHeadOuterCon{
width: 100%;
height: 40vh;
background-position: center center;
background-repeat: no-repeat;
background-size: cover;
}

.newsHeadArrowDown{
position: absolute;
width: 100%;
height: 45vh;
}

.newsHeadContentCon{
width: 100%;
height: 40vh;
display: flex;
text-align: center;
padding: 0 20%;
}

.latestPressOuterCon{
width: 100%;
padding: 20px 10%
}

.latestPressInnerCon{
width: 100%; 
padding: 30px; 
display: flex
}

.newListItemOutercon{
width:30% ;
}

.newsListItemLink{
background-color: #035aa6;
color: white;
display: flex;
align-items: center;
flex-direction: row;
padding: 7px;
text-decoration: none;               
}

.newsListItemLink1{
background-color: white;
color: #035aa6;
display: flex;
align-items: center;
flex-direction: row;
padding: 7px;
text-decoration: none;
}

.newsListItemLink .icon, .newsListItemLink1 .icon{
font-size: 16px;
margin-bottom: 20px;
}

.pressReleaseLiCon{
padding: 0;
margin-top: 2px
}

.newsListingCon{
padding-left: 30px;
}

.newsListingCon h3{
text-align: center;
opacity: 0.8
}

.newsItemsCon{
width: 100%;
margin-top: 20px 
}

.newsItemsCon b{
opacity: 0.9;
display: block 
}

.newsLoadingCon{
width: 100%;
height: 100%;
background: black;
opacity: 0.7;
z-index: 5;
position: fixed;
display: flex;
justify-content: center;
align-items: center;
padding: 0 20%;
}

.newsOpenModal{
position: absolute;
right: 10px; 
top: 65vh; 
}

.newsOpenModal .icon{
color: black;
font-size: 20px;
opacity: 0.8;
margin-right: 7px;
}

.newsAddOuterCon{
width: 70%;
margin-left: 15%;
padding: 20px;
display: flex;
}

.newsInputAddCon{
width: 98%;
margin-left: 1%;
background-color: #035aa6;
border-radius: 10px;
padding: 0 50px 20px 50px;
color: white;
}

.newsAddOuterCon h1{
font-family: 'DM Serif Display', serif;
font-size: 25px;
margin: 30px auto 20px auto;
text-align: center;
}

.newsroomAddBtnSubmit{
width: 50%;
margin: 20px 25%;
background-color: #035aa6;
color: white;
border: 1px solid white;
font-weight: bold;
}

.newsAddError{
text-align: center; 
color: red; 
opacity: 0.8
}

.newsAddPlus{
padding: 5px;
margin: 10px 0;
}

.newsAddPlus .icon{
color: #035aa6;
opacity: .7;
font-size: 30px;
}

.newsMinusBtn{
padding: 20px;
margin: 10px 10px 10px 10px;
}

.newsMinusBtn .icon{
color: red;
opacity: 0.7;
font-size: 30px;
cursor: pointer;
}

.newsAwareCon{
margin: 45px 0;
padding: 0px 0;
font-family: 'Montserrat',sans-serif;
}

.newsroomAddOuterCon{
margin: 50px 10%;
display: flex;
width: 70%;
font-family: 'Montserrat', sans-serif
}

@media (max-width: 991px) {
                .newsInputAddCon{
                                width: 100%;
                 
                                background-color: #035aa6;
                                border-radius: 10px;
                                padding: 0 5px 20px 5px;
                                color: white;
                                margin-top: 50px;
                                }
                                .newsOpenModal{
                                                position: absolute;
                                                right: 10px; 
                                                top: 42vh; 
                                                }
 
.newsHeadContentCon{
width: 100%;
height: 60vh;
display: flex;
text-align: center;
padding: 20px 1%;
}

.newsroomHeadingTextContainer h1{        
font-size: 22px;
 }

 .newsroomHeadingTextContainer b{        
font-size: 15px;
}
.newsHeadArrowDown{
position: absolute;
width: 100%;
height: 55vh;
}  

.latestPressInnerCon{
width: 100%; 
padding: 30px 0; 
display: flex;
flex-direction: column;
}
.newListItemOutercon{
width:100% ;
}


.newsroomAddOuterCon{
                margin: 50px 1%;
                display: flex;
                flex-direction: column;
                width: 98%;
                font-family: 'Montserrat', sans-serif
                }                            
}