.prevEnterLeaveEnter {
  width: 25%;
  position: fixed;
  right: 0;
  height: 87vh;
  background: white;
  border: 2px solid #035aa6;
  z-index: 5;
  border-bottom-left-radius: 10px;
  padding: 50px 5px 5px 5px;
  animation: fadeInShowLeftConfirm 0.6s ease-in-out;
}

.prevEnterLeaveLeave {
  width: 25%;
  position: fixed;
  right: 0;
  height: 0vh;
  background: white;
  border: 2px solid #035aa6;
  z-index: 5;
  animation: fadeInShowLeftConfirm1 0.6s ease-in-out;
}

@keyframes fadeInShowLeftConfirm {
  0% {
    opacity: 0;
    transform: translateY(-100vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInShowLeftConfirm1 {
  0% {
    opacity: 0;
    height: 87vh;
  }

  100% {
    opacity: 1;
    /* transform: translateY(0); */
    height: 0px;
    display: none;
  }
}
.deleteFromTableBtn {
  background: #ffa500;
  color: white;
  border: solid 1px white;
  border-radius: 10px;
  font: normal normal normal 14px/24px Merriweather;
}

.deleteFromTableBtn:hover {
  background: white;
  border: solid 1px #ffa500;
  color: #ffa500;
}

.deleteAndDenyBtn {
  background: red;
  color: white;
  border: solid 1px white;
  border-radius: 10px;
  opacity: 0.9;
  font: normal normal normal 14px/24px Merriweather;
}

.deleteAndDenyBtn:hover {
  background: white;
  border: solid 1px red;
  color: red;
}

.deleteOptionInnerCon {
  width: 25%;
  height: 20%;
  background: #d3d3d3;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 10px 18px black;
  animation: fadeIndownConfirm 0.6s ease-in-out;
}

@keyframes fadeIndownConfirm {
  0% {
    opacity: 0;
    transform: translateY(-100vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.myErrorContainerForModalApplication {
  width: 80%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px auto;
  text-align: center;
  color: #ff4141;
  font-size: 12px;
}

.listingSortSelectSpan select {
  cursor: pointer;
  border-radius: 5px;
  font: normal normal normal 15px/32px Merriweather;
  border: 1px solid grey;
  padding: 2px 5px;
}

.someMarginInListingSearchInput .sortedBySpan {
  font: normal normal normal 15px/32px Merriweather;
}

.myHandShakeIcon {
  font-size: 16px;
  margin-right: 3px;
}

.applicationPostingContainer {
  width: 100%;
  text-align: center;
}
.appliModalContent {
  margin-top: 250px;
  width: 700px;
  left: -100px;
  height: 430px;
}
.myModalContainerForApplication input {
  margin-right: 5px;
  cursor: pointer;
}

.appliModalContent .modal-header {
  background: #035aa6 0% 0% no-repeat padding-box;
  opacity: 0.7;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header .appliName {
  color: #222222;
  opacity: 1;
  font: normal normal bold 12px/32px Merriweather;
  position: absolute;
  margin-left: -500px;
}

.modal-header .appliTitleModal {
  position: absolute;
  color: white;
  opacity: 1;
  letter-spacing: 1.13px;
  font: normal normal bold 16px/32px Merriweather;
}

.appliModalBodyCon {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}
.myListTd .goToListingDet:hover {
  color: #035aa6;
}

.appliModalBodyCon .alreadyClickedUnderReview,
.container2AppliModal,
.container3AppliModal {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appliModalBodyCon .container1AppliModal {
  flex: 1;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}

.container1AppliModal b {
  font: normal normal bold 14px/32px Merriweather;
}
.container1AppliModal span {
  font: normal normal normal 14px/32px Merriweather;
  margin-left: 5px;
  color: #035aa6;
  text-decoration: underline;
}

.container3AppliModal button {
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 50px;
  border: 1px #ff4141 solid;
  color: white;
  background: #ff4141 0% 0% no-repeat padding-box;
  letter-spacing: 0.85px;
  font: normal normal bold 16px/24px Merriweather;
  box-shadow: 3px 1px 3px 0.5px #969696;
}

.container3AppliModal button:hover {
  color: #ff4141;
  background: white;
  border: 1px solid #ff4141;
}

.container1AppliModal button {
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 25px;
  border: 1px #dbd400 solid;
  color: white;
  background: #dbd400 0% 0% no-repeat padding-box;
  letter-spacing: 0.85px;
  font: normal normal bold 16px/24px Merriweather;
  box-shadow: 3px 1px 3px 0.5px #969696;
}

.alreadyClickedUnderReview button {
  border-radius: 5px;
  padding: 10px 25px;
  border: 1px rgb(235, 229, 229) solid;
  color: white;
  background: rgb(235, 229, 229) 0% 0% no-repeat padding-box;
  letter-spacing: 0.85px;
  font: normal normal bold 16px/24px Merriweather;
}

.container1AppliModal button:hover {
  color: #dbd400;
  background: white;
  border: 1px solid #dbd400;
}
.disableLeaseSign,
.appliModalLeasesCon {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appliModalLeasesCon button {
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 0px;
  margin: 25px 10%;
  width: 80%;
  border: 1px #24842e solid;
  color: white;
  background: #24842e 0% 0% no-repeat padding-box;
  letter-spacing: 0.85px;
  font: normal normal bold 16px/24px Merriweather;
}
.disableLeaseSign button {
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 0px;
  margin: 25px 10%;
  width: 80%;
  border: 1px grey solid;
  color: white;
  background: rgb(233, 231, 231) 0% 0% no-repeat padding-box;
  letter-spacing: 0.85px;
  font: normal normal normal 16px/24px Merriweather;
  box-shadow: 3px 1px 3px 0.5px #969696;
}
/* .disableLeaseSign */

.appliModalLeasesCon button:hover {
  color: #24842e;
  background: white;
  border: 1px solid #24842e;
}

.container2AppliModal button {
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 50px;
  border: 1px #035aa6 solid;
  color: white;
  background: #035aa6 0% 0% no-repeat padding-box;
  letter-spacing: 0.85px;
  font: normal normal bold 16px/24px Merriweather;
  box-shadow: 3px 1px 3px 0.5px #969696;
}

.container2AppliModal button:hover {
  color: #035aa6;
  background: white;
  border: 1px solid #035aa6;
}

/* ApplicationTable  Component*/
.applicationTableCon {
  width: 100%;
  height: 460px;
  margin-top: 10px;
}
.applicationTableCon1 {
  width: 100%;
  height: 350px;
  margin-top: 10px;
}
.applicationTableCon7 {
  width: 100%;
  height: 450px;
  margin-top: 10px;
}
.applicationTableCon5 {
  width: 100%;
  height: 450px;
  margin-top: 10px;
}

/* ApplicationTitle Component */

.applicationTitleCon {
  width: 100%;
  text-align: center;
}

.applicationTitleCon h6 {
  color: #222222;
  letter-spacing: 0.9px;
  font: normal normal bold 16px/21px Merriweather;
}

/* Applications Screens */

.applicationsScreenCon {
  margin-top: 46px;
  width: 100%;
  height: 570px;
}

.applicationScreenTableCon {
  width: 84%;
  margin-left: 16%;
}

.applicationScreenTableCon1 {
  width: 94%;
  margin-left: 6%;
}

.approveUnapproveCon {
  display: flex;
  flex-direction: row;
}

.approveUnapproveCon .approveCon {
  flex: 1;
}

.approveCon button {
  width: 98%;
  background: #24842e 0% 0% no-repeat padding-box;
  padding: 5px;
  color: white;
  letter-spacing: 0.68px;
  font: normal normal bold 12px/16px Merriweather;
  border-radius: 5px;
}

.approveUnapproveCon .unapproveCon {
  flex: 1;
}

.unapproveCon button {
  width: 98%;
  background: #ff4141 0% 0% no-repeat padding-box;
  padding: 5px;
  color: white;
  letter-spacing: 0.68px;
  font: normal normal bold 12px/16px Merriweather;
  border-radius: 5px;
}

.approveUnapproveIcon {
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.approveUnapproveCon .tickIcon {
  width: 20px;
  height: 20px;
}
@media (max-width: 991px) {
  .appliModalBodyCon {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .container3AppliModal {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .prevEnterLeaveLeave {
    width: 100%;
    height: 0vh;
  }
  .prevEnterLeaveEnter {
    width: 100%;
    height: 90vh;
  }
  .applicationTableCon1 {
    width: 100%;
    margin: 20px 0;
    max-height: 800px;
    overflow-x: scroll;
  }
  .applicationTableCon1 table {
    width: 1200px;
  }
  .applicationTableCon7 {
    width: 100%;
    margin: 20px 0;
    max-height: 800px;
    overflow-x: scroll;
  }
  .applicationTableCon7 table {
    width: 700px;
  }
  .applicationTableCon5 {
    width: 100%;
    margin: 20px 0;
    max-height: 800px;
    overflow-x: scroll;
  }
  .applicationTableCon5 table {
    width: 950px;
  }
  /* .applicationTableCon {
    width: 100%;
    margin: 20px 0;
    max-height: 800px;
    overflow-x: scroll;
    } */
  /* .applicationTableCon table{
      width: 1100px;
    } */
  .deleteOptionInnerCon {
    width: 80%;
  }
  .applicationsScreenCon {
    margin-top: 46px;
    width: 100%;
    height: 650px;
  }

  .appliModalContent {
    margin-top: 50px;
    width: 99%;
    left: 0%;
    height: 600px;
  }
  .applTopTableCon {
    height: 1px;
  }
  .applicationScreenTableCon {
    width: 90%;
    margin-left: 5%;
  }
  .applicationScreenTableCon1 {
    width: 90%;
    margin-left: 5%;
  }

  .alreadyClickedUnderReview button,
  .container1AppliModal button,
  .container2AppliModal button,
  .container3AppliModal button {
    padding: 5px 10px;
  }

  .disableLeaseSign,
  .appliModalLeasesCon {
    width: 100%;
    margin-top: 10px;
  }

  .disableLeaseSign button,
  .appliModalLeasesCon button {
    padding: 5px 5px;
    margin: 25px 1%;
  }
}
