.tenantSearchCon {
  margin: 15px 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tenantAnalyticsSearchCon {
  margin-left: 30px;
}

.tenantSearchCon .tenantSearchInputCon {
  flex: 1;
  padding: 0 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tenantAccordionCon {
  width: 100%;
}

.tenantAccordionConInner {
  width: 80%;
  margin: 0 auto;
  min-height: 80px;
  border: #035aa6 solid 2px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noListingToAnalyse {
  text-align: center;
  color: #035aa6;
  border: 1px solid #035aa6;
  padding: 10px 5px;
  width: 30%;
  margin: 20px auto;
  font: normal normal bold 14px/16px Merriweather;
}

.tableItemLetters:hover {
  color: #035aa6;
}
.manageTenantOuterContainer {
  width: 100%;
  margin-top: 70px;
}
.theTenantDetailsForPaymentByTenant .alert-info {
  width: 80%;
  text-align: center;
  margin: 20px auto;
  font: normal normal bold 16px/16px Merriweather;
}
.tenantDetailsTenantNameChatContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 80px;
}
.tenantDetailsTenantNameChatContainer .alert-info {
  width: 80%;
  text-align: center;
  margin: 20px auto;
  font: normal normal bold 16px/16px Merriweather;
}
.theTenantDetailsForPaymentByTenant {
  width: 100%;
  height: 700px;
}
.loadingTheAggreementInTenantTable {
  margin-left: 10px;
}
.loadingTheTenantDownloadAgreement {
  margin-left: 10px;
}

.downloadTheAgreementForATenant {
  font-size: 9px;
  margin-left: 5px;
}

.tenantHeaders {
  font-size: 12px;
}
.manageTenantOuterContainer .manageTenantInnerCon {
  margin-top: 70px;
  width: 100%;
  height: 700px;
}
.listingSortSelectSpan select {
  cursor: pointer;
}
.openButtonCon {
  height: 450px;
}
.myListDeleteBtnLease {
  text-align: center;
  display: flex;
  flex-direction: row;
}

.myListDeleteBtnLease .noneMemberDownloadBtn {
  background: grey;
  cursor: pointer;
  border: 1px solid grey;
  padding: 5px 2px;
  color: white;
  font-size: 9px;
  margin-left: 5px;
  border-radius: 5px;
  width: 100px;
  text-decoration: none;
}

.myListDeleteBtnLease .noneMemberDownloadBtn:hover {
  border: 1px solid grey;
  background: white;
  color: grey;
}

.myListDeleteBtnLease .deleteBtn {
  border-radius: 5px;
  border: 1px solid #ff4141;
  background: #ff4141 0% 0% no-repeat padding-box;
  color: #ffffff;
  letter-spacing: 0.68px;
  padding: 7px 5px;
  font: normal normal bold 8px/16px Merriweather;
}

.myListDeleteBtnLease .deleteBtn:hover {
  background: white;
  border: 1px solid #ff4141;
  color: #ff4141;
}

.myListDeleteBtnLease .downloadBtn {
  border-radius: 5px;
  border: 1px solid #035aa6;
  background: #035aa6 0% 0% no-repeat padding-box;
  color: #ffffff;
  letter-spacing: 0.68px;
  padding: 7px 5px;
  font: normal normal bold 8px/16px Merriweather;
  width: 98px;
  cursor: pointer;
  margin-left: 5px;
}

.myListDeleteBtnLease .downloadBtn:hover {
  background: white;
  border: 1px solid #035aa6;
  color: #035aa6;
}

#CN,
#JT,
#AN {
  width: 85%;
  position: absolute;
  right: 45px;
}

.topLabalAddTenantFile {
  color: #707070;
  letter-spacing: 0.85px;
  font: normal normal normal 13px/24px Merriweather;
  margin-bottom: 5px;
}

.addlistCons a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.hiddleFileInputAddTenant {
  border-radius: 5px;
  display: none;
  width: 85%;
  height: 30px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #bcbcbc;
}

.InputLabelAddTenant {
  width: 85%;
  padding: 7px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  background: #f5f5f5 0% 0% no-repeat padding-box;
}

.innerLabelAddTenant {
  border: 1px solid #707070;
  margin-top: 7px;
  cursor: pointer;
  margin-left: 2px;
  padding: 2px 5px;
  color: #707070;
  font: normal normal normal 10px/17px Merriweather;
  letter-spacing: 0.58px;
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.displayFileNameAddTenant {
  font: normal normal normal 13px/24px Merriweather;
  margin-left: 5px;
}

.imgAddTenant {
  width: 30px;
  height: 30px;
  border-radius: 2px;
}
.loadingContainerAddTenantFile {
  font: normal normal normal 10px/17px Merriweather;
}

.errorAddTenantFile {
  color: red;
  font-size: 12px;
}

.addTenantInputLabel {
  color: #707070;
  letter-spacing: 0.85px;
  font: normal normal normal 13px/24px Merriweather;
}

.addTenantInput {
  border-radius: 5px;
  width: 85%;
  height: 30px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #bcbcbc;
}

.addTenantModalOuterCon {
  margin-top: 150px;
  width: 800px;
  margin-left: -180px;
  height: 550px;
}

.addTenantModalOuterCon .modal-header {
  background: #035aa6 0% 0% no-repeat padding-box;
  opacity: 0.6;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 5px 5px 0px 0px;
}

.addTenantTitle {
  color: #222222;
  opacity: 1;
  letter-spacing: 1.13px;
  font: normal normal bold 16px/32px Merriweather;
}

.closeIconContainerAddTenant {
  position: absolute;
}

.closeIconContainerAddTenant .myCloseIcon {
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
}

.addTenantInfoTitle {
  color: #222222;
  margin-left: 42.5%;
  position: absolute;
  letter-spacing: 0.85px;
  font: normal normal normal 13px/24px Merriweather;
}

.underlineContainAddTenant {
  position: absolute;
  margin-left: 42%;
  margin-top: 1px;
}

.underlineContainAddTenant hr {
  width: 90px;
  height: 0.5px;
  color: #035aa6;
  background: #035aa6 0% 0% no-repeat padding-box;
}

.addTenantInputFieldsCon {
  width: 100%;
  height: 420px;
  margin-left: 1px;
  margin-top: 30px;
}

.addTenantSubmitBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addTenantSubmitBtnContainer button {
  border-radius: 5px;
  margin-top: 15px;
  padding: 5px 150px;
  border: 1px solid #035aa6;
  cursor: pointer;
  background: #035aa6 0% 0% no-repeat padding-box;
  letter-spacing: 0.85px;
  color: #ffffff;
  font: normal normal bold 14px/24px Merriweather;
}

.addTenantSubmitBtnContainer button:hover {
  background: white;
  color: #035aa6;
  border: 1px solid #035aa6;
}

.addTenantErrorContainer {
  width: 80%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px auto;
  text-align: center;
  font-size: 12px;
}

.addTenantPostingContainer {
  margin: 0 auto;
  font: normal normal normal 12px/17px Merriweather;
}

.IDTypelabel {
  color: #707070;
  letter-spacing: 0.85px;
  font: normal normal normal 13px/24px Merriweather;
}

.IDTypeOuterCon {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.IDTypeSelectCon {
  width: 30%;
}

.IDTypeSelectCon select {
  border-radius: 5px;
  cursor: pointer;
  width: 85%;
  height: 30px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #bcbcbc;
}

.IDTypeInputCon {
  width: 65%;
  position: absolute;
  margin-left: 23.5%;
}

.IDTypeInputCon input {
  border-radius: 5px;
  width: 85%;
  height: 30px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #bcbcbc;
}

@media (max-width: 991px) {
  .tenantAnalyticsSearchCon {
    margin-left: 7px;
  }

  .noListingToAnalyse {
    width: 80%;
  }
  .tenantAccordionCon {
    width: 100%;
    margin: 20px 0;
    max-height: 1200px;
    overflow-x: scroll;
  }

  .tenantAccordionCon .accordionContainer {
    width: 1500px;
  }
  .tenantSearchCon {
    margin: 15px 2px;
    flex-direction: column;
  }

  .tenantSearchCon .tenantSearchInputCon {
    padding: 0 1%;
    flex: none;
    width: 100%;
  }

  .tenantAccordionConInner {
    width: 99%;
  }
  .leasesTableCon20 table {
    width: 500px;
  }
  .nonMemberProfileImageContainer {
    height: 40px;
    width: 40px;
  }
  .leaseTableTitleContainer {
    margin-top: 50px;
  }
  .someMarginTop {
    margin-top: 30px;
  }
  #CN,
  #JT,
  #AN {
    width: 85%;
    position: relative;
    right: 1px;
  }
  .addTenantModalOuterCon {
    margin-top: 20px;
    width: auto;
    margin-left: 0px;
    height: 950px;
  }
  .addlistCons a {
    margin-left: 70px;
  }
  .someMarginInTenantTable {
    margin-left: 50px;
  }

  .tenantDetailsTenantNameChatContainer {
    flex-direction: column;
    width: 98%;
    margin-left: 1%;
  }
  .tenantDetailsTenantNameChatContainer .alert-info {
    margin-top: 200px;
    width: 95%;
  }
  .theTenantDetailsForPaymentByTenant .alert-info {
    margin-top: 50px;
    width: 90%;
  }
}

@media (max-height: 1366px) and (min-height: 1024px) {
  .addTenantModalOuterCon {
    margin-top: 20px;
    width: auto;
    margin-left: 0px;
    height: 650px;
  }
  .tenantDetailsTenantNameChatContainer {
    flex-direction: column;
  }
}
