body,
html {
  height: 100%;
  margin: 0;
}

.constructing {
  color: white;
  font-family: 'Bree Serif', serif;
  font-size: 70px;
  text-align: center;
}

.underConstructionContainer {
  margin-top: 40px;
  background-size: cover;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  height: 100vh;
}

/* #underConstructionAnimation {
  animation: anime 1s infinite;
}
@keyframes anime {
  from {
    color: white;
    font-size: 50px;
    margin-top: ;
  }
  to {
    color: white;
    font-size: 20px;
  }
} */
/* .bgimg { */
/* background-image: url('../../images/blue-door.jpg'); */
/* height: 700px;
  width: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-size: 25px;
} */

.topleft {
  position: absolute;
  margin: 100px auto 0 auto;
  width: 100%;
}

.topleft img {
  height: 100px;
  width: 100px;
  z-index: 1;
  margin: 0 48%;
}

.bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

.middle {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.middle h1 {
  border-bottom: 5px solid #035aa6;
  font-size: 70px;
  font-weight: bolder;
  color: #035aa6;
}
.middle p {
  animation: anime 1s infinite;
  font-size: 25px;
  font-weight: bolder;
}

@keyframes anime {
  from {
    color: #035aa6;
  }
  to {
    color: white;
  }
}

hr {
  margin: auto;
  width: 40%;
}

@media (max-width: 991px) {
  .constructing {
    color: white;
    font-family: 'Bree Serif', serif;
    font-size: 25px;
    margin-top: 20px;
  }

  .topleft {
    position: absolute;
    margin: 70px auto 0 auto;
    width: 100%;
  }
  .topleft img {
    margin-left: 42%;
  }
  .topleft {
    margin-top: 20px;
  }
  .topleft img {
    margin: 0 35%;
  }
}
