.hereIsButtonImageCon img {
  object-fit: cover;
  width: 60px;
  height: 30px;
  border-radius: 20px;
}

.closeDealButtonCon {
  width: 100%;
  border-radius: 30px;
  background: red;
  color: white;
  border: 1px solid red;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  font: normal normal normal 16px/25px Merriweather;
  box-shadow: 2px 5px 7px black;
}

.closeDealButtonCon:hover {
  background: white;
  color: red;
}

.closeDealButtonCon1 {
  width: 100%;
  border-radius: 30px;
  background: red;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  font: normal normal normal 16px/25px Merriweather;
  box-shadow: 2px 5px 7px black;
  border: 1px solid red;
  animation: backgroundChange 1.5s infinite;
}

@keyframes backgroundChange {
  from {
    background: red;
    color: white;
  }
  to {
    background: white;
    color: red;
  }
}
.cancelAgreementAlertCon {
  position: fixed;
  z-index: 5;
  margin-top: 50px;
  background: rgb(255, 99, 71);
  padding: 5px;
  width: 20%;
  text-align: center;
  border-radius: 5px;
  color: white;
  border: 1px solid white;
  font: normal normal normal 14px/16px Merriweather;
  animation: fadeInUpOutConfirm 0.6s ease-in-out;
}
@keyframes fadeInUpOutConfirm {
  0% {
    opacity: 0;
    transform: translateX(-100vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.leaseSignDetailListingInfoContainer {
  width: 98%;
  margin: 0 auto;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #035aa6;
  box-shadow: 3px 1px 3px 0.5px black;
}

.leasingSignedDetInnerContainer {
  width: 85%;
  margin-left: 15%;
}
.leasingSignedDetInnerContainer1 {
  width: 93%;
  margin-left: 7%;
}

.leasingSignedDetInnerContainer .alert-danger {
  text-align: center;
  width: 65%;
  margin: 10px auto;
  font: normal normal normal 14px/16px Merriweather;
}

.leasingSignedDetInnerContainer1 .alert-danger {
  text-align: center;
  width: 65%;
  margin: 10px auto;
  font: normal normal normal 14px/16px Merriweather;
}

.leasingSignedDetInnerContainer .alert-info {
  width: 50%;
  margin: 0 auto 10px auto;
  text-align: center;
  font: normal normal bold 16px/16px Merriweather;
  /* box-shadow: 3px 1px 3px 0.5px #969696; */
}
.leasingSignedDetInnerContainer1 .alert-info {
  width: 50%;
  margin: 0 auto 10px auto;
  text-align: center;
  font: normal normal bold 16px/16px Merriweather;
}

.leasingDetailsOuterContainer {
  margin-top: 45px;
  width: 100%;
}

.leaseSignDetailListingInfoContainer .title {
  text-align: center;
  font: normal normal bold 18px/24px Merriweather;
  margin-top: 10px;
}

.infoDetailsContainerleaseSignDet {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.infoDetailsContainerleaseSignDetInnerCon {
  flex: 1;
  padding: 5px 20px;
  border-right: 1px solid rgb(194, 194, 194);
  margin-right: 20px;
}

.infoDetailsContainerleaseSignDetInnerCon .landlordInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infoDetailsContainerleaseSignDetInnerCon .landlordInfo .imageContainer {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 100%;
  border: 1px solid #035aa6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoDetailsContainerleaseSignDetInnerCon .landlordInfo .imageContainer img {
  width: 100%;
  height: 100%;
}

.infoDetailsContainerleaseSignDetInnerCon .landlordInfo .nameContainer {
  margin-left: 10px;
}

.infoDetailsContainerleaseSignDetInnerCon .landlordInfo .nameContainer h6 {
  font: normal normal normal 16px/25px Merriweather;
}

.leaseTypeLeasingSignDet {
  margin: 10px 0;
  /* box-shadow: 3px 1px 3px 0.5px #969696; */
  padding: 10px;
  border-radius: 5px;
}

.leaseTypeLeasingSignDet .title {
  font: normal normal bold 16px/25px Merriweather;
}

.monthToMonthLeasingSignDet {
  font: normal normal normal 16px/25px Merriweather;
}

.leasingEndLeasingDet {
  font: normal normal normal 15px/25px Merriweather;
}

.leaseStartingAndEndingBox {
  margin-top: 20px;
  /* border: 1px solid #ffa500; */
  width: 50%;
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: 3px 1px 3px 0.5px #969696; */
}

.leaseStartingAndEndingBox .startDateContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.leaseStartingAndEndingBox .startDateContainer1 {
  display: flex;
  flex-direction: row;
  animation: blink 1s infinite;
  color: #ff6d60;
  cursor: pointer;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.leaseStartingAndEndingBox .startDateContainer h5 {
  font: normal normal bold 14px/25px Merriweather;
}

.leaseStartingAndEndingBox .startDateContainer1 h5 {
  font: normal normal bold 14px/25px Merriweather;
}

.leaseStartingAndEndingBox .startDateContainer p {
  margin-left: 15px;
  font: normal normal normal 14px/25px Merriweather;
}

.leaseStartingAndEndingBox .startDateContainer1 p {
  margin-left: 15px;
  font: normal normal normal 14px/25px Merriweather;
}

.ToTalPaidTotalOwedAmtBox {
  margin-top: 20px;
  /* border: 1px solid #ffa500; */
  width: 80%;
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: 3px 1px 3px 0.5px #969696; */
}

.ToTalPaidTotalOwedAmtBox .totalPaid {
  display: flex;
  flex-direction: row;
}
.ToTalPaidTotalOwedAmtBox .totalPaid1 {
  display: flex;
  flex-direction: row;
  animation: blink 1s infinite;
  color: #ff6d60;
  cursor: pointer;
}

.ToTalPaidTotalOwedAmtBox .totalPaid h5,
.ToTalPaidTotalOwedAmtBox .totalPaid1 h5 {
  font: normal normal bold 14px/25px Merriweather;
}

.ToTalPaidTotalOwedAmtBox .totalPaid p,
.ToTalPaidTotalOwedAmtBox .totalPaid1 p {
  margin-left: 15px;
  font: normal normal normal 14px/25px Merriweather;
}

.waitingForTenantToConfirm {
  display: flex;
  flex-direction: row;
}

#exampleModalMakePayment {
  margin-top: 120px;
}

#exampleModalMakePayment h5 {
  font: normal normal normal 20px/25px Merriweather;
}

.pictureContaineLeaseSignDet {
  flex: 1;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 1px 3px 0.5px grey;
  background-color: lightgray;
}

.pictureContaineLeaseSignDet img {
  width: 100%;
  height: 100%;
}

.alertInfoForNoPaymentMethodAvailable {
  text-align: center;
}

.modal-body .makePaymentActiveBtnLeaseSignDet {
  margin: 3px 0.5%;
  color: white;
  width: 99%;
  background: #035aa6;
  font: normal normal normal 16px/25px Merriweather;
  box-shadow: 3px 1px 3px 0.5px #969696;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-body .makePaymentActiveBtnLeaseSignDet:hover {
  background: white;
  color: #035aa6;
  border: #035aa6 1px solid;
}

.modal-body .makePaymentActiveBtnLeaseSignDet1 {
  margin: 3px 0.5%;
  border: 1px solid #035aa6;
  color: #035aa6;
  width: 99%;
  background: white;
  font: normal normal normal 16px/25px Merriweather;
  box-shadow: 3px 1px 3px 0.5px #969696;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-body .makePaymentActiveBtnLeaseSignDet1:hover {
  background: #ffa500;
  color: white;
  border: #035aa6 1px solid;
}

.modal-body .makePaymentActiveBtnLeaseSignDet .imageContainer,
.modal-body .makePaymentActiveBtnLeaseSignDet1 .imageContainer {
  width: 50px;
  display: flex;
  margin-right: 2px;
  /* position: absolute; */
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  height: 20px;
  background: white;
}

.modal-body .makePaymentActiveBtnLeaseSignDet .imageContainer img,
.modal-body .makePaymentActiveBtnLeaseSignDet1 .imageContainer img {
  width: 40px;
  height: 20px;
}

.makePaymentHr {
  margin: 20px auto;
}

.showingCommasInpayment {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* text-align: center; */
}

.makePaymentInputFields {
  display: flex;
  flex-direction: row;
}

.makePaymentInputFields select {
  width: 29%;
  cursor: pointer;
  font: normal normal normal 16px/25px Merriweather;
}

.makePaymentInputFields input {
  margin: 5px auto;
  width: 98%;
  font: normal normal normal 16px/25px Merriweather;
  border-top: 1px white solid;
  border-left: 1px white solid;
  border-right: 1px white solid;
}

.makePaymentOnSubmitBtn {
  background: #035aa6;
  color: white;
  border: #035aa6 solid 1px;
  width: 50%;
  margin: 10px 25%;
  border-radius: 10px;
  font: normal normal normal 20px/25px Merriweather;
}

.makePaymentOnSubmitBtn:hover {
  background: white;
  color: #035aa6;
}

.makePaymentOnSubmitBtn .imageContainer {
  width: 40px;
  display: flex;
  margin-right: 5px;
  position: absolute;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  height: 20px;
  background: white;
}

.makePaymentOnSubmitBtn .imageContainer img {
  width: 40px;
  height: 20px;
}

.makePaymentError {
  color: red;
  text-align: center;
  font-size: 12px;
  width: 100%;
}

.finallyMadePayment {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.finallyMadePayment .theIconContainer {
  width: 100%;
  cursor: pointer;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.finallyMadePayment .theIconContainer .checkedIcon {
  font-size: 100px;
  color: white;
  transform: rotate(-360deg);
  transition: transform 3s;
}

.leaseSignDetPayLoading {
  width: 100px;
  color: #365aa6;
  height: 100px;
  margin: 30px auto;
}

.searchPaymentLeaseMakePayBtn {
  flex: 1.4;
  padding-left: 30px;
  padding-right: 10px;
}
.searchPaymentLeaseMakePayBtn a {
  text-decoration: none;
  padding: 8px 30px;
  color: #ffa500;
  cursor: pointer;
}

.searchPaymentMethodInputCon,
.searchPaymentDateContainer {
  flex: 1;
}

.searchPaymentDateContainer input {
  margin-left: 5px;
}

.searchPaymentMethodInputCon {
  margin-left: 10px;
}

.makePaymentSelect {
  cursor: pointer;
}

.madePaymentTableContainer {
  border: 1px solid #035aa6;
  border-radius: 5px;
  box-shadow: 3px 1px 3px 0.5px black;
}

.myListTd a {
  text-decoration: none;
}

.faChrevronCircleUp {
  margin-right: 5px;
}

.dropdownContainerForTableDet {
  width: 100%;
  background-color: grey;
}

.dropdownContainerForTableDet .card-body {
  background-color: grey;
}

.myListTd .receiptDownloadBtn {
  padding: 0;
  border-radius: 5px;
  background: #035aa6;
  border: #035aa6 1px solid;
  color: white;
  padding: 2px 2px;
}

.myListTd .receiptDownloadBtn:hover {
  background: white;
  color: #035aa6;
}

.closeBtnMadePayment {
  background-color: #035aa6;
  color: white;
  cursor: pointer;
  border: #035aa6 solid 1px;
}

.closeBtnMadePayment:hover {
  background-color: white;
  color: #035aa6;
}

.theTitleForTheHeaderContainer {
  width: 100%;
  padding: 20px;
}

.separationBtwTitleAndPrice {
  right: 35px;
}

.theIconforVerified {
  fill: #035aa6;
}

@media (max-width: 991px) {
  .modal-body .makePaymentActiveBtnLeaseSignDet .imageContainer,
  .modal-body .makePaymentActiveBtnLeaseSignDet1 .imageContainer {
    width: 50px;
    height: 20px;
  }
  .searchPaymentLeaseMakePayBtn {
    flex: 1.4;
    padding-left: 30px;
    margin-bottom: 15px;
  }
  .searchPaymentLeaseMakePayBtn a {
    margin-left: 50px;
  }
  .pictureContaineLeaseSignDet {
    margin-right: 0px;
    margin-bottom: 0;
  }
  .infoDetailsContainerleaseSignDetInnerCon {
    padding: 15px 5px;
    border-right: 1ps solid white;
    margin-right: 0px;
  }
  .leasingSignedDetInnerContainer {
    width: 100%;
    margin-left: 0;
  }
  .leasingSignedDetInnerContainer1 {
    width: 100%;
    margin-left: 0%;
  }

  .leasingSignedDetInnerContainer .alert-danger {
    width: 90%;
    padding: 0;
  }

  .leasingSignedDetInnerContainer .alert-info {
    width: 78%;
    padding: 10px;
    margin-right: 5px;
  }
  .leasingSignedDetInnerContainer1 .alert-info {
    width: 78%;
    padding: 10px;
    margin-right: 5px;
  }
  .someMarginInLeasignDetailInput {
    margin-bottom: 5px;
    margin-left: 25px;
  }
  .searchPaymentDateContainer input {
    margin-left: 0px;
  }
  .someNopaymentMadeInleaseSign {
    margin-top: 100px;
    width: 100%;
  }
  .infoDetailsContainerleaseSignDet {
    flex-direction: column;
  }
  .leaseStartingAndEndingBox {
    width: 100%;
    padding: 0px;
  }

  .leaseStartingAndEndingBox .startDateContainer h5 {
    font: normal normal bold 12px/25px Merriweather;
  }
  .leaseStartingAndEndingBox .startDateContainer p {
    font: normal normal normal 12px/25px Merriweather;
  }
  .leaseSignDetailListingInfoContainer {
    width: 100%;
    padding: 0;
  }
  .ToTalPaidTotalOwedAmtBox {
    width: 100%;
  }

  .separationBtwTitleAndPrice {
    right: 0px;
    left: 0px;
  }

  .madePaymentTableContainer {
    margin-top: 100px;
    margin-bottom: 20px;
  }
  .ToTalPaidTotalOwedAmtBox .totalPaid h5 {
    font: normal normal bold 12px/25px Merriweather;
  }

  .ToTalPaidTotalOwedAmtBox .totalPaid p {
    font: normal normal normal 12px/25px Merriweather;
  }
  .cancelAgreementAlertCon {
    width: 80%;
  }
}
