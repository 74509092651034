/* .textInputKeywordCon {
  width: 100%;
} */

.logoingContainer {
  padding-bottom: 10px;
  /* margin-left: -20px; */
}
/* styling for Navbar component */
.MuiAutocomplete-inputRoot {
  height: 50px;
  width: 100%;
  color: #222222;
  font-size: 18px;
  font: normal normal 300 18px/25px Merriweather;
  letter-spacing: 1.13px;
  border-radius: 5px;
  /* border: 1px solid #707070; */
  background: #ffffff 0% 0% no-repeat padding-box;
}

.footer1Atag h5:hover {
  color: black;
}
.offlineSignal {
  padding: 5px;
  position: fixed;
  background: white;
  z-index: 100;
  border-radius: 5px;
  top: 90vh;
  left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation: fadeInUpConfirm 0.6s ease-in-out;
}

@keyframes fadeInUpConfirm {
  0% {
    opacity: 0;
    transform: translateY(100vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.clickToSeeDetailContainer {
  width: 68%;
  height: 40px;
  padding: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: 2;
  background: #035aa6;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  font-size: 10px;
  opacity: 0.5;
  border-radius: 30px;
  text-align: center;
  visibility: hidden;
}

.faqFooter2IconBtn {
  border-radius: 30px;
  padding: 5px 30px;
  font-weight: bold;
  background: #035aa6;
  color: white;
  box-shadow: 3px 1px 3px 0.5px black;
}

.faqFooter2IconBtn:hover {
  background: white;
  color: #035aa6;
}

.pleaseCheckNetwork {
  position: fixed;
  width: 25%;
  margin: 45px auto 0 auto;
  z-index: 200;
  background: red;
  color: white;
  opacity: 0.7;
  border-radius: 5px;
  padding: 10px 10px;
  font: normal normal normal 18px/20px Merriweather;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.DetailsUserIcon1 {
  width: 20px;
  height: 20px;

  fill: white;
}

.clickToSeeDetailContainer1 {
  width: 68%;
  padding: 5px;
  height: 30px;
  border-radius: 30px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  background: #035aa6;
  z-index: 2;
  color: white;
  font-size: 10px;
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
  margin-left: 15%;
  opacity: 0.9;
  animation: h1 0.6s alternate;
}

@keyframes h1 {
  from {
    top: 60%;
  }
  to {
    top: 40%;
  }
}

.clickToSeeDetailContainer1:hover {
  background: white;
  border: 1px solid #035aa6;
  color: #035aa6;
}
.clickToSeeDetailContainer21 {
  width: 68%;
  padding: 5px;
  height: 30px;
  border-radius: 30px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  background: #035aa6;
  z-index: 2;
  color: white;
  font-size: 10px;
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
  margin-left: 1%;
  opacity: 0.9;
  animation: h1 0.6s alternate;
}

.clickToSeeDetailContainer21:hover {
  background: white;
  border: 1px solid #035aa6;
  color: #035aa6;
}

.cardBodyContainerForDisplayCard {
  display: flex;
  flex-direction: column;
  background-color: #dddddd;
}
.navbarSeparationFromAbove {
  height: 58px;
}
.globeInNavbar {
  color: #1a1110;
}

.signUpSpan {
  color: white;
}

.navbarLoginSpan {
  color: #035aa6;
}

.navbarDropdownIcon {
  font-size: 20px;
}

.headerSearchAtag {
  color: #ffffff;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-radius: 5px;
}

.headerSearchAtag:hover {
  background-color: rgba(232, 244, 243, 0.4);
  color: black;
}

.youtubeFooter2 {
  height: 25px;
  fill: white;
}
.youtubeFooter2:hover {
  fill: #035aa6;
}

.youtubeAtagFooter2 {
  text-decoration: none;
  /* position: absolute; */
  /* margin-left: 70px; */
}

.facebookIconFooter2 {
  height: 25px;
  width: 25px;
  fill: #035aa6;
}
.facebookIconFooter2:hover {
  fill: #035aa6;
  opacity: 0.5;
}

/* .separateleftFooter2 {
  margin-left: 10px;

} */
.separateleftFooter2:hover {
  fill: #035aa6;
}

.footer2Atag {
  color: black;
}
.footerParaLink {
  margin: 2px 0;
}

.theInfoNawafrica {
  color: orange;
}

.dropdownHouse:hover > .dropdown-menuHouse {
  display: block;
}

.dropdown-menuHouse {
  width: 150px;
  z-index: 50;
  background: white;
  transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  padding: 10px 20px;
  border: 0 solid white;
}
.dropdown-menuHouse a,
.dropdown-menuLands a {
  font: normal normal normal 16px Merriweather;
  padding: 7px 0;
}
.dropdown-menuHouse .first:hover {
  fill: #035aa6;
  background-color: #035aa6;
  color: white;
}
.dropdown-menuHouse .first {
  border-bottom: 1px solid #dddddd;
  text-decoration: none;
  color: grey;
  background-color: white;
  /* border-radius: 20px; */

  border: 1px solid #dddddd;
}
.dropdown-menuLands {
  width: 150px;
  z-index: 50;
  background: white;
  transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  padding: 0 20px;
}
.dropdown-menuLands .first {
  border-bottom: 1px solid #dddddd;
  text-decoration: none;
  color: grey;
  background-color: white;
  /* border-radius: 20px; */

  border: 1px solid #dddddd;
}
.dropdown-menuLands .first:hover {
  fill: #035aa6;
  background-color: #035aa6;
  color: white;
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0 10px;
  margin: 0;
}

.dropdown-menu ul li {
  border-bottom: 1px solid white;
}

.flag-icon-opacity1 {
  opacity: 0.5;
}

.flag-icon-opacity2 {
  opacity: 1;
}

.dropdownLands:hover > .dropdown-menuLands {
  display: block;
}

.dropdownHouse {
  /* margin-left: 1%; */
}

.carousel-captionForSearch {
  bottom: initial;
  top: 30px;
  padding: 0;
}

.carousel-captionForSearch p {
  z-index: 1;
  text-align: center;
  color: #ffffff;
  letter-spacing: 2.7px;
  font-size: 50px;
  margin: 0;
  font: normal normal bold 11px/65px Merriweather;
}

.dropdownLands {
  /* margin-left: 2%; */
  width: 100px;
}

.App {
  max-width: 100%;
  overflow-x: hidden;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 100%;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}
.navbar {
  position: fixed;
  z-index: 20;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #035aa6;
}

.logoutContainer {
  /* font-size: 20px; */
  font: normal normal bold 18px/25px Merriweather;
  text-align: center;
  position: absolute;
  right: 7%;
}
.logoutContainer .loginSpan {
  color: #035aa6;
  fill: #035aa6;
  transition: all 1s;
}

.logoutContainer .loginSpan:hover {
  color: #ffa500;
  fill: #ffa500;
}

.logoutContainer .logoutIcon {
  font-size: 20px;
}

.logoutContainer .loginIcon {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

.globeContainer {
  font-size: 20px;
  font-family: "Bree Serif", serif;
  text-align: center;
  position: absolute;
  right: 1%;
}

.logoutContainer a {
  color: #707070;
  fill: #707070;
  transition: color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  letter-spacing: 0.9px;
  opacity: 1;
  font: normal normal bold 18px/25px Merriweather;
  /* font-family: 'Bree Serif', serif; */
}

.logoutContainer .logoutIcon:hover,
.logoutContainer:hover,
.logoutContainer a:hover,
.logoutContainer span:hover {
  color: #035aa6;
}

.houseIconContainer {
  fill: #707070;
  color: #707070;
  transition: color 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.houseIconContainer:hover {
  color: #035aa6;
  fill: #035aa6;
}
.landsIconContainer {
  color: #707070;
  fill: #707070;
  transition: color 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.landsIconContainer:hover {
  color: #035aa6;
  fill: #035aa6;
}

.logo {
  width: 150px;
  height: 55px;
  margin-left: 10px;
  margin-top: 7px;
}

.logoPara {
  line-height: 0.5rem;
  color: #1a1110;
  font-family: "Merriwather", sans-serif;
  /* font: normal normal bold 12px/25px Merriweather; */
  font-size: 12px;
}

.homeContainer {
  margin-left: 15%;
  width: 100px;
  opacity: 1;
  /* background: red; */
}

.homeContainer a {
  letter-spacing: 0.9px;
  opacity: 1;
  font: normal normal bold 18px/25px Merriweather;
  color: #707070;
  transition: color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  /* font-family: 'Bree Serif', serif; */
}
.homeContainer span {
  color: #707070;
  transition: color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.homeContainer span:hover {
  color: #035aa6;
}

/* .homeContainer i:hover,
.homeContainer:hover,
.homeContainer a:hover,
.homeContainer span:hover {
  color: #035aa6;

} */

/* .homeContainer i {
  margin-right: 5px;
} */

.housesContainer {
  min-width: 110px;
  letter-spacing: 0.9px;
  opacity: 1;
  color: black;
  fill: black;
  font: normal normal bold 18px/25px Merriweather;
  transition: color 1s cubic-bezier(0.19, 1, 0.22, 1),
    fill 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.housesContainer:hover {
  color: #035aa6;
  fill: #035aa6;
}
.housesContainer .houseDropIcon {
  font-size: 20px;
}

.landsContainer {
  width: 120px;
  letter-spacing: 0.9px;
  opacity: 1;
  font: normal normal bold 20px/25px Merriweather;
  color: #035aa6;
}

.landsContainer:hover {
  color: #035aa6;
  fill: #035aa6;
}

.portalContainer {
  /* margin-left: 35%; */
  margin-top: 10px;
  position: absolute;
  min-width: 235px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.myPortalContainer {
  /* margin-left: 35%; */
  margin-top: 10px;
  position: absolute;
  min-width: 235px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.portalContainerClient {
  position: absolute;
  min-width: 150px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.portalContainer1 {
  margin-left: 97%;
  margin-top: 10px;
}

.portalContainer button {
  background-color: #035aa6;
  transition: background-color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  border: #035aa6 solid 1px;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  font-size: 16px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  font: normal normal bold 16px/25px Merriweather;
}

.portalContainer button:hover {
  background-color: white;
  color: #035aa6;
}

.myPortalContainer button {
  background-color: white;
  transition: background-color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: #035aa6;
  border: 1px solid #035aa6;
  font-size: 16px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  font: normal normal bold 16px/25px Merriweather;
}

.myPortalContainer button:hover {
  background-color: #035aa6;
  color: white;
}

.portalContainerClient button {
  min-width: 180px;
  padding: 8px 5px;
  background-color: white;
  transition: background-color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  color: #035aa6;
  border: 1px solid #035aa6;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  font: normal normal bold 16px/25px Merriweather;
}

.portalContainerClient button:hover {
  background-color: #035aa6;
  color: white;
  border: 1px solid #035aa6;
}

.portalContainer1 button {
  background-color: white;
  transition: background-color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  border: 1px solid #035aa6;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: #035aa6;
  /* font-size: 16px; */
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 100px;
  font: normal normal bold 16px/25px Merriweather;
}

.portalContainer button:hover {
  background-color: white;
  color: #035aa6;
  border: 1px solid #035aa6;
}

.managementContainer {
  margin-left: 47%;
  margin-top: 7px;
  min-width: auto;
  padding: 5px;
  position: absolute;
  height: 38px;
}
.myManagementContainer {
  margin-left: 47%;
  margin-top: 7px;
  min-width: 216px;
  padding: 5px;
  position: absolute;
  height: 38px;
}

.managementContainer .btn {
  background-color: #035aa6;
  transition: background-color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  font: normal normal bold 16px/25px Merriweather;
}

.managementContainer .btn:hover {
  background-color: white;
  color: #035aa6;
}

.myManagementContainer .btn {
  background-color: white;
  transition: background-color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: #035aa6;
  border: 1px solid #035aa6;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  font: normal normal bold 16px/25px Merriweather;
}

.myManagementContainer .btn:hover {
  background-color: #035aa6;
  color: white;
}

.managementContainer button:hover {
  background-color: white;
  color: #035aa6;
  border: 1px solid #035aa6;
}

.navProfileContainer {
  /* font-size: 20px; */
  font: normal normal bold 18px/25px Merriweather;
  font: normal normal bold 18px/25px Merriweather;
  text-align: center;
  position: absolute;
  right: 13.5%;
}

.navProfileContainer1 {
  /* font-size: 20px; */
  font: normal normal bold 18px/25px Merriweather;
  font: normal normal bold 18px/25px Merriweather;
  text-align: center;
  position: absolute;
  right: 16.5%;
}
.navProfileContainer button {
  padding: 7px 25px;
  border-radius: 10px;
  fill: white;
  color: white;
  background: #ffa500 0% 0% no-repeat padding-box;
  transition: all 0.6s;
}
.navProfileContainer button:hover {
  background: white;
  color: #ffa500;
  border: 1px solid #ffa500;
  fill: #ffa500;
}

.navProfileContainer1 button {
  padding: 7px 25px;
  border-radius: 10px;
  fill: white;
  color: white;
  background: #ffa500 0% 0% no-repeat padding-box;
  transition: all 0.6s;
}

.navProfileContainer1 button:hover {
  background: white;
  color: #ffa500;
  fill: #ffa500;
  border: 1px solid #ffa500;
}

.navProfileContainer .signUpIcon {
  width: 22px;
  height: 22px;
  margin-right: 4px;
}
.navProfileContainer1 .signUpIcon {
  width: 22px;
  height: 22px;
  margin-right: 4px;
}

.navProfileContainer a {
  text-decoration: none;
  letter-spacing: 0.9px;
  opacity: 1;
  font: normal normal bold 18px/25px Merriweather;
  font: normal normal bold 18px/25px Merriweather;
}

.navProfileContainer1 a {
  text-decoration: none;
  letter-spacing: 0.9px;
  opacity: 1;
  font: normal normal bold 18px/25px Merriweather;
  font: normal normal bold 18px/25px Merriweather;
  color: #707070;
}

.navProfileContainerName .navImgCon {
  width: 50px;
  height: 50px;
  border: 1px solid #035aa6;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  /* box-shadow: 3px 1px 3px 0.5px grey; */
}
.navProfileContainerName1 .navImgCon {
  width: 50px;
  height: 50px;
  border: 1px solid #035aa6;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  /* box-shadow: 3px 1px 3px 0.5px grey; */
}

.navImgCon .userIcon {
  width: 30px;
  height: 30px;
  margin-top: 10px;
}

.navProfileContainerName .name {
  margin-top: 15px;
  flex: 1;
  margin-left: 5px;
}
.navProfileContainerName1 .name {
  margin-top: 15px;
  flex: 1;
  margin-left: 5px;
}
.navProfileContainer img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.navProfileContainerName img {
  height: 50px;
  width: 50px;
  border-radius: 100%;
}
.navProfileContainerName1 img {
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.navProfileContainerName {
  /* font-size: 20px; */
  font: normal normal bold 18px/25px Merriweather;
  text-align: center;
  position: absolute;
  right: 15%;
}

.navProfileContainerName1 {
  /* font-size: 20px; */
  font: normal normal bold 18px/25px Merriweather;
  text-align: center;
  position: absolute;
  right: 20%;
}

.navProfileContainerName a {
  text-decoration: none;
  color: #707070;
  transition: bacolor 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  letter-spacing: 0.9px;
  opacity: 1;
  font: normal normal bold 18px/25px Merriweather;
  /*   font: normal normal bold 18px/25px Merriweather; */
  display: flex;
  flex-direction: row;
}
.navProfileContainerName a:hover {
  color: #035aa6;
}

.navProfileContainerName1 a {
  text-decoration: none;
  color: #707070;
  transition: color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  letter-spacing: 0.9px;
  opacity: 1;
  font: normal normal bold 18px/25px Merriweather;
  /*   font: normal normal bold 18px/25px Merriweather; */
  display: flex;
  flex-direction: row;
}

.bi-globe {
  width: 30px;
  height: 30px;
}

#dropdownMenuButton1 {
  color: #035aa6;
  transition: color 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
#dropdownMenuButton1:hover {
  color: #ffa500;
}

.globaldropDownContainer {
  z-index: 200;
  top: 65px;
  left: 85%;
  position: fixed;
  background-color: white;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  border: 3px solid #dddddd;
  width: 15%;
  padding: 0 1px;
  text-align: center;
}

.globaldropDownContainer ul li:first-child {
  border-bottom: 2px solid #dddddd;
}

.globaldropDownContainer ul li:nth-of-type(2) {
  border-bottom: 2px solid #dddddd;
}
.globaldropDownContainer ul li:nth-of-type(3) {
  border-bottom: 2px solid #dddddd;
}

.globaldropDownContainer ul li:hover {
  background: #035aa6;
}
.globaldropDownContainer button:hover {
  background: #035aa6;
}

/* .globaldropDownContainer:hover{
  background-color: #035aa6;

} */

.showLangDowndown:hover > .globaldropDownContainer {
  display: block;
}

/* styling for HeaderSearch component */

.previousCarouselBtn {
  width: 40px;
  height: 100px;
  background-color: #035aa6;
  top: 40%;
  transform: translateY(-40%);
  opacity: 0.5;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 5px 7px black;
  /* border-top: white 1px solid;
  border-right:white 1px solid;
  border-bottom:white 1px solid; */
}

.nextCarouselBtn {
  width: 40px;
  height: 100px;
  background-color: #035aa6;
  top: 40%;
  transform: translateY(-40%);
  opacity: 0.5;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 2px 5px 7px black;
  /* border-top: white 1px solid;
  border-left:white 1px solid;
  border-bottom:white 1px solid; */
}
.headerSearchIcon {
  height: 50px;
  width: 50px;
  fill: white;
}
.recievedMessageCon {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 2%;
  top: 70px;
  position: absolute;
  width: 80px;
  height: 50px;
  background: #035aa6;
  opacity: 0.9;
  border-radius: 10px;
  border: white solid 1px;
  box-shadow: 1px 3px 5px black;
  cursor: pointer;
}

.recievedMessageCon .badge {
  border-radius: 100%;
  position: absolute;
  margin-bottom: 45px;
  right: 20px;
  color: white;
  background-color: white;
  color: #035aa6;
}

.recievedMessageCon1 {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 2%;
  top: 120px;
  position: absolute;
  width: 80px;
  height: 50px;
  background: #035aa6;
  opacity: 0.9;
  border-radius: 10px;
  border: white solid 1px;
  box-shadow: 1px 3px 5px black;
  cursor: pointer;
}

.recievedMessageCon1 .badge {
  border-radius: 100%;
  position: absolute;
  margin-bottom: 45px;
  right: 20px;
  color: white;
  background-color: white;
  color: #035aa6;
}

.headerTitleConh1 {
  text-align: center;
  color: #ffffff;
  letter-spacing: 2.7px;
  margin-top: 10px;
  font: normal normal bold 50px/65px Merriweather;
  opacity: 1;
  margin-bottom: 0;
  border-radius: 20px;
}
.span {
  margin-left: 7px;
}
.headerTitleConP {
  text-align: center;
  letter-spacing: 1.35px;
  margin-top: 5px;
  font: normal normal normal 20px/28px Merriweather;
}

.headerSearchContainer {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#carouselExampleSlidesOnly {
  height: 100%;
  width: 100%;
  position: absolute;
}

#carouselExampleSlidesOnly .carousel-inner {
  height: 100%;
  width: 100%;
}

#carouselExampleSlidesOnly img {
  width: 100%;
  height: 650px;
  object-fit: cover;
}

.headerTitleConRentOrSellPropB {
  color: #035aa6;
  letter-spacing: 1.35px;
  font: normal normal 1500 18px/28px Merriweather;
}

.headerSearchInputContainer {
  width: 80%;
  /* margin-top: 80px;
  margin-left: 10%; */
  min-height: 500px;
  position: absolute;
  border-radius: 10px;
  box-shadow: 2px 5px 7px black;
}

.headerInputsCon {
  flex-grow: 1;
  width: 75%;
  height: 100%;
  margin: auto;
}

.searchCountryCon {
  width: 100%;
}

/*
.searchKeywordCon select,
.listingTypeCon select,
.searchBedroomCon select,
.searchCityCon select,
.searchStateCon select,
.searchCountryCon select {
  cursor: pointer;
} */

.searchStateCon {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

.searchCityCon {
  width: 100%;
  margin-top: 12px;
}

.overlay-image {
  height: auto;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  width: auto;
}

.listingTypeCon {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: 12px;
}
.searchKeywordCon {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: 12px;
}

.searchTitleConing {
  width: 100%;
}

/* .searchBedroomCon {
  margin-left: 19.5%;
  margin-top: 20px;
}


.searchKeywordCon {
  margin-left: 11%;
  margin-top: 20px;
} */

/* .searchCurrencyCon {
  margin-left: 19.5%;
  margin-top: 20px;
} */

.searchBtnCon {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
}

/* .priceCompoCon {
  display: flex;
  flex-direction: row;
} */

.priceCompoCon select {
  flex: 1;
}

.priceCompoCon input {
  flex: 2;
  color: #222222;
  font-size: 18px;
  font: normal normal 300 18px/25px Merriweather;
  letter-spacing: 1.13px;
  border-radius: 5px;
  /* border: 1px solid #707070; */
  background: #ffffff 0% 0% no-repeat padding-box;
}

.searchButton {
  background: #035aa6 0% 0% no-repeat padding-box;
  transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  color: white;
  min-width: 20%;
  /* margin-top: 50px; */
  font: normal normal bold 18px/28px Merriweather;
  letter-spacing: 1.35px;
  color: #ffffff;
  border-radius: 5px;
  box-shadow: 1px 3px 5px black;
}

.searchButton:hover {
  background-color: white;
  border: 1px #035aa6 solid;
  color: #035aa6;
}

/* styling for DisplayCard component */

.cardsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0;
}

.displayListingCard {
  width: 24%;
  height: 400px;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 15px;
  margin-top: 0;
  border-radius: 5px;
  padding: 0;
  border-spacing: 0;
  overflow: hidden;
  outline: 0;
  display: flex;
  flex-direction: column;
  /* box-shadow: 2px 5px 7px black; */
  box-shadow: 3px 1px 3px 0.5px #969696;
  transition: all 0.8s;
}

.displayListingCard7 {
  width: 100%;
  height: 400px;
  /* margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 15px;
  margin-top: 0; */
  border-radius: 5px;
  padding: 0;
  border-spacing: 0;
  overflow: hidden;
  outline: 0;
  display: flex;
  flex-direction: column;
  /* box-shadow: 2px 5px 7px black; */
  box-shadow: 3px 1px 3px 0.5px #969696;
  transition: all 0.8s;
}

.displayListingCard:hover {
  border: 1px solid #035aa6;
  opacity: 0.5;
  background-color: white;
  /* margin-left: 0.25%;
  margin-right: 0.25%; */
  /* z-index: 1; */
  /* height: 451px; */
  outline: 0;
  /* margin-top: -10px; */
  /* width: 24.5%; */
}

.displayListingCard7:hover {
  border: 1px solid #035aa6;
  opacity: 0.5;
  background-color: white;
  /* margin-left: 0.25%;
  margin-right: 0.25%; */
  /* z-index: 1; */
  /* height: 451px; */
  outline: 0;
  /* margin-top: -10px; */
  /* width: 24.5%; */
}

.displayListingCard2 {
  width: 48%;
  margin-top: 18px;
  margin-left: 1.5%;
  margin-right: 1.5%;
  border-radius: 5px;

  /* overflow-x: hidden; */
}

.displayListingCard img {
  outline: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  opacity: 1;
  object-fit: cover;
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1),
    height 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.displayListingCard7 img {
  outline: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  opacity: 1;
  object-fit: cover;
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1),
    height 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.displayListingCard img:hover {
  opacity: 0.5;
}

.displayListingCard7 img:hover {
  opacity: 0.5;
}

.displayListingCard .card-body {
  display: flex;
  flex-direction: row;
}

.displayListingCard7 .card-body {
  display: flex;
  flex-direction: row;
}

.card-body .title_locationCon {
  height: 100%;
  flex: 1;
  padding: 0 10px;
}

.title_locationCon .card-title1 {
  font: normal normal bold 18px/25px Merriweather;
  letter-spacing: 1.13px;
  color: #222222;
}
.title_locationCon .card-title2 {
  font: normal normal bold 16px/25px Merriweather;
  letter-spacing: 1.13px;
  color: #222222;
}
.title_locationCon p {
  font: normal normal normal 12px/20px Merriweather;
  margin-top: 10px;
  letter-spacing: 0.72px;
  color: #222222;
}

.price_posted {
  height: 100%;
  flex: 1;
}

.price_posted h6 {
  color: #222222;
  letter-spacing: 1.13px;
  font: normal normal normal 14px/19px Merriweather;
}

.price_posted p {
  font: normal normal 300 12px/15px Merriweather;
  letter-spacing: 0.54px;
  color: #222222;
}
/* style for Footer1 component */

.footer1OuterCon {
  /* display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: row;
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: hidden; */
  padding-bottom: 20px;
}
.footer1IconClass {
  height: 50px;
  width: 50px;
  fill: #035aa6;
}

.footer1CardCon1 {
  width: 100%;
  min-height: 400px;
  /* margin-top: 18px;
  margin-left: 0.5%;
  margin-right: 0.5%; */
  border-radius: 5px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  /* --webkit-box-shadow: 3px 1px 3px 0.5px #969696; */
}

.footer1IconCon {
  width: 100%;
  height: 70px;
  color: white;
  /* background-color: #035aa6; */
  margin: 20px auto 0px auto;
  position: relative;
  text-align: center;
}

.footer1CardCon1 h5 {
  text-align: center;
  color: #035aa6;
  letter-spacing: 0.9px;
  margin-bottom: 20px;
  font: normal normal bold 18px/25px Merriweather;
}

.footer1CardCon1 p {
  font: normal normal normal 14px/19px Merriweather;
  letter-spacing: 0.81px;
  color: #222222;
  padding: 0 20px;
}

.footer1Bold {
  color: #1a1110;
}

.footer1LinkCon {
  /* position: absolute; */
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.footer1LinkCon a {
  width: 70%;
  font-family: "Bree Serif", serif;
  color: #035aa6;
  border: 1.5px solid #035aa6;
  padding-top: 12px;
  border-radius: 10px;
}
.footer1LinkCon a:hover {
  opacity: 0.6;
  color: white;
}
/* styling for Footer2 component */

.footer2OuterCon {
  width: 100%;
  padding: 0 5%;
  min-height: 280px;
  border-top: 5px solid #035aa6;
  background-color: #dddddd;
  margin-top: 0px;
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
}
.footer2OuterCon a {
  text-decoration: none;
}

.solutionContainer {
  width: 100%;
  color: #ffffff;
  height: 320px;
  display: flex;
  flex-direction: column;
}

.solutionContainer .title {
  text-align: left;
  margin-left: 20%;
  margin-top: 35px;
  font: normal normal bold 15px/18px Merriweather-sans;
  letter-spacing: 0.72px;
}

.solutionContainer p {
  text-align: left;
  margin-left: 20%;
  font: normal normal normal 14px/27px Merriweather;
  letter-spacing: 0.58px;
  color: black;
}

.aboutUsContainer {
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
}

.aboutUsContainer .title {
  text-align: left;
  font: normal normal bold 15px/18px MMerriweather-sans;
  letter-spacing: 0.72px;
  margin-left: 20%;
  color: black;
  margin-top: 35px;
}

.aboutUsContainer p {
  text-align: left;
  font: normal normal normal 14px/27px Merriweather;
  letter-spacing: 0.58px;
  color: black;
  margin-left: 20%;
}

.contactUsContainer {
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
}
.contactUsContainer .title {
  text-align: left;
  font: normal normal bold 15px/16px Merriweather-sans;
  color: black;
  margin-left: 20%;
  margin-top: 35px;
}

.contactUsContainer p {
  text-align: left;
  font: normal normal normal 14px/27px Merriweather;
  letter-spacing: 0.58px;
  color: black;
  margin-left: 20%;
}

.contactUsLinksCon {
  display: flex;
  flex-direction: row;
  margin-left: 20%;
  width: 50%;
}

.newletterContainer {
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
}

.newletterContainer .title {
  text-align: left;
  font: normal normal bold 15px/18px Merriweather-sans;
  letter-spacing: 0.72px;
  color: black;
  margin-left: 20%;
  margin-top: 35px;
}

.newletterContainer p {
  text-align: left;
  color: black;
  font: normal normal normal 14px/27px Merriweather;
  letter-spacing: 0.58px;
  margin-left: 20%;
}

.newletterContainer input {
  width: 250px;
  background-color: rgba(232, 244, 243, 0.6);
  color: #035aa6;
  padding-left: 5px;
  border: #035aa6 solid 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 20%;
  border-radius: 10px;
  font-size: 18px;
  outline: none;
}

.newletterContainer button {
  background-color: #035aa6;
  box-shadow: 3px 1px 3px 0.5px black;
  border-radius: 30px;
  width: 150px;
  padding: 10px;
  font-size: 18px;
  margin-left: 25%;
  color: white;
  border: solid 1px #035aa6;
  margin-top: 10px;
  font-family: "Bree Serif", serif;
  cursor: pointer;
}

.newletterContainer button:hover {
  background-color: white;
  color: #035aa6;
}

.terms_copy_container {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  padding-left: 5%;
  background-color: #dddddd;
}

.terms_copy_container .copyContainer {
  flex: 1;
  margin-left: 10px;
}

.copyContainer h5 {
  font: normal normal normal 10px/12px Merriweather;
  letter-spacing: 0.72px;
  color: #bcbcbc;
}

.terms_copy_container .policyContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
.terms_copy_container .policyContainer span {
  margin: -3px 5px 0px 5px;
  color: White;
  opacity: 0.8;
}
.terms_copy_container .policyContainer a {
  text-decoration: none;
}

.terms_copy_container h5 {
  font: normal normal normal 15px/20px Merriweather;
  letter-spacing: 0.72px;
  color: #035aa6;
}

/* styling for NumberOfListingsBar component */
.numberOfListingCon {
  width: 100%;
  display: flex;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 70px;
  background-color: #035aa6;
  /* box-shadow: 3px 1px 3px 0.5px #969696; */
  box-shadow: 2px 5px 7px 0.5px grey;
}

.numberOfListingCon h3 {
  opacity: 1;
  color: #ffffff;
  letter-spacing: 1.13px;
  font: normal normal normal 23px/30px Merriweather;
}

/* styling for Pagination Component */

.paginationOuterCon {
  margin: 50px 10px;
  width: auto;
}

.paginationOuterCon .previousBtn {
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #035aa6;
  color: #035aa6;
  letter-spacing: 1.13px;
  font: normal normal bold 18px/27px Merriweather;
  border-radius: 5px;
}

.paginationOuterCon .page-link {
  margin-left: 7px;
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #035aa6;
  color: black;
  letter-spacing: 1.13px;
  font: normal normal bold 18px/27px Merriweather;
  border-radius: 5px;
}

.paginationOuterCon .page-link1 {
  margin-left: 7px;
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #035aa6;
  color: black;
  background-color: #035aa6;
  letter-spacing: 1.13px;
  font: normal normal bold 18px/27px Merriweather;
  border-radius: 5px;
}

/* .paginationOuterCon a:hover {
  background-color: #035aa6;
  color: white;
} */

.paginationOuterCon button {
  background-color: white;
  margin-left: 7px;
  height: 30px;
  color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.13px;
  font: normal normal bold 20px/27px Merriweather;
  border-radius: 5px;
  border: 2px solid white;
  cursor: pointer;
}

/* styling for SelectedItem Component */
.numberOfBedrooms {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectInput,
.numberOfBedrooms {
  width: 100%;
  color: #222222;
  font: normal normal 300 18px/25px Merriweather;
  letter-spacing: 1.13px;
  border-radius: 5px;
  /* border: 1px solid #707070; */
  background: #ffffff 0% 0% no-repeat padding-box;
}

/* styling for PictureModal Component */

.pictureModalContent {
  width: 1000px;
  height: 700px;
}

.homeSpinnerContainer {
  width: 100%;
  margin-top: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeOuterContainer {
  /* margin-top: 35px; */
  width: 100%;
  overflow: hidden;
}

.homeHrContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
}
.homeHrContainer hr {
  width: 40%;
  box-shadow: 3px 3px 6px #00000029;
  height: 0.4px;
  opacity: 1;
  background: #035aa6 0% 0% no-repeat padding-box;
}

@media (max-width: 1250px) {
  .dropdownHouse {
    margin-left: 1%;
  }
  .homeContainer {
    margin-left: 20%;
    width: 200px;
    color: #707070;
    opacity: 1;
  }
  .housesContainer {
    margin-left: 5%;
    width: 110px;
  }
  .landsContainer {
    margin-left: 2%;
  }
}

@media (max-width: 1140px) {
  .homeContainer {
    margin-left: 5%;
    width: 200px;
    color: #707070;
    opacity: 1;
  }
  .housesContainer {
    margin-left: 2%;
    width: 110px;
  }
  .landsContainer {
    margin-left: 1%;
  }
}

@media (max-width: 991px) {
  .headerInputsCon {
    width: 98%;
  }
  .searchCountryCon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .searchStateCon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    width: 100%;
  }
  .searchCityCon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .headerSearchVideoCon {
    height: 100vh;
  }
  .listingTypeCon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 12px;
  }
  .searchBedroomCon {
    width: 100%;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .searchKeywordCon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 12px;
  }

  .searchTitleConing {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 7px;
  }
  .searchBtnCon {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
  /* .textInputKeywordCon {
    margin: 0 5%;
  } */
  .pleaseCheckNetwork {
    width: 80%;
  }
  .pleaseCheckNetwork {
    width: 80%;
  }
  /* styling for Navbar component */
  .terms_copy_container .policyContainer span {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 10px;
  }
  .portalContainerClient {
    position: relative;
    margin-left: 0;
    margin-top: 15px;
  }

  .navImgCon .userIcon {
    width: 30px;
    height: 30px;
    margin-top: 2px;
    margin-left: 5px;
  }

  .portalContainerClient button {
    width: 200px;
  }
  .dropdownHouse {
    margin-left: 0%;
  }

  .dropdownLands {
    margin-left: 0%;
  }

  .navbar-toggler {
    position: absolute;
    right: 5%;
  }

  .logoutContainer {
    font-size: 20px;
    position: relative;
    margin-left: 0;
    right: 15px;
    margin-top: 20px;
    max-width: 250px;
    padding: 5;
    text-align: left;
  }

  .globeContainer {
    font-size: 20px;
    position: relative;
    right: 5px;
    margin-top: 20px;
    width: 70px;
  }

  .logoutContainer a {
    font-size: 20px;
  }

  .logo {
    width: 150px;
    height: 50px;
  }

  .logoPara {
    font-size: 12px;
  }

  .homeContainer {
    margin-left: 0%;
    width: 200px;
  }

  .homeContainer a {
    font-size: 20px;
  }

  .housesContainer {
    margin-left: 0%;
    width: 230px;
  }

  .housesContainer a {
    font-size: 20px;
  }

  .housesContainer i {
    font-size: 30px;
  }

  .landsContainer {
    margin-left: 0%;
    width: 150px;
  }

  .landsContainer a {
    font-size: 20px;
  }

  .landsContainer i {
    font-size: 30px;
  }

  .portalContainer {
    margin-left: 0%;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
  }

  .myPortalContainer {
    margin-left: 0%;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
  }

  .portalContainer a {
    font-size: 16px;
  }
  .myPortalContainer a {
    font-size: 16px;
  }

  .managementContainer {
    margin-left: -5px;
    margin-top: 20px;
    position: relative;
  }

  .myManagementContainer {
    margin-left: -5px;
    margin-top: 20px;
    position: relative;
  }

  .navProfileContainer {
    font-size: 20px;
    position: relative;
    margin-left: 0;
    right: 23px;
    margin-top: 20px;
    width: 250px;
  }
  .navProfileContainer1 {
    font-size: 20px;
    position: relative;
    margin-left: 0;
    right: 50px;
    margin-top: 20px;
    width: 250px;
  }
  .navProfileContainer a {
    text-align: left;
  }
  .navProfileContainerName {
    font-size: 20px;
    position: relative;
    margin-left: 0;
    right: 20px;
    margin-top: 20px;
    width: 250px;
  }
  .navProfileContainerName1 {
    font-size: 20px;
    position: relative;
    margin-left: 0;
    right: 20px;
    margin-top: 20px;
    width: 250px;
  }
  .navProfileContainerName a {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }
  .navProfileContainerName1 a {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  .navProfileContainerName .navImgCon {
    width: 40px;
    height: 40px;
    border: 1px solid black;
    border-radius: 100%;
    flex: 1;
    position: absolute;
    margin-left: 1px;
  }
  .navProfileContainerName1 .navImgCon {
    width: 40px;
    height: 40px;
    border: 1px solid black;
    border-radius: 100%;
    flex: 1;
    position: absolute;
    margin-left: 1px;
  }

  .navProfileContainerName .name {
    font-size: 18px;
    margin-top: 7px;
    margin-left: 45px;
  }

  .navProfileContainerName1 .name {
    font-size: 18px;
    margin-top: 7px;
    margin-left: 45px;
  }

  .navProfileContainer img {
    height: 40px;
    width: 40px;
  }

  .navProfileContainerName img {
    height: 40px;
    width: 40px;
  }

  .navProfileContainerName1 img {
    height: 40px;
    width: 40px;
  }

  .navProfileContainer .name {
    font-size: 18px;
  }

  .bi-globe {
    width: 30px;
    height: 30px;
  }

  .globaldropDownContainer {
    top: 450px;
    left: 1%;
    width: 50%;
  }

  .globaldropDownContainer li {
    margin-left: 0px;

    width: 100%;
  }

  /* styling for HeaderSearch component */

  .recievedMessageCon {
    top: 100px;
  }

  .recievedMessageCon1 {
    top: 100px;
  }
  .headerTitleCon {
    margin-top: 30px;
  }

  .headerTitleConh1 {
    font-size: 30px;
    margin-top: 10px;
  }
  .span {
    margin-left: 7px;
  }
  .headerTitleConP {
    font-size: 25px;
  }

  .headerSearchContainer {
    height: 850px;
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }
  .headerTitleConh1 {
    font-size: 18px;
  }

  .headerTitleConP {
    font-size: 18px;
  }

  #carouselExampleSlidesOnly .carousel-inner {
    height: 850px;
    width: 100%;
  }

  #carouselExampleSlidesOnly img {
    height: 850px;
    width: 100%;
  }

  .headerSearchInputContainer {
    width: 98%;
    margin-top: 20px;
    /* margin-left: 1%; */
    min-height: 800px;
    position: absolute;
  }

  .searchBedroomCon {
    width: 100%;
    margin-top: 12px;
  }
  /* .headerSearchInputContainer .country,
  .headerSearchInputContainer .state,
  .headerSearchInputContainer .city,
  .headerSearchInputContainer .bedroom,
  .headerSearchInputContainer .listingType,
  .headerSearchInputContainer .keyword {
    width: 90%;
  } */
  /* .searchBedroomCon {
    margin-left: 5%;
    margin-right: 30%;
  }
  .searchStateCon,
  .searchCityCon,
  .searchCountryCon,
  .listingTypeCon {
    margin-left: 0%;
    display: flex;
    justify-content: center;
  }

  .searchCurrencyCon {
    margin-left: 0%;
    margin-top: 20px;
  } */

  /* .searchBtnCon {
    display: flex;
    width: 100%;
    justify-content: center;
  } */

  .priceCompoCon {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
  }

  .searchButton {
    background-color: #035aa6;
    color: white;
    width: 80%;
    margin-top: 20px;
    font-family: "Bree Serif", serif;
  }

  /* styling for NumberOfListingsBar component */
  .numberOfListingCon {
    width: 100%;
    display: flex;
    background-color: #035aa6;
    text-align: center;
  }
  .numberOfListingCon h3 {
    font-size: 18px;
  }

  /* styling for DisplayCard component */

  .displayListingCard {
    width: 98%;
    height: 500px;
    margin-top: 0px;
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 5px;
    overflow-x: hidden;
  }
  .displayListingCard7 {
    width: 100%;
    height: 500px;
    /* margin-top: 0px;
    margin-left: 1%;
    margin-right: 1%; */
    border-radius: 5px;
    overflow-x: hidden;
  }
  .displayListingCard:hover {
    border: 1px solid #035aa6;
    opacity: 0.5;
    background-color: white;
    margin-left: 1%;
    margin-right: 1%;
    /* z-index: 1; */
    height: 500px;
    outline: 0;
    width: 100%;
  }
  .displayListingCard7:hover {
    border: 1px solid #035aa6;
    opacity: 0.5;
    background-color: white;
    margin-left: 1%;
    margin-right: 1%;
    /* z-index: 1; */
    height: 500px;
    outline: 0;
    width: 100%;
  }

  .displayListingCard img {
    height: 400px;
    object-fit: cover;
  }

  .displayListingCard7 img {
    height: 400px;
    object-fit: cover;
  }
  .displayListingCard img:hover {
    height: 400px;
  }
  .displayListingCard7 img:hover {
    height: 400px;
  }

  .displayListingCard .card-body {
    display: flex;
    flex-direction: row;
  }

  .displayListingCard7 .card-body {
    display: flex;
    flex-direction: row;
  }

  .card-body .title_locationCon {
    height: 100%;
    flex: 2;
  }

  .title_locationCon h6 {
    font-size: 18px;
    font-family: "Bree Serif", serif;
  }

  .price_posted {
    height: 100%;
    flex: 1;
  }

  .price_posted h6 {
    font-family: "Bree Serif", serif;
    margin-left: 5%;
  }
  .price_posted p {
    margin-left: 5%;
  }

  /* style for Footer1 component */

  .footer1OuterCon {
    /* display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: row;
    flex-wrap: wrap; */
  }

  .footer1CardCon1 {
    width: 100%;
    /* padding: 40px; */
    min-height: 400px;
    margin-top: 18px;
    box-shadow: revert;
    /* margin-left: 1%;
    margin-right: 1%; */
  }

  .footer1IconCon {
    width: 70px;
    height: 70px;
    color: white;
    margin: 20px auto 10px auto;
    position: relative;
    text-align: center;
    text-align: center;
  }

  .footer1CardCon1 h5 {
    text-align: center;
    font-family: "Bree Serif", serif;
    margin-bottom: 20px;
  }

  .footer1CardCon1 p {
    text-align: center;
    margin-left: 0;
    font-size: 16px;
    font-family: "Merriweather", serif;
  }

  .footer1Bold {
    color: #1a1110;
  }

  .footer1LinkCon a {
    width: 80%;
    padding-top: 12px;
    font-family: "Bree Serif", serif;
    color: #035aa6;
    border: 1.5px solid #035aa6;
    border-radius: 10px;
  }

  /* styling for Footer2 component */

  .footer2OuterCon {
    width: 100%;
    min-height: 500px;
    border-top: 5px solid #035aa6;
    background-color: #dddddd;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
  }

  .solutionContainer {
    width: 100%;
    color: white;
    height: 100px;
    display: flex;
    flex-direction: column;
  }

  .solutionContainer .title {
    text-align: center;
    font-family: "Bree Serif", Serif;
    margin-left: 0%;
    margin-top: 35px;
  }

  .solutionContainer p {
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
    margin-left: 0%;
  }

  .aboutUsContainer {
    width: 100%;
    color: black;
    height: 250px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .aboutUsContainer .title {
    text-align: center;
    font-family: "Bree Serif", Serif;
    margin-left: 0%;
    color: black;
    margin-top: 60px;
  }

  .aboutUsContainer p {
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
    margin-left: 0%;
  }

  .contactUsContainer {
    width: 100%;
    display: flex;
    flex-direction: flex-start;
    text-align: center;
    height: auto;
    padding-bottom: 10px;
  }
  .contactUsContainer .title {
    text-align: center;
    font-family: "Bree Serif", Serif;
    margin-left: 0%;
    color: black;
    margin-top: 10px;
  }

  .contactUsContainer p {
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
    margin-left: 0%;
  }

  .contactUsLinksCon {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }

  .newletterContainer {
    width: 100%;
    height: 320px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .newletterContainer .title {
    text-align: center;
    font-family: "Bree Serif", Serif;
    margin-left: 0%;
    color: black;
    margin-top: 25px;
  }

  .newletterContainer p {
    text-align: center;
    color: black;
    font-family: "Roboto Condensed", sans-serif;
    margin-left: 0%;
  }

  .newletterContainer input {
    width: 250px;
    background-color: rgba(232, 244, 243, 0.6);
    color: white;
    padding-left: 5px;
    border: white solid 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
    border-radius: 10px;
    font-size: 18px;
  }

  .newletterContainer button {
    background-color: #035aa6;
    border-radius: 30px;
    width: 150px;
    padding: 10px;
    font-size: 18px;
    margin: 10px auto;
    color: white;
    border: solid 1px #035aa6;
    font-family: "Bree Serif", serif;
  }

  .terms_copy_container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    /* margin-top: 10px; */
  }

  .copyContainer h5 {
    color: rgba(232, 244, 243, 0.6);
    font-family: "Merriweather", serif;
    font-size: 18px;
    text-align: center;
  }

  .terms_copy_container .policyContainer {
    justify-content: center;
  }

  .terms_copy_container h5 {
    color: #035aa6;
    font-family: "Merriweather", serif;
    font-size: 18px;
    margin-top: 30px;
  }
}

@media (max-height: 1366px) and (min-height: 1024px) {
  .navProfileContainerName {
    font-size: 20px;
    position: relative;
    margin-left: 0;
    right: 20px;
    margin-top: 20px;
    width: 250px;
  }
  .navProfileContainerName1 {
    font-size: 20px;
    position: relative;
    margin-left: 0;
    right: 20px;
    margin-top: 20px;
    width: 250px;
  }

  .logoutContainer {
    font-size: 20px;
    position: relative;
    margin-left: 0;
    right: 15px;
    margin-top: 20px;
    width: 120px;
    text-align: left;
  }
  .newletterContainer input {
    margin: 0 auto;
  }
  .newletterContainer button {
    margin: 10px auto;
  }

  .contactUsLinksCon {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }

  .footer1CardCon1 {
    min-height: 400px;
  }

  .footer1LinkCon {
    margin-top: 350px;
  }
}
