.consultingServicesOuterCon {
  width: 100%;
  min-height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.consultantSerContentOuterCon {
  width: 100%;
  padding-top: 80px;
  padding: 50px 22%;
}

.consultantSerHeadOuterCon {
  width: 100%;
  height: 60vh;
  /* background-position: center center; */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  margin-top: 5px;
  background-color: lightgray;
}

.consultantSerHeadOuterCon .consultant {
  color: white;
  font-size: 70px;
  font-family: "DM Serif Display", serif;
}

.consultantHelpInfo {
  text-align: center;
  width: 100%;
  padding: 50px 0;
}
.consultantHelpInfo h1 {
  font-size: 40px;
  color: #ffa500;
}
.consultantHelpInfo p {
  font-size: 22px;
  padding: 0 25%;
  line-height: 2;
}

.consultSerCardContainer {
  width: 100%;
  padding: 50px 10%;
  display: flex;
  justify-content: space-around;
}

.contactUsCardContainer {
  background: #eef2f6;
  width: 30%;
  text-align: center;
  padding: 30px 0 20px 0;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
}

.contactUsCardContainer h1 {
  margin-bottom: 20px;
  font-weight: bolder;
  color: #035aa6;
}

@media (max-width: 991px) {
  .consultantSerContentOuterCon {
    width: 100%;
    padding-top: 80px;
    padding: 50px 1%;
  }
  /*  */
  .consultantSerHeadOuterCon {
    height: 70vh;
    padding-left: 0;
    justify-content: center;
    align-items: center;
  }
  .consultantSerHeadOuterCon .consultant {
    font-size: 30px;
    justify-content: center;
    align-items: center;
    padding-left: 0;
  }

  .consultantHelpInfo {
    padding: 20px 0;
  }

  .consultantHelpInfo h1 {
    font-size: 20px;
    color: #ffa500;
    margin-left: 0;
  }
  .consultantHelpInfo p {
    font-size: 16px;
    padding: 0 10%;
    line-height: 2;
  }
  .consultSerCardContainer {
    padding: 10px 0;
    flex-direction: column;
  }

  .contactUsCardContainer {
    background: #eef2f6;
    width: 98%;
    text-align: center;
    margin: 10px auto;
    margin-left: 2%;
    padding: 30px 10px 20px 10px;
    border-radius: 10px;
    font-family: "Montserrat", sans-serif;
  }

  .contactUsCardContainer h3 {
    margin-bottom: 16px;
    font-weight: bolder;
    color: #035aa6;
    font-size: 18px;
  }
}
