
.rentSaleDetOuterCon{
 width: 100%;
  height: 70vh;
  margin-top: 45px;
}

.rentSaleDetInnerCon{
width: 80%;
height: 50%;
position: absolute;
background: white;
margin-top:100px;
left: 50%;
transform: translateX(-50%);
box-shadow: 5px 5px 5px 5px rgba(0, 0, 255, .2);
display: flex;
}

.rentSaleH1InnerCon{
flex: 1;
display: flex;
justify-content: center;
align-items: center;
padding: 30px
                      
}

.rentSaleH1InnerCon h1{

font-size: 45px;
opacity: 0.8;
font-family: 'DM Serif Display', serif
                               
}

.rentSaleBackDateCon{
width: 52%;
height: 30px;
background-color: #035aa6;               
position: absolute;
bottom: 0px;
display: flex;            
align-items: center;
padding: 0 10px
                       
}

.rentSaleBackCon{
flex: 1;
display: flex;
justify-content: flex-start;
align-items: center
                 
}

.rentSaleBackCon a{
            
color: white;
display: flex;
justify-content: center;
align-items: center;
text-decoration: none
                   
}

.addStyles{
font-size: 35px;
 cursor: pointer
}

.rentSaleBackCon a span{
margin-left: 7px;
font-weight: 700;
font-size: 18px;                    
}

.rentSaleIconDate{
flex: 1;
display: flex;
justify-content: flex-end;
align-items: center                      
}

.rentSaleIconDate .dating{
margin-left: 7px;
color: white;
font-weight: 700px;
opacity: 1                     
}

.rentSaleBackgroundCon{
width: 100%;
height: 50%;
background: #035aa6
}

.rentDetailBodyCon{
width: 80%;
 margin: 0 auto
}

.rentDetailBodyCon h1{
font-size: 30px;
opacity: 0.8;
margin-bottom: 50px                          
}

.rentDetailBodyCon p{
font-size: 20px;
 text-indent: 70px
}

.rentSaleResponseCon{
 width: 100%;
height: 200px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
background: #f5f5f5
                        
}
.rentSaleResponseCon .firstH1{
text-align: center;
opacity: 0.8;
margin-bottom: 0;
font-weight: bold;           
}

.rentSaleResponseCon .secondH1{
 margin-top: 10px;
 font-size: 23px;
 opacity: 0.7
}

.rentSaleResponseBtnCon{
display: flex;
margin-top: 20px
}

.authorH1{
text-align: center;
margin: 50px 0;
opacity: 0.8
}

.rentSaleAuthorCon{
width: 100%;
height: 300px;
background-color: #035aa6;
display: flex;
padding: 0 15%                  
}

.rentSaleImageContainer{
flex: 1;
display: flex;
align-items: center;
justify-content: flex-end;
padding-right: 20px              
}

.rentSaleImageContainer .onlyImage{
    width: 220px;
height: 220px;
border-radius: 100%;
overflow: hidden;
border: 5px solid white;
margin-top: 10px                        
}

.rentSaleImageContainer .onlyImage img{
     width: 100%;
     height: 100%
}

.rentSaleAuthorContent{ 
flex: 7;
color: white;
display: flex;
flex-direction: column;
justify-content: center;
padding-left: 10px
                      
}

.rentSaleAuthorContent h1{
margin-bottom: 10px
}

.rentSaleAuthorContent p{
font-size: 18px;
line-height: 2
}

@media (max-width: 991px) {
  
  .rentSaleH1InnerCon{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
                          
    }

    .rentSaleDetInnerCon{
      width: 98%;
      height: 50%;
      position: absolute;
      background: white;
      margin-top:50px;

      box-shadow: 5px 5px 5px 5px rgba(0, 0, 255, .2);
      display: flex;
      flex-direction: column;
      }

      .rentSaleH1InnerCon h1{
        font-size: 20px;
        opacity: 0.8;
      text-align: center;                         
        }

        .rentSaleDetOuterCon{
          width: 100%;
           height: 70vh;
           margin-top: 45px;
         }

        .rentSaleBackDateCon{
          width: 100%;
          height: 30px;
          background-color: #035aa6;               
          position: absolute;
          bottom: 0px;
          display: flex;            
          align-items: center;
          padding: 0 10px
                                 
          }

          .addStyles{
            font-size: 25px;
             cursor: pointer
            }

            
.rentSaleBackCon a span{
  margin-left: 7px;
  font-weight: 700;
  font-size: 14px;                    
  }
  .RentPlatformHeadIcon1{
    font-size: 25px;
  }

  .rentSaleIconDate .dating{
font-size: 14px;                    
    }

    .rentDetailBodyCon h1{
      font-size: 20px;                      
      }
      
      .rentDetailBodyCon p{
      font-size: 16px;
       text-indent:10px
      }
      .rentSaleResponseCon{
        width: 100%;
       height: 400px;
      }
 
       .rentSaleResponseCon .firstH1{
       font-size: 20px;        
       }
       
       .rentSaleResponseCon .secondH1{
        font-size: 16px;
       }

       .rentSaleResponseBtnCon{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
     
        }

        .rentLove{
          margin-bottom: 10px;
        }

        .rentHelpful{
          margin-top: 10px;
        }
        
   .authorH1{
    font-size: 18px;
   }   
   
   .rentSaleAuthorCon{
    width: 100%;
    height: auto;
    background-color: #035aa6;
    display: flex;
    padding: 0 15%  ;
    flex-direction: column;            
    }

    .rentSaleImageContainer .onlyImage{
      width: 110px;
  height: 110px;
                     
  }

  .rentSaleImageContainer{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px              
    }

    .rentSaleAuthorContent h1{
      margin-bottom: 10px;
      font-size: 20px;
      }
       

      .rentSaleAuthorContent p{
        font-size: 16px;
        line-height: 1.5
        }
        
  
}