.googleAndFacebookOuterContainer1 {
  width: 40%;
  border: 1px solid white;
  opacity: 0.78;
  background: #bcbcbc 0% 0% no-repeat padding-box;
  position: absolute;
  margin: 70px 30%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 400px;
  border-radius: 10px;
}

.googleBtnContainer {
  width: 30%;
  opacity: 0.78;
  background: #bcbcbc 0% 0% no-repeat padding-box;
  position: absolute;
  top: 50%;
  left: 45%;
  margin: -25px 0 0 -100px;
  border-radius: 10px;
  height: 200px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fbLoginBtnContainer {
  width: 30%;
  opacity: 0.78;
  background: #bcbcbc 0% 0% no-repeat padding-box;
  position: absolute;
  top: 50%;
  left: 45%;
  margin: -25px 0 0 -100px;
  border-radius: 10px;
  height: 200px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.facebookLoginBtn {
  background-color: #035aa6;
  padding: 20px 20px;
  border: 1px solid #035aa6;
  color: white;
  cursor: pointer;
  border-radius: 30px;
  font: normal normal bold 14px/20px Merriweather;
}
.googleLoginBtn {
  padding: 20px 40px;
  border-radius: 30px;
  border: 1px solid #035aa6;
  cursor: pointer;
  background: #035aa6;
  color: white;
}

.googleLoginBtn span {
  margin-left: 10px;
  font: normal normal bold 18px/20px Merriweather;
}

.googleLoginBtn:hover {
  background-color: white;
  border: 1px solid #035aa6;
  color: #035aa6;
}

.facebookLoginBtn:hover {
  background-color: white;
  padding: 20px 20px;
  border: 1px solid #035aa6;
  color: #035aa6;
}

.registerCheckbox .termAtag {
  color: #035aa6;
}

.termsConditionCheckbox {
  cursor: pointer;
}

.loginLoadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginErrorContainer {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
.loginOuterCon {
  box-shadow: 6px 12px 12px 12px #00000029;
  width: 40%;
  padding: 20px;
  border-radius: 5px;
  height: 580px;
  margin: 80px auto;
}

.googleFb_Container {
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 100%;
  height: 30px;
}

.google_container {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.fb_container {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: left;
  align-items: center;
}

.loginOuterCon .title {
  font-family: "Merriweather", serif;
  font-size: 18px;
}

.headerSeparator {
  width: 20%;
  margin: 10px auto 30px auto;
  background-color: #035aa6;
}

.hrContainerLogin {
  display: flex;
  flex-direction: row;
}

.firstLineContainer {
  flex: 1;
  border: 1px solid #bcbcbc;
  border: 1px solid var(--unnamed-color-bcbcbc);
}

.paraContainer {
  flex: 1.2;
}

.paraContainer p {
  font-size: 12px;
}

.loginOuterCon label {
  font-family: "Merriweather", serif;
  font-size: 14px;
  margin-left: 0%;
}

.loginOuterCon .passwordLabel {
  font-family: "Merriweather", serif;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 0%;
}

.loginOuterCon input {
  width: 60%;
  margin: 0 auto;
  border: 1px solid var(--unnamed-color-bcbcbc);
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  opacity: 1;
}

.loginOuterCon button {
  font: normal normal bold 18px/20px Merriweather;
  background-color: #035aa6;
  border: 1px #035aa6 solid;
  padding: 10px;
  width: 60%;
  margin: 30px auto 10px auto;
  color: white;
  border-radius: 30px;
  transition: all 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.loginOuterCon button:hover {
  background-color: white;
  color: #035aa6;
}

.loginFooter {
  position: relative;
  width: 100%;
  height: 60px;
}

.loginFirstLine {
  width: 30%;
  position: absolute;
  margin-left: 10%;
  height: 1px;
}
.authenticationInputContainer {
  margin-left: 15%;
}
.authenticationInputContainer label {
  font-family: "Merriweather", serif;
  font-size: 14px;
}
.AuthenticationSpinner {
  width: 50px;
  height: 50px;
  color: #035aa6;
}

.authenticationInputContainer input {
  width: 80%;
  border: 1px solid var(--unnamed-color-bcbcbc);
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
}
.loginSecondLine {
  width: 30%;
  margin-left: 60%;
  position: absolute;
  height: 1px;
}

.googleLink {
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 30px;
  border: #035aa6 solid 1px;
  opacity: 1;
  padding: 1px 10px;
  color: #035aa6;
  /* background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box; */
}

.googleLink:hover {
  background: #035aa6;
  color: white;
}

.googleLink img {
  width: 30px;
  height: 25px;
  border-radius: 100%;
}

.fbLink {
  margin-left: 10%;
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  padding: 1px 10px;
  border: 1px solid #035aa6;
  color: #035aa6;
}

.fbLink:hover {
  background: #035aa6;
  color: white;
}

.fbLink img {
  width: 30px;
  height: 25px;
  border-radius: 100%;
}

.googleLinkSpan {
  font-size: 10px;
  margin-left: 5px;
}

.noAccount {
  font: normal normal 300 12px/15px Merriweather;
}

.loginLinesPara {
  position: absolute;
  margin-top: 5px;
  margin-left: 42%;
  font-size: 14px;
  color: #222222;
}

/* STYLING TO REGISTER A USER */

.RegMD,
.RegEmail,
.RegPass,
.RegCP {
  margin-top: 10px;
}

.signUpOuterCon {
  display: flex;
  flex-direction: row;
  margin-top: 35px;
}

.SignUpInnerCon1 {
  width: 100%;
  margin: 5%;
  margin-top: 50px;
  height: 520px;
  box-shadow: 6px 12px 12px 12px #00000029;
  padding-top: 2px;
}

.SignUpInnerCon1 .titleContainer {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto 20px auto;
  padding-top: 10px;
  background: var(--primary-nav) 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  background: #035aa6 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px 5px 0px 0px;
}

.SignUpInnerCon1 h3 {
  letter-spacing: var(--unnamed-character-spacing-1-13);
  font: normal normal bold 20px/27px Merriweather;
  text-align: center;
  letter-spacing: 1.13px;
  color: black;
  opacity: 1;
}

.SignUpInnerCon1 .form-group {
  margin-top: 20px;
}

.SignUpInnerCon1 hr {
  width: 20%;
  height: 1px;
  background-color: #035aa6;
}

.SignUpInnerCon1 .btnContainer {
  width: 100%;
  text-align: center;
}

.SignUpInnerCon1 button {
  background-color: #035aa6;
  width: 40%;
  margin: 10px auto;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #035aa6;
  color: #ffffff;
  opacity: 1;
  letter-spacing: 1.13px;
  font: normal normal bold 18px/25px Merriweather;
}
.SignUpInnerCon1 button:hover {
  background-color: white;
  color: #035aa6;
}

.googleFbContainer {
  width: 200px;
  height: 170px;
  margin-top: 80px;
  margin-left: 10%;
  box-shadow: inset 3px 3px 6px #00000029, 3px 3px 6px #00000029;
  padding: 10px;
}

.googleFbContainer h5 {
  opacity: 1;
  text-align: center;
  letter-spacing: 1.13px;
  font: normal normal bold 16px/20px Merriweather;
}

.googleFbContainer .orSpan {
  color: #035aa6;
  font-size: 15.5px;
  letter-spacing: 1.13px;
  font: normal normal bold 16px/22px Merriweather;
}
.googleFbContainer .btnCon,
.googleFbContainer .btnGoogle {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.btnCon div,
.btnGoogle div {
  flex: 1;
}

.btnCon a {
  opacity: 1;
  width: 120px;
  padding: 5px 7px;
  border-radius: 30px;
  border: 1px solid #bcbcbc;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.btnCon a:hover {
  border: 1px solid #035aa6;
  background: #035aa6;
  color: white;
}

.btnCon img {
  width: 25px;
  height: 20px;
  border-radius: 100%;
}

.googleLinkSpan {
  opacity: 1;
  letter-spacing: 0.63px;
  font: normal normal 300 10px/12px Merriweather;
}

.rentOrBuyPropInputCon {
  margin-left: 7%;
  margin-top: 10px;
}

.rentOrBuyPropInputCon label {
  font-family: "Merriweather", serif;
  font-size: 14px;
}

.rentOrBuyPropInputCon select {
  width: 32%;
  border: 1px solid var(--unnamed-color-bcbcbc);
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
}

.rentOrBuyPropInputCon option {
  color: #bcbcbc;
  letter-spacing: 0.9px;
  font: normal normal normal 16px/20px Merriweather;
  color: var(--unnamed-color-bcbcbc);
}

.checkboxOuterCon {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.checkboxLabel {
  flex: 1.7;
}

.registerCheckbox {
  font-family: "Merriweather", serif;
  font-size: 14px;
  margin-left: 11%;
}

.checkboxInput {
  flex: 1;
}

.loginNoAccount {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  /* text-align: center; */
}

@media (max-width: 991px) {
  .googleBtnContainer {
    width: 70%;
  }
  .fbLoginBtnContainer {
    width: 70%;
  }
  .googleAndFacebookOuterContainer1 {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
  }
  .google_container {
    flex: 1;
    justify-content: center;
    text-align: left;
    align-items: center;
  }
  .googleFbContainer {
    width: 200px;
    height: 170px;
    margin: 0 auto;

    box-shadow: inset 3px 3px 6px #00000029, 3px 3px 6px #00000029;
    padding: 10px;
  }

  .googleFbContainer h5 {
    opacity: 1;
    text-align: center;
    letter-spacing: 1.13px;
    font: normal normal bold 16px/20px Merriweather;
  }

  .googleFbContainer .orSpan {
    color: #035aa6;
    font-size: 15.5px;
    letter-spacing: 1.13px;
    font: normal normal bold 16px/22px Merriweather;
  }
  .googleFbContainer .btnCon,
  .googleFbContainer .btnGoogle {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .btnCon div,
  .btnGoogle div {
    flex: 1;
  }

  .rentOrBuyPropInputCon {
    margin-left: 7%;
    margin-top: 10px;
  }

  .rentOrBuyPropInputCon label {
    font-family: "Merriweather", serif;
    font-size: 14px;
  }

  .rentOrBuyPropInputCon select {
    width: 70%;
    border: 1px solid var(--unnamed-color-bcbcbc);
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: 1px solid #bcbcbc;
    border-radius: 5px;
  }

  .rentOrBuyPropInputCon option {
    color: #bcbcbc;
    letter-spacing: 0.9px;
    font: normal normal normal 16px/20px Merriweather;
    color: var(--unnamed-color-bcbcbc);
  }
  .rentOrBuyPropInputCon {
    margin-left: 15%;
    margin-top: 10px;
  }
  .loginOuterCon {
    width: 90%;
    height: 580px;
    margin: 80px auto;
  }

  .loginOuterCon .title {
    font-family: "Merriweather", serif;
    font-size: 18px;
  }

  .checkboxOuterCon {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .checkboxLabel {
    flex: 5;
  }

  .checkboxInput {
    flex: 1;
  }

  .headerSeparator {
    width: 20%;
    margin: 10px auto 30px auto;
    background-color: #035aa6;
  }

  .loginOuterCon label {
    font-family: "Merriweather", serif;
    font-size: 14px;
    margin-left: 0%;
  }

  .loginOuterCon .passwordLabel {
    font-family: "Merriweather", serif;
    font-size: 14px;
    margin-top: 20px;
    margin-left: 0%;
  }

  .loginOuterCon input {
    width: 90%;
    margin: 0 auto;
    border: 1px solid var(--unnamed-color-bcbcbc);
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: 1px solid #bcbcbc;
    border-radius: 5px;
    opacity: 1;
  }

  .loginOuterCon button {
    font: normal normal bold 18px/20px Merriweather;
    background-color: #035aa6;
    padding: 10px;
    width: 90%;
    margin: 30px auto 10px auto;
    color: white;
    border-radius: 30px;
  }

  .loginFooter {
    position: relative;
    width: 100%;
    height: 80px;
  }

  .loginFirstLine {
    width: 30%;
    position: absolute;
    margin-left: 2%;
    height: 1px;
  }

  .loginSecondLine {
    width: 40%;
    margin-left: 60%;
    position: absolute;
    height: 1px;
  }

  .loginLinesPara {
    margin-left: 38%;
  }

  .googleLink {
    /* margin-top: 40px;
    margin-left: 15%; */
    /* position: absolute; */
    margin-left: 7%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    padding: 1px 10px;
    border: 1px solid #bcbcbc;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  }

  .googleLink img {
    width: 30px;
    height: 25px;
  }

  .fbLink {
    /* margin-top: 40px; */
    margin: 0 auto;
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    padding: 1px 10px;
    border: 1px solid #bcbcbc;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  }

  .fbLink img {
    width: 30px;
    height: 25px;
    border-radius: 100%;
  }

  .googleLinkSpan {
    font-size: 10px;
    margin-left: 5px;
  }

  .noAccount {
    font: normal normal 300 12px/15px Merriweather;
  }

  /* STYLING TO REGISTER A USER */

  .RegMD,
  .RegEmail,
  .RegPass,
  .RegCP {
    margin-top: 10px;
  }

  .signUpOuterCon {
    display: flex;
    flex-direction: column;
  }

  .SignUpInnerCon1 {
    width: 98%;
    margin: 1% auto 1% auto;
    height: 820px;
    box-shadow: 3px 3px 6px #00000029;
  }

  .SignUpInnerCon1 .titleContainer {
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto 20px auto;
    background: var(--primary-nav) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-707070);
    background: #035aa6 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px 5px 0px 0px;
  }

  .SignUpInnerCon1 h3 {
    letter-spacing: var(--unnamed-character-spacing-1-13);
    font: normal normal bold 20px/27px Merriweather;
    text-align: center;
    letter-spacing: 1.13px;
    color: black;
    opacity: 1;
  }

  .SignUpInnerCon1 .form-group {
    margin-top: 20px;
  }

  .SignUpInnerCon1 hr {
    width: 20%;
    height: 1px;
    background-color: #035aa6;
  }

  .SignUpInnerCon1 .btnContainer {
    width: 100%;
    text-align: center;
  }

  .SignUpInnerCon1 button {
    background-color: #035aa6;
    border: 1px solid #035aa6;
    width: 40%;
    margin: 10px auto;
    padding: 5px;
    border-radius: 5px;
    color: #ffffff;
    opacity: 1;
    letter-spacing: 1.13px;
    font: normal normal bold 18px/25px Merriweather;
  }

  .googleFbContainer {
    width: 200px;
    height: 170px;
    margin-top: 80px;
    box-shadow: inset 3px 3px 6px #00000029, 3px 3px 6px #00000029;
    padding: 10px;
  }

  .googleFbContainer h5 {
    opacity: 1;
    text-align: center;
    letter-spacing: 1.13px;
    font: normal normal bold 16px/20px Merriweather;
  }

  .googleFbContainer .orSpan {
    color: #035aa6;
    font-size: 15.5px;
    letter-spacing: 1.13px;
    font: normal normal bold 16px/22px Merriweather;
  }
  .googleFbContainer .btnCon,
  .googleFbContainer .btnGoogle {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .btnCon div,
  .btnGoogle div {
    flex: 1;
  }

  .btnCon a {
    opacity: 1;
    width: 100px;
    padding: 5px 7px;
    border-radius: 30px;
    border: 1px solid #bcbcbc;
    background: #ffffff 0% 0% no-repeat padding-box;
  }

  .btnCon img {
    width: 25px;
    height: 20px;
    border-radius: 100%;
  }

  .googleLinkSpan {
    color: #222222;
    opacity: 1;
    letter-spacing: 0.63px;
    font: normal normal 300 10px/12px Merriweather;
  }
}

@media (max-height: 1366px) and (min-height: 1024px) {
  .googleLink {
    /* margin-top: 40px;*/
    margin-left: 20%;
    position: absolute;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    padding: 1px 10px;
    border: 1px solid #bcbcbc;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  }

  .fbLink {
    /* margin-top: 40px; */
    margin-left: 10%;
    position: absolute;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    padding: 1px 10px;
    border: 1px solid #bcbcbc;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  }
  .loginLinesPara {
    margin-left: 41%;
  }
}
