.rentSalePublishCon{
width: 100%;
min-height: 100vh;
margin-top: 45px;
padding: 50px 20%;
font-family: 'Montserrat', sans-serif;
background-position: center center;
background-repeat: no-repeat;
background-size: cover;    
}

.rentSaleAddBack{
position: absolute;
right: 10px;
top: 150px;
color: black;
}

.rentSaleAddOpenIcon{
color: black;
font-size: 20px;
opacity: 0.8;
 margin-right: 7px
                      
}

.rentSalingBackBtnCon{
 margin-left: 20px;
 margin-bottom: 20px
}

.rentSaleBackArrowIcon{
color: black;
font-size: 20px;
opacity: 0.8;
margin-right: 7px
                   
}


.rentSaleInputsContainer{

width: 100%;
padding: 0 50px;
border: 1px solid ;
background: black;
color: white;
opacity: 0.9
                         
}

.rentSaleInputsContainer h1{
margin-top: 50px;
text-align: center;
margin-bottom: 50px;                    
}

.rentSaleAddInnerCon{
  display: flex;
  flex-wrap: wrap
}

.rentSalePublishBtnCon{
display: flex;
flex-direction: column;              
width: 100%;
}


.rentSalePublishBtnCon button{
width: 50%;
margin: 20px 25%;
background-color: #035aa6;
color: white;
border: 1px solid white;
font-weight: bold

}

.rentSaleAddInputConing{
padding-right: 1%;
 margin-top: 7px
}

.rentSaleAddSelect{
width: 49%;
padding-right: 1%;
margin-top: 7px
}

.rentSaleAddTextarea{
width: 100%;
padding-right: 10px;
margin-top: 7px
}

.rentSaleEditModalCon{
width: 50%;
padding: 20px  15px;
background: #035aa6;
position: absolute;
opacity: 1;
color: white;
right: 5px;
border-radius: 10px;
z-index: 5            
}
.rentSaleEditModalCloseCon{
position: absolute;
right: 10px;
top: 10px;
z-index: 7
}

.rentSaleEditModalSubtitle{               
text-align: center;
margin-bottom: 40px
}                


