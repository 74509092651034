  .blogAddStoryOuterCon{
width: 100%;
padding: 100px 50px 50px 50px;
font-family: 'Montserrat', sans-serif
  }

.blogAddStoryOpenBtn{
position: absolute;
right: 10px;
top: 150px
}

.blogAddStoryOpenIcon{
color: black;
font-size: 20px;
opacity: 0.8;
margin-right: 7px
}

.addStoryInnerCon{
width: 50%;
margin: 0 auto;
box-shadow: 6px 12PX 12px 12px #00000029;
padding-top: 30px;
border-radius: 5px;
padding-bottom: 30px;
padding-left: 50px;
padding-right: 50px;
}

.addStoryTitleCon{
background: #035aa6;
margin-bottom: 20px
}

.addStoryTitleCon h4{
text-align: center;
color: white;
padding: 5px 0;
}

.addBlogBtn{
width: 50%;
margin: 20px 25%;
background-color: #035aa6;
color: white;
font-weight: bold;
}
.addBlogBtn:hover{
  background-color: white;
  border: #035aa6 1px solid;
  color:#035aa6
}

.addBlogPlus{
padding: 5px;
margin: 10px 0;
}
  
.addBlogPlus .iconss{
color: #035aa6;
opacity: .7;
font-size: 30px;
}

.addStoryMinus{
padding: 5px;
margin: 10px 10px 10px 10px;
}

.addStoryMinus .iconsss{
color: red;
opacity: 0.7;
font-size: 30px;
cursor: pointer;
}

.addFileImage{
width: 150px;
height: 100px
}

.blogDetBackBtnOuterCon{
position: absolute;
font-weight: bold;
width: 150px;
height: 50px;
}

.blogDetBackBtnOuterCon a{
width: 100%;
height: 100%;
font-weight: bold;
background-color: #035aa6;
color: white;
display: flex;
justify-content: center;
align-items: center;
}

.blogDetBackBtnOuterCon a:hover{
  background-color: white;
  border: 1px solid #035aa6;
  color:#035aa6
}

.blogDetBackBtnOuterCon a .icon{
font-size: 30px; 
margin-right: 7px
}

.editParaModalOuterCon{
width: 50%;
padding: 20px  15px;
background: #035aa6;
position: absolute;
opacity: 1;
color: white;
right: 5px;
border-radius: 10px;
z-index: 5;
}
.editParaModalInnerCon{
position: absolute;
right: 10px;
top: 10px;
z-index: 7;
}
.editParaModalInnerCon .icon{
font-size: 35px;
cursor: pointer 
}

.editModalPlsCopy{
 text-align: center;
 margin-bottom: 40px 

}

@media (max-width: 991px) {
  .blogAddStoryOuterCon{
    width: 100%;
    padding: 100px 1% 50px 1%;
    font-family: 'Montserrat', sans-serif
      }

      .addStoryInnerCon{
        width: 100%;
        margin-top: 30px;
        padding-left: 5px;
        padding-right: 5px;
        }

        
.editParaModalOuterCon{
  width: 99%;
  padding: 20px  1%;

  }


  .editParaModalInnerCon{
    position: absolute;
    right: 1px;
    top: 10px;
    z-index: 7;
    }

    .blogAddStoryOpenBtn{
      position: absolute;
      right: 1px;
      top: 50px
      }

      .editParaModalInnerCon .icon{
        font-size: 20px;
        cursor: pointer 
        }
        .editModalPlsCopy{
          text-align: center;
          margin-bottom: 40px;
          font-size: 12px;
         }
         .editParaModalOuterCon p{
          font-size: 14px;
          }
        
}

