.aboutUsOuterCon {
  width: 100%;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
}

.aboutOwnerImageCon {
  flex: 1;
  overflow: hidden;
  background-color: lightgray;
}

.CEOImageContentCon {
  flex: 1;
  border-left: 5px solid #035aa6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.aboutShortVideoCon {
  margin-left: 20%;
  width: 1200px;
  margin-top: 50px;
  height: 700px;
}

.react-player {
  width: 1000px;
  height: 700px;
}

.aboutSubHeadCon {
  width: "100%";
  margin-left: 20%;
  margin-top: 80px;
  margin-bottom: 30px;
}

.aboutTopHeadImageCon {
  height: 600px;
  width: 100%;
}

.abouttopHeadImageinnerCon {
  width: 100%;
  height: 600px;
  z-index: 1;
  position: absolute;
  background: black;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 30px;
  padding: 0 20%;
  text-align: center;
}

.aboutTopHeadImageContent,
.aboutOwnerH2ImageCon {
  padding: 10px;
  border: 5px solid white;
  border-radius: 10px;
}
.aboutTopHeadImageContent h1 {
  color: white;
  opacity: 1;
}

.aboutUsVideoTitleCon {
  width: 100%;
  height: 200px;
  padding: 30px 20% 0 20.5%;
}

.aboutUsVideoTitleCon h2 {
  line-height: 1.4;
}

.aboutWhoUsesCon {
  width: 100%;
  padding: 0 10%;
  display: flex;
}

.aboutWhereParaCon {
  width: 100%;
  padding: 0 20%;
  margin-top: 40px;
  margin-bottom: 10px;
}

.aboutWhereParaCon1 {
  width: 100%;
  padding: 0 20%;
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutUsMapCon {
  width: 100%;
  padding: 0 20%;
  margin-top: 30px;
  margin-bottom: 100px;
  height: 350px;
}

.aboutUsTopHeadImgOwnerCon {
  height: 600px;
  width: 100%;
}
.aboutUsTopHeadImgInnerOwnerCon {
  width: 100%;
  height: 600px;
  z-index: 1;
  position: absolute;
  background: #035aa6;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 30px;
  padding: 0 20%;
  text-align: center;
}
.aboutOwnerH2ImageCon {
  margin: 0 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutOwnerH2ImageCon h1 {
  color: white;
  opacity: white;
  font-size: 40px;
}
.aboutCeoOutcon {
  width: 60%;
  margin: 80px 20% 20px 20%;
  height: 400px;
  border: 10px solid #035aa6;
  display: flex;
  flex-direction: row;
}
.ceoIcon {
  color: #035aa6;
  font-size: 70px;
  margin-bottom: 20px;
}

.aboutUsOwnerShipContent {
  width: 60%;
  margin: 50px 20%;
}
.aboutUsOwnerShipContent .firstSpan {
  margin-left: 80px;
  color: #035aa6;
  font-style: italic;
  font-weight: bold;
}

.crewUnderLine {
  height: 2px;
  background: #ffa500;
  margin-left: 20px;
  width: 45px;
  margin-top: 10px;
}

.aboutStatement {
  width: 60%;
  margin: 20px 20%;
  padding: 30px 0;
  font-size: 20px;
}

.aboutStatement a {
  text-decoration: none;
  cursor: pointer;
  color: #035aa6;
  margin-left: 80px;
}

.aboutActiveUnderLine {
  height: 2px;
  background: #ffa500;
  width: 190px;
  margin-top: 10px;
}

.impactActiveUnderLine {
  height: 2px;
  background: #ffa500;
  margin-left: 20px;
  width: 100px;
  margin-top: 10px;
}

.crewImageOuterCon {
  width: 100%;
  margin-left: 0%;
  margin-top: 0;
  margin-bottom: 0;
}

.crewCardCon {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.opCrewCardCon {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.crewCardCon .crewImageCon {
  width: 50%;
  height: 500px;
  background-color: lightgray;
}
.crewImagesContent {
  width: 50%;
  height: 500px;
  padding: 10px;
  /* background-color: #035aa6; */
  color: white;
  /* display: flex;
flex-direction: column;
justify-content: center;
align-items: center; */
}
.opCrewCardCon .crewImageCon {
  width: 50%;
  height: 500px;
}
.crewImagesContent {
  width: 50%;
  height: 500px;
  background-color: #035aa6;
  color: white;
  /* padding-top: 50px; */
  padding-left: 20px;
}

.crewImagesContent .crewIcon {
  color: white;
  font-size: 50px;
  margin-bottom: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.crewImagesContent .names {
  font-size: 20px;
  text-align: center;
}
.crewImagesContent .nameContainer {
  text-align: center;
}

.crewDDepartmentOuterCon {
  width: 100%;
  margin-left: 0%;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.crewDepInnerCon {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.opCrewDepInnerCon {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.crewDepContent {
  width: 50%;
  height: 500px;
  padding: 10px;
  background-color: black;
  opacity: 0.9;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.crewDepContent .title {
  font-size: 20px;
  color: #ffa500;
  opacity: 1;
  text-align: center;
}

.crewDepStatement {
  padding: 0 50px;
}

.crewDepImageCon {
  width: 50%;
  height: 500px;
  background-color: lightgray;
}

.crewDepHouseIcon {
  color: white;
  opacity: 1;
  font-size: 30px;
  margin-bottom: 20px;
}
.allLinkContainer {
  width: 100%;
  height: 60px;
  padding: 30px 20%;
  display: flex;
}

.allLinkContainer .a {
  color: black;
  text-decoration: none;
}
.allLinkContainer .a1 {
  color: #ffa500;
  text-decoration: none;
}

.allLinkContainer .a:hover,
.allLinkContainer .c:hover,
.allLinkContainer .b:hover {
  color: #ffa500;
}
.allLinkContainer .b {
  color: black;
  margin-left: 20px;
  text-decoration: none;
}

.allLinkContainer .b1 {
  color: #ffa500;
  margin-left: 20px;
  text-decoration: none;
}

.allLinkContainer .c {
  color: black;
  margin-left: 20px;
  text-decoration: none;
}
.allLinkContainer .c1 {
  color: #ffa500;
  margin-left: 20px;
  text-decoration: none;
}

.iconMessageCardCon {
  width: 100%;
  padding-right: 50px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.iconMessageCardCon .innerCon {
  flex: 1;
}

.iconMessageCardCon .iconContainer {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconMessageCardCon .content {
  flex: 5;
  padding-left: 10px;
}

.iconMessageCardCon .content h4 {
  font-weight: bolder;
  color: #035aa6;
}
.iconMessageCardCon .content p {
  font-size: 14px;
}

@media (max-width: 991px) {
  .aboutWhereParaCon1 {
    width: 100%;
    padding: 0 2%;
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .abouttopHeadImageinnerCon,
  .aboutTopHeadImageCon {
    height: 70vh;
    width: 100%;
    /* padding: 0 2%; */
  }

  .aboutTopHeadImageContent,
  .aboutOwnerH2ImageCon {
    padding: 5%;
  }
  .aboutOwnerH2ImageCon {
    margin: 0 1%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .aboutUsTopHeadImgInnerOwnerCon {
    padding: 0 2%;
  }
  .aboutTopHeadImageContent h1,
  .aboutOwnerH2ImageCon h1 {
    font-size: 25px;
  }

  .allLinkContainer {
    height: 60px;
    padding: 30px 1%;
    display: flex;
  }

  .allLinkContainer .a,
  .allLinkContainer .a1,
  .allLinkContainer .b,
  .allLinkContainer .b1,
  .allLinkContainer .c,
  .allLinkContainer .c1 {
    font-size: 12px;
  }
  .aboutActiveUnderLine {
    width: 130px;
  }

  .impactActiveUnderLine {
    width: 70px;
  }
  .crewUnderLine {
    width: 25px;
  }

  .aboutShortVideoCon {
    margin-left: 1%;
    width: 98%;
    margin-top: 50px;
    height: 400px;
  }

  .aboutUsVideoTitleCon h2 {
    line-height: 1.4;
    font-size: 18px;
  }
  .aboutUsVideoTitleCon {
    width: 100%;
    height: 200px;
    padding: 30px 1% 0 1%;
  }
  .aboutSubHeadCon {
    margin-left: 1%;
    margin-top: 10px;
  }

  .aboutWhoUsesCon {
    width: 100%;
    padding: 0 1%;
    display: flex;
    flex-direction: column;
  }

  .aboutUsMapCon {
    width: 100%;
    padding: 0 1%;
    margin-top: 30px;
    margin-bottom: 100px;
    height: auto;
  }

  .aboutCeoOutcon {
    width: 98%;
    margin: 40px 1% 20px 1%;
    height: 600px;
    border: 10px solid #035aa6;
    display: flex;
    flex-direction: column;
  }

  .aboutUsOwnerShipContent {
    width: 98%;
    margin: 50px 1%;
  }
  .aboutUsOwnerShipContent .firstSpan {
    margin-left: 40px;
    color: #035aa6;
    font-style: italic;
    font-weight: bold;
  }

  .aboutStatement {
    width: 99%;
    margin: 20px 1%;
    padding: 30px 0;
    font-size: 16px;
  }

  .crewImageOuterCon h1 {
    font-size: 22px;
  }

  .crewCardCon .crewImageCon {
    width: 100%;
    height: 500px;
  }
  .opCrewCardCon .crewImageCon,
  .crewDepImageCon {
    width: 100%;
    height: 500px;
  }

  .crewCardCon,
  .opCrewDepInnerCon {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }
  .opCrewCardCon,
  .crewDepInnerCon {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin-top: 10px;
  }

  .crewImagesContent,
  .crewDepInnerCon .crewDepContent,
  .opCrewDepInnerCon .crewDepContent {
    width: 100%;
    height: auto;
    padding: 5px;
  }
}
