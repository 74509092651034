.newsDetBodyCon{
padding-left: 10px;
padding-top: 30PX;
padding-bottom: 50px;
width: 75%;
padding-right: 10px;
background: #035aa6;
color: white;
border-radius: 10px;
margin-left: 15px;
}

.newsDetPInnerCon{
font-size: 22px;
margin-bottom: 0
}

.newsDetTitleB{
font-size: 25px;
font-family: 'DM Serif Display', serif
}

.newsDetDate{
font-size: 14px;
margin-top: 15px 
}

.newsDetImageBtn{
background-color: #035aa6;
border: solid 1px white;
color: white;
padding: 10px 30px;
display: flex;
}

.newsDetImageBtn .newsIcon{
font-size: 16px;
margin-left: 7px;
}

.newsDetImageCon{
width: 100%;
height: 300px;
border: 5px solid #035aa6;
border-radius: 10px;
}

.newsDetSubtitle{
margin: 20px 0;
color: white;
text-align: center;
font-size: 15px;
}
.newsDetPara{
margin: 0 0;
font-size: 18px;
line-height: 1.8;
text-indent: 70px;
white-space: pre-wrap;
}

@media (max-width: 991px) {
   .newsDetBodyCon{
                padding-left: 10px;
                padding-top: 30PX;
                padding-bottom: 50px;
                width: 99%;
                padding-right: 10px;
                background: #035aa6;
                color: white;
                border-radius: 10px;
                margin-left: 1%;
                margin-top: 20px;
                }   
                
                .newsDetPara{
                                margin: 0 0;
                                font-size: 15px;
                                line-height: 1.8;
                                text-indent: 70px;
                                white-space: pre-wrap;
                                }
          .mobileAddNewsCon{
          flex-direction:column;
          width: 98%;
          margin: 10px 1%;
          }
}

