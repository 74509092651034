.fag-about-icon{
color: #035aa6;
font-size: 35px;
opacity: 0.8;
cursor: pointer;    
transform: rotate(0deg);   
transition: transform 0.6s;                       
}

.fag-about-icon1{
color: #035aa6;
font-size: 35px;
opacity: 0.8;
cursor: pointer;  
transform: rotate(180deg);  
transition: transform 0.6s;              
}
.showDetails{
display: none;
transition: all o.6s;
}
.showDetails1{
display: block;
transition: all 0.6;
cursor: pointer;
}

.openWhere10{
 width: 100%;
 display: flex;
 align-items: center             
}
.openWhere1{
width: 100%;
display: flex;
align-items: center             
}

.openAnswer{             
background: #f5f7f7;
width: 100%;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
margin-bottom: 5px;
display: none;
}
.openAnswer1{             
background: #f5f7f7;
width: 100%;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
margin-bottom: 5px;
display: block;
}


.faqHeadH1{
 animation: fadeInUp 0.5s ease-in-out;       
  position: absolute;
  top: 20%;
  left: 10%;
  font-size: 70px;
  font-family: 'DM Serif Display', serif;
  color: #035aa6;
  letter-spacing: 15px;
  text-decoration: underline;    
}
.faqContentCon{
    margin-top: 45px;
    width: 100%;
    padding: 20px 12%;
    font-family: 'Montserrat', sans-serif;
}
.faqHeadIconCon{
    position: absolute;
    width: 100%;
    height: 40vh;
}

@keyframes fadeInUp {
0% {
  opacity: 0;
  transform: translateY(140px);
}
                                 
100% {
  opacity: 1;
  transform: translateY(0);
}
}

.faqHeadingOuterCon{
    width: 100%;
    height: 40vh
}

.faqDropOuterCon{
  box-shadow: 2px 2px 2px 2px #969696;
  border-radius: 5px;
  padding: 0 50px;
  margin-top: 10px;
}

.faqdrop1OuterCon{
  width: 100%;
  height: 100px;
  display: flex;
}

.faqdrop1InnerCon{
    flex: 1;
    cursor: pointer;
    color: #035aa6;
    height: 100%;
    display: flex;
    align-items: center;
}

.faqdrop1IconCon{
display: flex;
align-items: center;
padding: 0 20px
}

.faqDrop2OuterCon{
    font-size: 20px;
    color: #035aa6;
}

.faqDrop2Question{
    flex: 1;
    padding: 20px 30px;
    height: 100%;
    background-color: #f5f7f7;
}

.faqDrop2IconCon{
    display: flex;
    align-items: center;
    padding: 20px 30px;
    background-color: #f5f7f7;
    justify-content: center;
}
.faqDrop2Separator{
 width: 100%;
 height: 1px;
 background: black;
}
.faqDrop2Answer{
    width: 100%;
    padding: 10px 50px;
    color: black;
    font-size: 16px;
}


@media (max-width: 991px) {
  .faqDropOuterCon{
    box-shadow: 2px 2px 2px 2px #969696;
    border-radius: 5px;
    padding: 0 5px;
    margin-top: 10px;
  }

 .faqdrop1InnerCon h4{
font-size: 16px;
}
.faqContentCon{
  margin-top: 45px;
  width: 100%;
  padding: 20px 5%;
  font-family: 'Montserrat', sans-serif;
}

.faqDrop2Question{
  flex: 1;
  padding: 10px 20px;
  height: 100%;
  background-color: #f5f7f7;
  font-size: 14px;
}

.fag-about-icon{
  font-size: 25px;               
  }
  
  .fag-about-icon1{
  font-size: 25px;            
  }

  .faqDrop2Answer{
    width: 100%;
    padding: 10px 20px;
    color: black;
    font-size: 14px;
}

}

                             