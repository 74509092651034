#exampleModalChoosePaymentMethod {
  margin-top: 150px;
}

.listingSearchCon3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
}

.listingSearchCon3 button {
  border-radius: 5px;
  background: #035aa6 0% 0% no-repeat padding-box;
  padding: 5px 7px;
  color: #ffffff;
  letter-spacing: 1.6px;
  border: #035aa6 1px solid;
  cursor: pointer;
}

.paymentMethodModalListItem {
  margin: 5px auto;
  width: 80%;
}

.paymentMethodModalListItem li {
  background: #f6f6f6;
  margin: 1px 0;
  cursor: pointer;
  color: #035aa6;
}
.paymentMethodModalListItem .mtnListItem img {
  width: 40px;
  height: 20px;
  margin-right: 5px;
  object-fit: cover;
}

.paymentMethodModalListItem li .theIconsMethods {
  margin-left: 7px;
}

.paymentMethodModalListItem li .selectedPaymentIcon {
  margin-left: 10px;
  color: #ffa500;
}
.paymentMethodModalListItem .paypalListItem img {
  width: 40px;
  height: 20px;
  border-radius: 30px;
  margin-right: 5px;
}

.visaCardPaymentIcon {
  font-size: 20px;
  margin-right: 7px;
  color: #035aa6;
}

#exampleModalChoosePaymentMethod .modal-body {
  display: flex;
  flex-direction: row;
}

#exampleModalChoosePaymentMethod .modal-body select {
  width: 40%;
  cursor: pointer;
  border-top-left-radius: 10px;
  margin-right: 10px;
  border-bottom-left-radius: 10px;
  font: normal normal bold 14px/25px Merriweather;
}

#exampleModalChoosePaymentMethod .modal-body input {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font: normal normal bold 12px/25px Merriweather;
}

#exampleModalChoosePaymentMethod .SaveChoosenPaymentBtn {
  background: #035aa6;
  width: 90%;
  color: white;
  margin: 5px 5%;
  border: #035aa6 solid 1px;
  font: normal normal bold 16px/25px Merriweather;
}
#exampleModalChoosePaymentMethod .SaveChoosenPaymentBtn:hover {
  background: white;
  color: #035aa6;
}

.alertBankPaymentUnderConstruction {
  width: 96%;
  margin: 5px auto;
  text-align: center;
}

.modal-body .bankCountry {
  margin-top: 10px;
}

.modal-body .continueWithOtherQuestions {
  background: grey;
  margin-left: 18px;
  margin-bottom: 5px;
}

.accountNumberInput {
  margin-top: 10px;
}
.savePaymentBankAccountBtn {
  background: #035aa6;
  width: 90%;
  color: white;
  margin: 5px 5%;
}

.underConstructionPaypalPayment {
  width: 96%;
  margin: 5px auto;
  text-align: center;
}

.savePaypalPaymentBtn {
  background: #035aa6;
  width: 90%;
  color: white;
  margin: 5px 5%;
}

.loadingErrorPaymentMenthod {
  color: red;
  font-size: 12px;
  width: 100%;
  text-align: center;
}

.giveSomeWidthToAmtPaid {
  width: 300px;
}

.letMeSpinnerToUpatePay {
  height: 20px;
  height: 20px;
  color: #035aa6;
}

.searchPaymentLeaseMakePayBtn .updatePaymentMethodError {
  color: red;
  font-size: 10px;
}

.searchPaymentLeaseMakePayBtn .choseAPaymentmethod {
  /* box-shadow: 2px 5px 7px black; */
}

.separationInPaymentTable {
  position: absolute;
}

.paymentsOuterContainer {
  width: 80%;
  margin-left: 15%;
}
.paymentsOuterContainer1 {
  width: 95%;
  margin-left: 5%;
}
/* style={{width:"80%", marginLeft:"15%"}} */
@media (max-width: 991px) {
  .listingSearchCon3 {
    width: 100%;
    display: flex;
    flex-direction:column;
    height: 50px;
  }
  .listingSearchCon .paymentInputFieldCon {
    margin-left: 20px;
    margin-bottom: 15px;
    width: 90%;
  }

  .listingSearchCon3 .paymentInputFieldCon {
    margin-left: 20px;
    margin-bottom: 7px;
    width: 90%;
  }

  .paymentInput{
    width: 100%;
  }

  .searchPaymentLeaseMakePayBtn .choseAPaymentmethod {
    text-decoration: none;
    padding: 8px 10px;
    color: #ffa500;
    font-size: 10px;
    width: 350px;
    /* box-shadow: 2px 5px 7px black; */
  }

  .separationInPaymentTable {
    margin-top: 200px;
    position: relative;
  }

  .paymentTableOuterContainer {
    width: 100%;
    height: 700px;
  }

  .setPositionToAbsolutePaymentTable {
    position: absolute;
  }
  .giveThePaymenttableFixHeight {
    height: 700px;
    width: 100%;
  }

  #exampleModalChoosePaymentMethod .modal-content {
    width: 350px;
  }
  .paymentsOuterContainer {
    margin-left: 0;
    width: 100%;
  }
  .paymentsOuterContainer {
    width: 100%;
    margin-left: 0%;
  }
}
