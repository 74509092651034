.tenantSignLeaseModals {
  width: 76%;
  padding: 20px;
  background: white;
  margin: 0 13%;
  border: 2px solid #035aa6;
  opacity: 1;
}

.logo201 {
  height: 100px;
  width: 80px;
}

.addPaymentModalMomoSelect {
  width: 35%;
}

.agreementModal1OuterCon {
  position: absolute;
  width: 100%;
  padding: 50px;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 5;
}

.confirmActivePaymentInnerCon {
  width: 40%;
  border: 1px solid #035aa6;
  background: white;
  z-index: 1;
  border-radius: 5px;
  padding: 5px;
}

.kfirmActiveCon {
  font-size: 50px;
  color: white;
  position: absolute;
  top: 120px;
  cursor: pointer;
}
.kfirmActiveCon:hover {
  color: #035aa6;
}

.kfirmNoBtn {
  background: #ffa500;
  color: white;
}
.kfirmNoBtn:hover {
  border: 1px solid #ffa500;
  background: white;
  color: #ffa500;
}

.kfirmYesBtn {
  background: #035aa6;
  color: white;
}
.kfirmYesBtn:hover {
  border: #035aa6 solid 1px;
  background: white;
  color: #035aa6;
}

.confirmActivePayOuterCon {
  width: 100%;
  height: 100%;
  background: black;
  position: fixed;
  opacity: 0.9;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeInUpConfirm 0.6s ease-in-out;
}
@keyframes fadeInUpConfirm {
  0% {
    opacity: 0;
    transform: translateY(100vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.clearBtnStyling {
  background: #035aa6;
  position: absolute;
  right: 21%;
  color: white;
  margin-top: 10px;
  box-shadow: 2px 5px 7px black;
  font: normal normal bold 14px/25px Merriweather;
}
.clearBtnStyling:hover {
  background: white;
  border: 1px solid #035aa6;
  color: #035aa6;
}
.leaseOwedContainer {
  position: absolute;
  width: 50%;
  padding: 10px;
  background: #035aa6;
  margin: 50px 30%;
  z-index: 1;
  animation: fadeInUp 0.6s ease-in-out;
}

.leaseOwedContainer .icon {
  position: absolute;
  right: 10px;
  top: 5px;
  color: white;
  cursor: pointer;
}

.leaseOwedContainer .icon:hover {
  color: #ffa500;
}

.leaseOweTableCon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: white;
  margin: 0.5px 0;
}

.leaseOweTableCon:hover {
  background: #ffa500;
}

.payDebtBtn {
  background: #ffa500;
  border: white 1px solid;
  opacity: 1;
}

.payDebtBtn:hover {
  background: #035aa6;
  color: white;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(140px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
#exampleModalAddPaymentOption {
  margin-top: 80px;
}
.myListTd .activeEditSignLease:hover {
  color: #035aa6;
}
.removeTablePadding {
  padding: 0;
}
.leaseSignedAgreementIcon {
  font-size: 12px;
}

.myListTd .goToLeaseSignDetails {
  text-decoration: none;
}
.myListTd .goToLeaseSignDetails1 {
  text-decoration: none;
  animation: blink 1s infinite;
  color: #ff6d60;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.myListTd .goToLeaseSignDetails:hover {
  color: #035aa6;
}
.activeEditSignLease {
  margin-right: 7px;
  cursor: pointer;
}
.addedPaymentOptionVisacardIcon {
  font-size: 20px;
  margin-bottom: 5px;
  margin-right: 5px;
  color: #035aa6;
}
.addedPaymentOptionsActive {
  font: normal normal normal 14px/25px Merriweather;
  background: #ffa500;
  color: white;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  padding: 2px;
  align-items: center;
  justify-content: center;
  border: #ffa500 solid 1px;
  cursor: pointer;
}
.addedPaymentOptionsActive:hover {
  background: white;
  color: #ffa500;
}
.addedPaymentOptionsActive1 {
  font: normal normal normal 14px/25px Merriweather;
  background: white;
  color: #ffa500;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  padding: 2px;
  align-items: center;
  justify-content: center;
  border: #ffa500 solid 1px;
  cursor: pointer;
}
.addedPaymentOptionsActive1:hover {
  background: #ffa500;
  color: white;
}
.addedPaymentOptionMtnImage {
  width: 40px;
  height: 20px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.listingSortSelectSpan select {
  width: 80px;
}

/* WelcomeUser component */

.addListWelcomeConLease {
  width: 80%;
  margin: 80px 1% 50px 1%;
}

.addListWelcomeConLease h6 {
  color: #222222;
  letter-spacing: 0.9px;
  text-align: left;
  font: normal normal bold 18px/25px Merriweather;
}
.cancelAddedPaymentoptionIcon {
  cursor: pointer;
}
.deletedAddedPaymentOptionSpan {
  padding: 3px;
  background: white;
  border-radius: 5px;
  border: 1px solid #ffa500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}
.deletedAddedPaymentOptionSpan:hover {
  background: red;
  color: white;
  opacity: 0.8;
}
.addedPaymentOptionPaypalImage {
  width: 30px;
  height: 20px;
  border-radius: 30px;
  margin-right: 5px;
}
.addedPaymentOptionsNonActive {
  background: white;
  color: #035aa6;
  margin-top: 5px;
}
.ifPaymentOptionIsZero {
  margin-top: 20px;
  text-align: center;
  font: normal normal normal 14px/25px Merriweather;
}

.addedPaymentOptions {
  width: 100%;
  height: 350px;
}

.addedPaymentOptions .list-group {
  margin-top: 10px;
}

.paymentOptionBtn {
  margin-top: 10px;
  background: #035aa6;
  border: #035aa6 solid 1px;
  color: white;
  z-index: 10;
  cursor: pointer;
  width: 100%;
  font: normal normal bold 14px/24px Merriweather;
  box-shadow: 3px 1px 3px 0.5px black;
}

.paymentOptionBtn:hover {
  background-color: white;
  color: #035aa6;
}
.cancelChangesBtn {
  background: white;
  color: #035aa6;
  width: 80%;
  margin: 5px auto;
  font: normal normal normal 15px/24px Merriweather;
}
.editLeaseSignBtnContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.editLeaseSignBtnContainer .confirmChange {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.editLeaseSignBtnContainer .cancelChanges {
  flex: 1;
  display: flex;
  justify-content: center;
}
.confirmChange .confirmBtn {
  background: #ffa500;
  color: white;
  width: 80%;
  margin: 5px auto;
  font: normal normal normal 15px/24px Merriweather;
}

.editLeaseSignInnerCon {
  margin-top: 200px;
  background: #035aa6;
}
.editLeaseSignCancelBtn {
  color: white;
}
.modalInnerbodyContainerLeaseSign {
  color: white;
  text-align: center;
}
.modalInnerbodyContainerLeaseSign p {
  font: normal normal normal 14px/24px Merriweather;
}
.editModalHeading {
  color: white;
  font: normal normal bold 17px/24px Merriweather;
}

.loadingAgreementLeaseModal {
  width: 100%;
  text-align: center;
}
.agreementLeaseSignFaGravel {
  font-size: 70px;
  /* transform: rotate(-90deg); */
  animation: rotateGravel 2s ease-in-out;
}

@keyframes rotateGravel {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-90deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-90deg);
  }
}
.faGravelContainerAgreementLeaseModal {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.handshakeAgreementLeaseModal {
  font-size: 20px;
  margin-right: 5px;
}

.inlineAgreementLeaseModal {
  display: inline;
}

.agreementLeaseModalCheckboxMargin {
  margin-left: 50px;
}

.TheAndInAgreementLeaseModal {
  margin-left: 5px;
  font-size: 20px;
  font-family: "Lobster", cursive;
}
.leftMarginAndUnderlineInAgreementLeaseModal {
  text-decoration: underline;
  margin: 0 5px;
}

.cancelAgreementLeaseModal {
  height: 55px;
  width: 55px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.underReviewListItem {
  height: 60px;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font: normal normal normal 13px/24px Merriweather;
  width: 100%;
  color: black;
}
.underReviewListItem:hover {
  background: #035aa6;
  color: white;
}

/* .underReviewListItem :hover{
  background-color: #035aa6;
  color: white;
} */

/* .underReviewListItemInline {
  display: inline;
} */

.signLeasedNAME {
  margin-left: 2px;
  flex: 1;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.addPaymentOptionSpinner {
  width: 30px;
  height: 30px;
}
.paypalPaymentOptionError {
  color: red;
  font-size: 10px;
  text-align: center;
}
.paypalAddOptionBtn {
  background: #035aa6;
  border: #035aa6 solid 1px;
  width: 90%;
  color: white;
  margin: 5px 5%;
}
.paypalAddOptionBtn:hover {
  background: white;
  width: 90%;
  color: #035aa6;
  margin: 5px 5%;
}

.paypalIsStillUnderConstruction {
  width: 96%;
  margin: 5px auto;
  text-align: center;
}

.addVisaCardOptionBtn {
  background: #035aa6;
  width: 90%;
  color: white;
  margin: 5px 5%;
}

.dateCardPaymentOption {
  width: 90px;
  margin: 0px 10px;
  border: 1px solid white;
  font-size: 14px;
}
.loadingDownloadSignedLease {
  margin-left: 10px;
}
.cvcCardPaymentOption {
  width: 80px;
  border: 1px solid white;
  font-size: 14px;
}
.cardNumberAddOption {
  border: 1px solid white;
  font-size: 14px;
}
.visaCardPaypalContainer {
  display: flex;
  flex-direction: row;
  border: 1px solid #eeeeee;
  border-radius: 5px;
}

.paymentOptionVisaCardImageIcon {
  font-size: 40px;
  margin-right: 7px;
  margin-top: 2px;
  color: #035aa6;
}

.visaCardIsStillUnderconstruction {
  width: 96%;
  margin: 5px auto;
  text-align: center;
}

.addMomoPaymentOptionError {
  color: red;
  text-align: center;
  font: normal normal normal 10px/25px Merriweather;
}

.addMomoPaymentOption {
  background: #035aa6;
  width: 90%;
  border: #035aa6 1px solid;
  color: white;
  margin: 5px 5%;
  font: normal normal bold 16px/24px Merriweather;
}

.addMomoPaymentOption:hover {
  background: white;
  width: 90%;
  color: #035aa6;
  margin: 5px 5%;
}

.myCheckedIconInAddOption {
  margin-left: 10px;
  color: #ffa500;
}

.modalBodyAddPaymentOption .momoCountryCode {
  width: 28%;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: center;
  font: normal normal normal 15px/24px Merriweather;
}

.momoCountryCode option {
  text-align: left;
}

.modalBodyAddPaymentOption input {
  font: normal normal normal 15px/24px Merriweather;
}

.modalBodyAddPaymentOption {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
}

.modalBodyAddPaymentOption .momoInput {
  margin-left: 2%;
  width: 70%;
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  height: 50px;
}

.visaCardImageIcon {
  font-size: 20px;
  margin-right: 7px;
  color: #035aa6;
}

.paypalImage {
  width: 40px;
  height: 20px;
  border-radius: 30px;
  margin-right: 5px;
}

.addPaymentMethodListItem {
  margin: 5px auto;
  width: 80%;
  font: normal normal normal 16px/24px Merriweather;
}

.otherCheckboxPaymentOption {
  margin-left: 10px;
}

.addPaymentMethodListItem li {
  background: #f6f6f6;
  margin: 1px 0;
  cursor: pointer;
  color: #035aa6;
}

.addPaymentMethodListItem .mtnImage {
  width: 40px;
  height: 20px;
  margin-right: 5px;
}

.noSignedLeaseAvailableContainer {
  width: 100%;
  margin: 50px auto;
  font-size: 12px;
}

.theContainerThatRapTheListItem {
  width: 98%;
  height: 250px;
  margin: 0 auto;
}
.noSignedLeaseAvailableContainer .alert {
  text-align: center;
  font: normal normal bold 12px/19px Merriweather;
  background-color: whitesmoke;
}
.leasingListAndTableOuterContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.leaseTableTitleContainer h6 {
  color: #222222;
  letter-spacing: 0.9px;
  font: normal normal bold 18px/25px Merriweather;
}

.leaseTableTitleContainer {
  width: 100%;
  text-align: center;
}

.myListDeleteBtnLease {
  text-align: center;
  display: flex;
  flex-direction: row;
}

.myListDeleteBtnLease .deleteBtn {
  border-radius: 5px;
  border: 1px solid #ff4141;
  background: #ff4141 0% 0% no-repeat padding-box;
  color: #ffffff;
  letter-spacing: 0.68px;
  padding: 7px 15px;
  font: normal normal bold 12px/16px Merriweather;
  cursor: pointer;
}

.myListDeleteBtnLease .deleteBtn:hover {
  background: white;
  border: 1px solid #ff4141;
  color: #ff4141;
}
.myListDeleteBtnLease .downloadingAgreementInLeaseSignedTable {
  border-radius: 5px;
  border: 1px solid #035aa6;
  background: #035aa6 0% 0% no-repeat padding-box;
  color: #ffffff;
  letter-spacing: 0.68px;
  padding: 7px 15px;
  font: normal normal bold 12px/16px Merriweather;
  margin-left: 5px;
  cursor: pointer;
}

.myListDeleteBtnLease .downloadingAgreementInLeaseSignedTable:hover {
  background: white;
  border: 1px solid #035aa6;
  color: #035aa6;
}

.paymentOptionsOuterCon {
  width: 94%;
  margin-left: 2%;
  height: 450px;
}

.paymentOptionsOuterCon hr {
  width: 100%;
  background: #bcbcbc;
  height: 0.05px;
}
.LeasingListContainer {
  width: 20%;
  border-right: 1px solid #bcbcbc;
  height: 700px;
  margin-top: 75px;
  padding: 20px 10px 20px 10px;
}
.LeasingListContainer1 {
  width: 25%;
  border-right: 1px solid #bcbcbc;
  height: 700px;
  margin-top: 75px;
  padding: 20px 10px 20px 10px;
}

.leasingTableContainerForLeasing {
  margin-top: 48px;
  width: 65%;
  height: 600px;
  border-right: 1px solid #bcbcbc;
  padding-right: 5px;
  margin-left: 15.1%;
}

.leasingTableContainerForLeasing1 {
  margin-top: 48px;
  width: 75%;
  height: 600px;
  border-right: 1px solid #bcbcbc;
  margin-left: 5.41%;
  padding-right: 2px;
}

.noLeasesContainer {
  width: 50%;
  margin: 10px auto;
}

.noLeasesContainer .alert {
  text-align: center;
  font: normal normal bold 16px/19px Merriweather;
  background-color: whitesmoke;
}

.leasesTableCon {
  width: 100%;
  margin: 10px auto;
  max-height: 600px;
}
.leasesTableCon1 {
  width: 100%;
  margin: 10px auto;
  max-height: 600px;
}

.leasesTableCon2 {
  width: 100%;
  margin: 10px auto;
  max-height: 600px;
}

.agreementSignOuterContainer {
  width: 1000px;
  /* max-height: 1300px; */
  margin-left: -250px;
}

.agreementSignOuterContainer .modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.agreementTitleHeader {
  font: normal normal bold 18px/19px Merriweather;
}

.agreementSignOuterContainer .modal-body {
  background-color: #f7f6fa;
}

.agreementInnerContainer {
  padding: 50px 120px;
  width: 98%;
  height: 98%;
  margin: auto auto;
  background-color: white;
}

.agreementInnerHeader {
  text-align: center;
  font-family: "Lobster", cursive;
  margin-bottom: 50px;
}

.agreementPartyContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementPartyContainer .inputCon1 {
  width: 150px;
}

.agreementPartyContainer .inputCon2 {
  width: 60px;
}

.agreementLandlordInfoCon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementLandlordInfoCon .inputCon1 {
  width: 250px;
}

.agreementLandlordInfoCon .inputCon5,
.agreementLandlordInfoCon .inputCon4,
.agreementLandlordInfoCon .inputCon3,
.agreementLandlordInfoCon .inputCon2 {
  width: 200px;
}

.agreementTenantInfoCon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementTenantInfoCon .inputCon1 {
  width: 300px;
}

.agreementTenantInfoCon .inputCon2 {
  width: 250px;
}

.agreementFixedLease {
  margin-left: 50px;
}

.agreementFixedLease .inputCon1 {
  width: 160px;
  margin-left: 5px;
}

.agreementFixedLease .inputCon4,
.agreementFixedLease .inputCon2 {
  width: 80px;
}
.agreementFixedLease .inputCon3 {
  width: 160px;
  margin-left: 5px;
}
.agreementFixedLease .inputCon6,
.agreementFixedLease .inputCon5 {
  margin-left: 50px;
}

.agreementMonthToMonth {
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementMonthToMonth .inputCon1 {
  width: 13px;
}
.agreementMonthToMonth .inputCon2 {
  width: 200px;
}
.agreementMonthToMonth .inputCon3 {
  width: 60px;
}
.agreementMonthToMonth .inputCon4 {
  width: 80px;
}

.agreementTheProperty {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementCountryStateCityCon {
  display: flex;
  margin-left: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementCountryStateCityCon .countryStateCon {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.agreementCountryStateCityCon .inputCon1 {
  width: 250px;
  margin-left: 7px;
}
.agreementCountryStateCityCon .stateCon {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.agreementCountryStateCityCon .inputCon2 {
  width: 250px;
  margin-left: 7px;
}

.agreementCityAddressCon {
  display: flex;
  margin-left: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementCityAddressCon .cityCon {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.agreementCityAddressCon .inputCon1 {
  width: 250px;
  margin-left: 7px;
}

.agreementCityAddressCon .addressCon {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.agreementCityAddressCon .inputCon2 {
  width: 250px;
  margin-left: 7px;
}

.agreementKitchen,
.agreementBathroom,
.agreementBedroom,
.agreementAppartNum,
.agreementResidentType {
  display: flex;
  margin-left: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementKitchen .inputCon1,
.agreementBathroom .inputCon1,
.agreementBedroom .inputCon1,
.agreementResidentType .inputCon1 {
  width: 250px;
  margin-left: 7px;
}

.agreementAppartNum .inputCon1 {
  width: 200px;
  margin-left: 7px;
}

.agreementSignLeaseBtn {
  width: 100%;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agreementSignLeaseBtn button {
  background-color: #035aa6;
  border-radius: 30px;
  width: 50%;
  cursor: pointer;
  padding: 5px;
  border: 0;
  color: white;
}

.agreementSignLeaseBtn button:hover {
  border: 1px solid #035aa6;
  background-color: white;
  color: #035aa6;
}

.agreementCheckbox {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.agreementInputField {
  border-top: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  border-left: 0;
  border-right: 0;
  text-align: center;
  border-bottom: 1px solid #bcbcbc;
}

.agreementListItemsCon {
  width: 100%;
  height: 250px;
}

.agreementListItemsCon .ListItemInnerCon {
  width: 90%;
  margin: 50px auto;
  border: #bcbcbc 1px solid;
  padding: 0 2px;
}
.ListItemInnerCon .mylistItemInnerContainerForLease {
  /* width: 95%; */
  /* padding: 7px; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */

  flex: 1;
  display: flex;
  flex-direction: row;
}

.myContainerForTrashIconList {
  padding: 10px;
  position: relative;
  /* border: red 1px solid; */
  border-radius: 5px;
  opacity: 0.8;
  color: red;
}
.myContainerForTrashIconList:hover {
  background: red;
  opacity: 0.8;
  color: white;
}
.mylistItemInnerContainerForLease .iconSigned {
  font-size: 20px;
  left: 5px;
  position: absolute;
}

.listItemTitle {
  width: 80%;
  color: #035aa6;
  margin: 0 auto;
  font: normal normal bold 16px/19px Merriweather;
  text-align: center;
  padding-top: 10px;
}
.listItemTitle h5 {
  font: normal normal bold 16px/19px Merriweather;
}

.ListItemInnerCon li {
  text-align: center;
  cursor: pointer;
}

.leasingTableContainerForLeasing .leaseNameContainer {
  width: 100%;
}

.leasingTableContainerForLeasing1 .leaseNameContainer {
  width: 100%;
}

.inputFieldCon .myListingSearchInput1 {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 10px;
  color: #bcbcbc;
  letter-spacing: 0.68px;
  font: normal normal bold 12px/16px Merriweather;
}
.listingSortDropdown1 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.listingSortDropdown1 .sortedBySpan {
  color: #222222;
  letter-spacing: 0.68px;
  font: normal normal bold 12px/16px Merriweather;
  margin-left: 5px;
}

.listingSortDropdown1 .listingSortSelectSpan {
  margin-left: 2px;
}

.listingSortDropdown1 select {
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #222222;
  padding: 2px 2px;
  letter-spacing: 0.68px;
  font: normal normal bold 12px/17px Merriweather;
}

@media (max-width: 991px) {
  .someLeaseMargin {
    margin-top: 10px;
  }
  .addPaymentModalMomoSelect {
    width: 55%;
  }
  .agreementModal1OuterCon {
    padding: 50px 5px 5px 5px;
  }
  .tenantSignLeaseModals {
    width: 99%;
    padding: 5px;
    background: white;
    margin: 0 0.5%;
    border: 2px solid #035aa6;
    opacity: 1;
  }
  .agreementFixedLease {
    margin-left: 10px;
  }
  .agreementSignOuterContainer {
    width: 100%;
    height: 1300px;
    margin-left: 0px;
  }
  .agreementInnerContainer {
    padding: 10px 2px;
    width: 99%;
    height: 99%;
    margin: auto auto;
    background-color: white;
  }
  .confirmActivePaymentInnerCon {
    width: 80%;
  }
  .leasingTableContainerForLeasing .leaseNameContainer {
    padding-left: 20%;
  }
  .leasingTableContainerForLeasing1 .leaseNameContainer {
    padding-left: 20%;
  }

  .inputFieldCon .myListingSearchInput1 {
    width: 75%;
    margin-left: 42px;
  }
  .leaseInputSearchContainer {
    margin-left: 10%;
  }
  .leasingListAndTableOuterContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .LeasingListContainer {
    width: 100%;
    padding: 20px 10px 20px 20px;
  }

  .LeasingListContainer1 {
    width: 100%;
    padding: 20px 10px 20px 20px;
  }
  .leasingTableContainerForLeasing {
    margin-top: 48px;
    width: 100%;
    margin-left: 0;
    padding-right: 5px;
  }
  .leasingTableContainerForLeasing1 {
    margin-top: 48px;
    width: 100%;
    margin-left: 0;
    padding-right: 5px;
  }

  .agreementListItemsCon .ListItemInnerCon {
    width: 100%;
    margin: 50px auto;
    box-shadow: 3px 1px 3px 0.5px #969696;
  }
  .ListItemInnerCon .mylistItemInnerContainerForLease {
    width: 100%;
    padding: 0px;
    height: 100%;
    position: absolute;
  }

  .theContainerThatRapTheListItem {
    width: 100%;
    margin-left: 0px;
    height: 400px;
  }
  .paymentOptionsOuterCon {
    width: 94%;
    margin-left: 0%;
  }

  .paymentOptionsOuterCon hr {
    width: 100%;
    background: #bcbcbc;
    height: 0.05px;
    margin-left: 0px;
  }

  .leasingTableContainerForLeasing {
    margin-top: 48px;
    width: 100%;
    height: 900px;
    margin-left: 0%;
    padding-right: 5px;
  }
  .leasesTableCon {
    width: 100%;
    margin: 0px 0;
    max-height: 900px;
    overflow-x: scroll;
  }
  .leasesTableCon1 {
    width: 100%;
    margin: 20px 0;
    max-height: 900px;
    overflow-x: scroll;
  }

  .leasesTableCon table {
    width: 1100px;
  }
  .leasesTableCon2 {
    width: 100%;
    margin: 20px 0;
    max-height: 700px;
    overflow-x: scroll;
  }

  .leasesTableCon2 table {
    min-width: 500px;
  }
  .paymentOptionBtn {
    margin-left: 0;
  }
}
