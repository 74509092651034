.ourStoryOuterCon{
width: 100%;
font-family: 'Montserrat', sans-serif;
background: grey;
padding-top: 100px;
padding-bottom: 50px
}

.ourStoryInnerCon{
width: 60%;
padding: 50px 20px;
margin: 0px 20%;
border: 5px solid #035aa6;
border-radius: 10px
}

.ourStoryHead{
text-align: center;
margin-bottom: 20px
}

.ourStoryHead b{
color: #ffa500;
font-family: 'DM Serif Display', serif;
letter-spacing: 2px;
}

.ourStoryPara{
margin: 20px 0
}

.ourStoryPara p{
text-indent: 50px;
 color: white
}

.ourStorySubtitleCon h4{
margin-bottom: 20px;
 color: #035aa6;
  font-weight: bolder                
}
.ourStorySubtitleCon p{
text-indent: 50px;
 color: white
}

@media (max-width: 991px) {
  .ourStoryInnerCon{
    width: 98%;
    padding: 50px 20px;
    margin: 0px 2%;
    border: 5px solid #035aa6;
    border-radius: 10px
    }
}
