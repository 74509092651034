.modalContentInComposeMsg {
  margin-top: 150px;
  margin-left: -100px;
  width: 700px;
}
.inputFieldCon1{
  flex: 3;
  flex-direction: row;
  display: flex;
}

.myListTd1 .removeTheUnderLine,
.myListTd .removeTheUnderLine {
  text-decoration: none;
}
#myFileInputFieldCompose {
  display: none;
}
.composeMessageErrorDisplay {
  color: red;
  font-size: 12px;
}
.myHeadingToComposeMsg {
  font: normal normal bold 14px/19px Merriweather;
}

.theBodyContainerOfComposeMsg {
  width: 100%;
  height: 450px;
}

.selectAnApplicationInComposeMsg {
  width: 100%;
  cursor: pointer;
  color: #707070;
  border-top: white solid 1px;
  border-left: white solid 1px;
  border-right: white solid 1px;
  border-bottom: #707070 solid 1px;
}

.subjectInputInComposeMsg {
  width: 100%;
  color: #707070;
  border-top: white solid 1px;
  border-left: white solid 1px;
  border-right: white solid 1px;
  border-bottom: #707070 solid 1px;
  font: normal normal bold 14px/19px Merriweather;
}

.theBodyContainerOfComposeMsg .theMessageTextAreaInComposeMsg {
  width: 100%;
  border-top: 1px white solid;
  border-right: 1px white solid;
  border-left: 1px white solid;
  border-bottom: 0.1px solid #707070;
  font: normal normal normal 16px/19px Merriweather;
  height: 330px;
}

.imagesTosendInComposeMsg {
  width: 100%;
  height: 45px;
}

.theFooterContainerForComposeMsg {
  padding: 40px;
  width: 100%;
}

.sendBtnContainerComposeMsg {
  position: absolute;
  margin-top: -20px;
  margin-bottom: 50px;
}

.sendBtnContainerComposeMsg button {
  background: #035aa6;
  color: white;
  padding: 5px 20px;
  font: normal normal bold 14px/19px Merriweather;
  box-shadow: 1px 1px 1px 0.5px #969696;
}

.sendBtnContainerComposeMsg button:hover {
  background: white;
  border: solid 1px #035aa6;
  color: #035aa6;
}

.faPaperClipContainComposeMsg {
  margin-top: -15px;
  margin-left: 120px;
  position: absolute;
}

.faPaperClipContainComposeMsg .clipIcon {
  cursor: pointer;
  color: #707070;
}

.faMehEmojiContainerComposeMsg {
  margin-top: -15px;
  margin-left: 160px;
  position: absolute;
}

.faMehEmojiContainerComposeMsg .iconNumber2 {
  cursor: pointer;
  color: #707070;
}

.faMehEmojiContainerComposeMsg .dropdown-menu {
  position: absolute;
  margin-top: -370px;
}

.fileInputMultipleImgMessage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: auto;
}

.fileInputMultipleImgMessage img {
  width: 40px;
  border-radius: 2px;
  margin: 2px;
  height: 30px;
  cursor: pointer;
}

.messagesInnerCon {
  width: 70%;
  margin: 0 auto;
}

.messagesInnerCon1 {
  width: 90%;
  margin-left: 5.5%;
}

.messageTableContainer {
  width: 100%;
  height: 500px;
  margin-top: 10px;
}

.approveUnapproveIconContainer button {
  background: #ff4141 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 1px solid #ff4141;
  width: 80px;
  color: white;
  cursor: pointer;
}

.approveUnapproveIconContainer button:hover {
  border: solid #ff4141 1px;
  background: white;
  color: #ff4141;
}

.messagesScreenOuterCon {
  margin-top: 48px;
  width: 100%;
  height: 600px;
}

.addlistConsMesageBtn {
  cursor: pointer;
  color: white;
  border-radius: 10px;
  font: normal normal bold 12px/19px Merriweather;
  background: #035aa6;

  outline: none;
}


/* .addlistConsMesageBtn:hover {
  border: solid 1px #035aa6;
  background-color: white;
  color: #035aa6;
} */

@media (max-width: 991px) {
  .inputFieldCon1{
    flex: 3;
    flex-direction: column;
    display: flex;
    padding-left: 50px;
  }
  .someMarginInListingSearchInput2{
    margin-bottom: 20px;
  }
  
  .messagesInnerCon {
    width: 100%;
  }
  .messagesInnerCon1{
    margin-left: 0%;

  }
  .messagesScreenOuterCon {
  height:800px;
  }

  .modalContentInComposeMsg {
    width: auto;
    margin-left: -5px;
  }
  .messageTableContainer {
    position: absolute;
    margin-top: 50px;
  }
}
