.contactSubmit {
  background: #035aa6;
  margin-left: 20px;
  margin-top: 50px;
  color: white;
  padding: 10px 50px;
  border: #035aa6 solid 1px;
  font: normal normal bold 16px/19px Merriweather;
  border-radius: 30px;
}

.contactSubmit:hover {
  background: white;
  color: #035aa6;
}

.categoryContainer {
  width: 100%;
  margin-top: 7px;
  padding-left: 20px;
}
.categoryContainer select {
  width: 47%;
  padding-left: 20px;
  cursor: pointer;
}

.loadingModalContainer {
  width: 100%;
  height: 100%;
  background: "white";
  opacity: 0.7;
  z-index: 5;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingModalContainer .loadingInnerContainer {
  width: 400px;
  height: 400px;
  background-color: black;
  border-radius: 10px;
}

.loadingInnerContainer .message {
  opacity: 1;
  color: white;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  text-align: center;
  border: 5px solid red;
  margin: 10% auto;
  border-radius: 30px;
  font: normal normal bold 18px/19px Merriweather;
  line-height: 1.5;
}

.phoneNumberOuterContainer {
  width: 100%;
  margin-top: 5px;
}

.phoneNumberOuterContainer .inputFieldsCon {
  display: flex;
  flex-direction: row;
}

.phoneNumberOuterContainer .inputFieldsCon select {
  width: 30%;
  cursor: pointer;
}

.phoneNumberOuterContainer .inputFieldsCon input {
  width: 70%;
}

.consultSerArrowIcon {
  position: absolute;
  width: 100%;
  height: 60vh;
}

.preferMethodCon label {
  color: #035aa6;
  margin-left: 20px;
  margin-top: 50px;
}
.preferMethodCon .inputFieldsCon {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.preferMethodCon .inputFieldsCon .innerContainer {
  display: flex;
  flex-direction: row;
}
.preferMethodCon .inputFieldsCon .innerContainer input {
  height: 18px;
  width: 18px;
  border-radius: 30px;
  cursor: pointer;
}

.preferMethodCon .inputFieldsCon .secondContainer {
  display: flex;
  flex-direction: row;
  margin-left: 50px;
}

.contactTextAreaContainer {
  width: 95%;
  margin-left: 20px;
  margin-top: 5px;
}

.containerUsOuterContainer {
  width: 100%;
  height: 920px;
  margin-top: 25px;
  background-size: cover;
  background-position: center;
  background: white;
}

.contactUsInnerContainer {
  width: 60%;
  height: 800px;
  border-radius: 10px;
  background: rgba(236, 236, 236);
  margin: 50px 20%;
  position: absolute;
  padding: 50px;
  font: normal normal bold 16px/19px Merriweather;
  border: 1px solid #035aa6;
  opacity: 0.9;
}

.contactUsInnerContainer h5 {
  text-align: center;
  margin-bottom: 40px;
  color: #ffa500;
  font-weight: bolder;
}

.contactUsInnerContainer .nameContainer {
  display: flex;
  flex-direction: row;
}

.nameInputContainer {
  width: 50%;
  padding: 0 20px;
}

.emailContainer {
  width: 100%;
  padding: 0 20px;
  margin-top: 5px;
  display: flex;
}

.contactUsError {
  color: red;
  opacity: 0.8;
  font: normal normal bold 12px/25px Merriweather;
  margin: 10px 30px;
}

@media (max-width: 991px) {
  .contactUsInnerContainer {
    width: 98%;
    margin: 50px 1%;
    height: 1000px;
    padding: 10px;
  }

  .containerUsOuterContainer {
    height: 1100px;
  }
  .contactUsInnerContainer .nameContainer {
    flex-direction: column;
  }
  .nameInputContainer {
    width: 100%;
    padding: 0 5px;
  }

  .emailContainer {
    width: 100%;
    padding: 0 5px;
    margin-top: 5px;
  }

  .phoneNumberOuterContainer {
    margin-top: 5px;
    padding-left: 5px;
  }

  .phoneNumberOuterContainer .inputFieldsCon select {
    width: 35%;
    padding-left: 5px;
  }

  .phoneNumberOuterContainer .inputFieldsCon input {
    width: 63%;
  }

  .categoryContainer {
    width: 100%;
    margin-top: 7px;
    padding-left: 5px;
  }
  .categoryContainer select {
    width: 100%;
    padding-left: 0px;
    cursor: pointer;
  }
  .contactTextAreaContainer {
    width: 98%;
    margin-left: 1%;
    margin-top: 5px;
  }

  .contactSubmit {
    background: #035aa6;
    margin: 50px 10%;
    color: white;
    padding: 10px 0;
    width: 80%;
    border: #035aa6 solid 1px;
    font: normal normal bold 16px/19px Merriweather;
    border-radius: 30px;
  }

  .contactUsInnerContainer h5 {
    margin-top: 50px;
  }
}
