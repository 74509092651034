.langDropdown {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  color: #035aa6;
  padding: 0;
  position: absolute;
  right: 20px;
  width: 12%;
}
.langDropdown1 {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  color: #035aa6;
  padding: 0;
  position: absolute;
  right: 150px;
  width: 12%;
}

.goingBackToMessage {
  padding: 5px;
  box-shadow: 3px 1px 3px 0.5px #969696;
}

.replyOuterContainer {
  width: 100%;
  background: white;
  height: 100px;
  margin-bottom: 20px;
  padding: 5px 20px;
}
.replyOuterContainer .p1 {
  text-align: center;
}
.goingBackToMessage .iconBack {
  margin-right: 5px;
}

.displayTheMessageHere {
  margin-left: 30px;
}

.theMessageImageContainer {
  width: 100%;
}

.bottomDeleteReplyOuterCon {
  width: 20%;
  border-radius: 30px;
  margin: 30px auto;
  background: white;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 1px 3px 0.5px grey;
}

.replyIconInMessageDet {
  margin-right: 50px;
  cursor: pointer;
}

.deleteIconInMessageDet {
  cursor: pointer;
  color: red;
}

.theImagesInMessageDetOuterCon {
  width: 200px;
  cursor: pointer;
  margin-left: 0.5%;
  padding: 10px;
  overflow: hidden;
  background: white;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: lightgray;
}

.theImagesInMessageDetOuterCon .theIconCon {
  background: white;
  margin-left: 10px;
  z-index: 1;
  position: absolute;
}

.theImagesInMessageDetOuterCon .theIconCon a {
  color: black;
}

.theImagesInMessageDet {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.specificImageInMessageDetToShow {
  max-width: 100%;
  height: auto;
}

.subjectInMessageDetCon {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: white;
  margin-top: 5px;
  height: 50px;
  border-bottom: black solid 0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-transform: uppercase;
  font-family: monospace;
}

.subjectInMessageDetCon .innerContainerForSubject {
  left: 22%;
  position: absolute;
  font: normal normal bold 14px/25px Merriweather;
}

.innerContainerForSubject .theSubjectMatter {
  margin-left: 5px;
  box-shadow: 3px 1px 3px 0.5px #969696;
}

.messageTitleMessageDet {
  font: normal normal bold 14px/25px Merriweather;
}

.replyContainerForMessageDet {
  width: 20%;
  padding: 10px;
  height: 400px;
  margin-top: 50px;
  border: 1px solid black;
  border-radius: 10px;
}

.replyContainerForMessageDet .theboldnessForReply {
  font: normal normal bold 14px/25px Merriweather;
  position: absolute;
  margin-left: -350px;
}

.replyOuterContainer .p1 b {
  font: normal normal bold 14px/25px Merriweather;
  position: absolute;
  margin-left: -350px;
}

.replyContainerForMessageDet .theHeading {
  margin-left: 5%;
  text-decoration: underline;
}
.replyOuterContainer .p1 .theHeading {
  font: normal normal normal 13px/25px Merriweather;
}
.replyOuterContainer .p2 {
  margin: 10px 50px;
  font-size: 12px;
  text-align: center;
}
.theDateSentMessageDetOuterCon {
  display: flex;
  flex-direction: row;
}
.theSenderOuterContainerInMessageDet {
  display: flex;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background: white;
  padding: 5px 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.theSenderOuterContainerInMessageDet img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: lightgray;
}

.theSenderAndListingNameMessageDetCon {
  margin-left: 5px;
  font: normal normal bold 14px/25px Merriweather;
  color: #035aa6;
}

.theDateInMessageDetOuterCon {
  flex: 1;
  display: flex;
  align-items: center;
}

.theDateInMessageDetOuterCon .theMessageCreatedDate {
  margin-left: 5px;
}

.theDateInMessageDetOuterCon .theInnerCon {
  display: flex;
  position: absolute;
  font-size: 14px;
  right: 22%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px;
  width: 260px;
  background: white;
  flex-direction: row;
}

.topDeleteReplyOuterCon {
  position: absolute;
  margin-left: 15%;
}

.topDeleteReplyOuterCon .innerContainer {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  height: 50px;
  background: #f8f8f8;
  box-shadow: 3px 1px 3px 0.5px #969696;
}

.theReplyIconInMessageDet {
  margin-right: 50px;
  cursor: pointer;
}

.theDeleteIconInMessageDet {
  cursor: pointer;
  color: red;
}

.unreadMessageForMessageDet {
  width: 60%;
  height: 200px;
  border-radius: 10px;
  background: #f8f8f8;
  border: 1px solid black;
  padding: 0 20px;
  margin: 50px auto;
}
.unreadMessageForMessageDet1 {
  width: 80%;
  border-radius: 10px;
  background: #f8f8f8;
  border: 1px solid black;
  padding: 0 20px;
  margin: 50px auto;
}

.unreadMessageHeadingContainer {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: white;
  margin-top: 5px;
  height: 50px;
  border-bottom: black solid 0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-transform: uppercase;
  font-family: monospace;
}

.unreadMessageHeadingContainer .title {
  font: normal normal bold 14px/25px Merriweather;
}

.backBtnChatsContainerMessageDet {
  margin-left: 220px;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.outerContainerSubjectBodyMessageDetail {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.innerContainerSubjectBodyMessageDetCon {
  width: 60%;
  border-radius: 10px;
  background: #f8f8f8;
  border: 1px solid black;
  padding: 0 20px;
  margin: 50px auto 50px auto;
  /* box-shadow: 3px 1px 3px 0.5px  black; */
}
.innerContainerSubjectBodyMessageDetCon1 {
  width: 80%;
  border-radius: 10px;
  background: #f8f8f8;
  border: 1px solid black;
  padding: 0 20px;
  margin: 50px auto 50px auto;
}

.sendingDateOuterConMessageDet {
  width: 100%;
  padding: 10px;
}

.messageBodyOuterConMessageDet {
  width: 100%;
  border-radius: 10px;
  background: white;
  padding: 30px;
  font: normal normal normal 16px/25px Merriweather;
}

.allSentImageOuterConMessageDet {
  display: flex;
  margin-top: 10px;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .allSentImageOuterConMessageDet {
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .unreadMessageForMessageDet {
    width: 90%;
    height: 400px;
    margin: 20px auto;
  }
  .unreadMessageForMessageDet1 {
    width: 90%;
    height: 400px;
    margin: 20px auto;
  }
  .langDropdown {
    width: 40%;
    margin-bottom: 80px;
    right: 50px;
  }
  .langDropdown1 {
    width: 40%;
    margin-bottom: 80px;
    right: 100px;
  }
  .innerContainerSubjectBodyMessageDetCon1,
  .innerContainerSubjectBodyMessageDetCon {
    width: 90%;
  }

  .backBtnChatsContainerMessageDet {
    margin-left: 0;
  }
  .topDeleteReplyOuterCon {
    position: absolute;
    margin-left: 40%;
  }

  .topDeleteReplyOuterCon .innerContainer {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    height: 50px;
    background: #f8f8f8;
  }
  .theReplyIconInMessageDet {
    margin-right: 20px;
    cursor: pointer;
  }

  .theDateSentMessageDetOuterCon {
    display: flex;
    flex-direction: column;
  }

  .theSenderOuterContainerInMessageDet {
    margin-bottom: 30px;
  }

  .theDateInMessageDetOuterCon .theInnerCon {
    right: 10%;
  }

  .messageBodyOuterConMessageDet {
    margin-top: 30px;
    padding: 15px;
  }
  .theImagesInMessageDet {
    width: 90%;
  }
  .theImagesInMessageDetOuterCon {
    width: 100%;
  }
  .bottomDeleteReplyOuterCon {
    width: 40%;
  }

  .subjectInMessageDetCon .innerContainerForSubject {
    left: 5%;
  }
}
