.career_content::before{
content: open-quote;   
color: #035aa6;    
font-size: 30px;  
}

.commitToReccon{
width: 100%;
height: 300px
}

.commitToReccon .content{
background: black;
width: 100%;
height: 300px;
opacity: 0.7;
position: absolute;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.environment_p::after{
content: close-quote;   
color: #035aa6;    
font-size: 30px;                  
}

.careerHeadCon{
width: 100%;
 height: 600px;
 font-family: 'Montserrat', sans-serif
}

.careerHeadInnerCon{
width: 100%;
height: 600px;
background: black;
opacity: 0.7;
position: absolute;
z-index: 1;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column
}

.careerHeadInnerCon h1{
font-weight: bolder;
opacity: 1;
color: white;
font-size: 40px;
color: white;
width: 60%;
text-align: center;      
margin-bottom: 0;
padding: 20px
}

.careerIntroCon{
width: 80%;
margin: 50px auto 0px auto;
padding-bottom: 50px;
}
.careerIntroCon p{
font-size: 20px;
}

.careerCarouselInnerCon{
display: flex;
 flex-direction: row
}

.careerCarouselInnerCon .careerImageCon{
flex: 1;
height: 100%
}

.careerWebDev{
flex: 1;
background-color: #035aa6;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
text-align: left;
padding: 20px;
color: white;
}

.careerMobileDev{
flex: 1;
background-color: black;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
text-align: left;
padding: 20px;
color: white;
opacity: 0.9
}

.careerCyberSec{
flex: 1;
background-color: white;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
text-align: left;
padding: 20px;
}

.careerRealEst{
flex: 1;
background-color: grey;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
text-align: left;
padding: 20px;
color: white
}

.careerIconss{
color: white;
font-size: 50px;
margin-bottom: 20px
}

.cyberSecIcon{
color: #035aa6;
font-size: 50px;
margin-bottom: 20px
}

.careerRecruitmentCon{
width: 100%;
background-color: #035aa6;
color: white;
padding-top: 20px;
font-size: 20px
}

.careerRecrContent{
width: 80%;
 margin: 0 auto
}

.careerRecrLink{
text-decoration: none;
cursor: pointer;
color: #ffa500;
margin-left: 80px
}

.careerCommitted{
font-size: 16px;
width: 90%;
margin: 0 auto;
text-align: center
}

.careerCapTvBtn{
text-decoration: none;
cursor: pointer;
color: white;
margin: 20px auto;
background: #ffa500;
padding: 5px 10px;
margin-top: 20px;
display: flex;
justify-content: center;
align-items: center;
width: 25%;
}

.careerArrowCapTv{
color: white;
font-size: 30px
}

.careerWorkEnv{
width: 100%;
height: 600px
}

.careerWorkEnv h3{
text-align: center;
color: #035aa6;
margin: 80px auto 10px auto;
}

.careerWorkEnv a{
text-decoration: none;
color: #ffa500;
margin-left: 80px
}

.careerWorkCar{
width: 60%;
margin: 50px auto 50px auto;
height: 100%
}

.careerWorkCar .careerInnerCon{
display: flex;
flex-direction: row;
}

.careerInnerContentCon{
flex: 1;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
text-align: left;
padding: 20px
}

.careerInnerContentCon h5{
text-align: left;
font-weight: bold;
margin-top:30px;
color: black;
opacity: 1
}

.careerCarImageCon{
flex: 1;
height: 100%;
padding: 30px
}

.careerInternshipCon{
 width: 100%;
 height: 500px
}

.careerInternshipInnerCon{
width: 100%;
height: 500px;
position: absolute;
background: black;
opacity: 0.7;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}

.careerInternshipInnerCon h3{
color: #ffa500;
opacity: 1;
font-weight: bolder          
}

.careerInternshipInnerCon p{
width: 70%;
margin: 0 auto;
text-align: center;
opacity: 1;
color: white
}


@media (max-width: 991px) {
.careerHeadCon{
width: 100%;
 height: 70vh;
 font-family: 'Montserrat', sans-serif
}      

.careerHeadInnerCon{
height: 70vh;
}
.careerHeadInnerCon h1{
font-size: 25px;
padding: 0;
line-height: 1.7;
width: 90%;
}
.careerIntroCon h3{
font-size: 20px;
}
.careerIntroCon p{
font-size: 14px;
}
.careerCarouselInnerCon{
display: flex;
flex-direction: column;
}
.careerRecrContent h3{
font-size: 20px;
}
.careerRecrContent p{
font-size: 16px;
}
.careerRecrLink{
margin-left: 20px;
}

.careerRecruitmentCon{
padding-top: 0;
}
.commitToReccon{
height: 800px;
}
.commitToReccon .content{
height: 800px;
}
.commitToReccon .content h1{
font-size: 30px;
text-align: center;
}
.careerCapTvBtn{
width: 90%;
}

.careerInternshipCon{
 width: 100%;
 height: 500px;
 margin-top: 800px;
}
.careerWorkCar{
width: 98%;
margin: 50px auto 50px auto;
height: 100%
}
                
.careerWorkCar .careerInnerCon{
display: flex;
flex-direction: column;
}

.careerIntroCon{
                width: 100%;
                margin: 50px auto 0px auto;
                padding-bottom: 50px;
                }
}

