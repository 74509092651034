.editSpaceAllContainer {
  width: 100%;
  height: 700px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.editAvailableSpaceOuter {
  width: 85%;
  height: 600px;
  margin-left: 15%;
  margin-bottom: 50px;
  /* margin: 50px 15%; */
  border: 1px solid #707070;
  box-shadow: 2px 5px 7px black;
  background: #fcfdff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.editAvailableSpaceOuter1 {
  width: 95%;
  height: 600px;
  margin: 50px 5%;
  border: 1px solid #707070;
  box-shadow: 2px 5px 7px black;
  background: #fcfdff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.editAvailableSpaceTitle {
  font: normal normal bold 18px/26px Merriweather;
  color: #035aa6;
  text-align: center;
}

.editAvailableSpaceSubTitle {
  font: normal normal normal 14px/26px Merriweather;
  text-align: center;
  margin-top: 5px;
}

.editAvailableSpaceInner {
  display: flex;
  width: 100%;
  height: 400px;
}

.editAvailableSpaceInnerLeft {
  width: 23%;
  height: 400px;
  margin-right: 2px;
}

.editAvailableSpaceInnerRight {
  flex: 1;
  background: #d3d3d3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.editSpaceListCon {
  width: 100%;
  height: 350px;
  background: white;
}

.editSpaceFormFieldCon {
  width: 60%;
  height: 200px;
  border: 2px solid white;
  border-radius: 5px;
  padding: 5px;
}

@media (max-width: 991px) {
  .editSpaceAllContainer {
    width: 100%;
    height: 900px;
    padding-bottom: 10px;
  }

  .editAvailableSpaceOuter1,
  .editAvailableSpaceOuter {
    width: 98%;
    margin: 50px 1%;
    height: 800px;
  }
  .editAvailableSpaceInner {
    flex-direction: column;
    height: 700px;
  }
  .editAvailableSpaceInnerLeft {
    width: 100%;
    height: 150px;
    margin-right: 0px;
  }
  .editAvailableSpaceInnerRight {
    flex: none;
    background: #d3d3d3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    margin-top: 50px;
  }
  .editSpaceListCon {
    height: 150px;
  }

  .editSpaceFormFieldCon {
    width: 98%;
    height: 300px;
  }
}
