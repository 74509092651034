.noBlogAvailableCon {
  width: 30%;
  text-align: center;
  margin: 10px auto;
}
.addStoryBtnLink {
  padding: 10px 20px;
  font-weight: bold;
  color: #035aa6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
}
.blogHeadCon {
  width: 100%;
  height: 40vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.blogHrCon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
}
.blogHeadInnerCon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.blogHeadInnerCon h1 {
  font-size: 70px;
  color: #035aa6;
  font-family: "DM Serif Display", serif;
}

.blogHeadDownArrow {
  position: absolute;
  width: 100%;
  height: 50vh;
}

.blogBodyCon {
  width: 80%;
  margin: 0 auto;
}

.blogBodyInnerCon {
  display: flex;
}

.blogNoStory {
  width: 70%;
}

.blogRecentStory {
  width: 30%;
}

.blogDisplayCon {
  padding-left: 30px;
}

.blogDisplayInnerCon {
  width: 98%;
  height: 300px;
  display: flex;
}

.blogDisplayImageCon {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.blogDisplayContentCon {
  width: 60%;
  height: 100%;
  padding: 10px;
}

.blogDisplayContentBlogType {
  color: #035aa6;
  font-size: 14px;
  margin-left: 5px;
  text-transform: uppercase;
}

.blogTitleLink {
  width: 100%;
  color: #035aa6;
  text-decoration: none;
}

.blogContentContents {
  margin-top: 10px;
  font-size: 16px;
}

.blogRecentOuterCon {
  padding: 0;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.blogRecentContentCon {
  text-decoration: none;
  color: #035aa6;
  font-weight: bold;
  font-size: 14px;
}
.blogRecentOuterCon .date {
  color: grey;
  font-size: 12px;
}
.blogRecentOuterCon .icon {
  color: red;
  opacity: 0.8;
  font-size: 16px;
  margin-bottom: 20px;
  cursor: pointer;
}
.blogHeadInnerCon b {
  font-size: 27px;
}

@media (max-width: 991px) {
  .noBlogAvailableCon {
    width: 94%;
  }
  .addStoryBtnLink {
    width: 50%;
  }
  .blogHeadCon {
    width: 100%;
    height: 70vh;
  }

  .blogHeadInnerCon h1 {
    font-size: 30px;
    text-align: center;
  }
  .blogHeadInnerCon b {
    font-size: 16px;
  }

  .blogHeadDownArrow {
    height: 70vh;
  }

  .blogHrCon span {
    width: 100px;
    font-size: 10px;
  }

  .blogNoStory {
    width: 100%;
  }

  .blogBodyCon {
    width: 100%;
  }
  .blogBodyInnerCon {
    display: flex;
    flex-direction: column;
  }

  .blogDisplayCon {
    padding-left: 0px;
    margin-top: 20px;
  }

  .blogDisplayInnerCon {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .blogDisplayImageCon {
    height: 300px;
    width: 100%;
  }
  .blogDisplayContentCon {
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  .blogTitleLink h3 {
    font-size: 20px;
  }
  .blogRecentStory {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
