/* not found screen */
.textInputKeywordAdd {
  width: 80%;
}
.angleIconContainer {
  border-radius: 100%;
  border: 1px solid #035aa6;
  width: 28px;
  height: 28px;
  margin-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #035aa6;
}
.angleIconContainer:hover {
  background: #035aa6;
  color: white;
}
.signBarUpArrow {
  visibility: hidden;
  position: absolute;
  background: #035aa6;
  color: #fff;
  width: 100%;
  padding: 0;
}
.errorUploadingContainerForAddList {
  width: 80%;
  margin: 5px auto;
  text-align: center;
}

.sidebarContainer .addListingListItemAtag {
  text-decoration: none;
}
.openAngleListItem {
  margin-left: 1px;
}
.sideListItemSentMsges {
  background: #ffa500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-left: 50px;
  height: 25px;
  width: 25px;
  font-size: 14px;
  color: white;
}

.tenantSignModalAgreement {
  display: inline;
  font: normal normal normal 14px/26px Merriweather;
}
.theLeftMarginInTenantSignModalAgreement {
  margin-left: 70px;
  position: relative;
  width: 100%;
}

.notFoundOuterCon {
  margin-top: 75px;
  width: 60%;
  /* height: 20vh; */
  padding: 0 100px;
  margin-left: 20%;
}
.notFoundOuterCon .alert {
  text-align: center;
  background-color: #707070;
  color: white;
  border-radius: 5px;
}

/* Chats component */

.addListChatCon {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 20px;
}

.addListChatCon .badge {
  margin-left: 40px;
  background-color: white;
  border: 1px solid black;
  color: #035aa6;
  border-radius: 100%;
  position: absolute;
}

/* Checkbox component */
.addListCheckboxCon {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.addListCheckboxCon .inputCon {
  flex: 1;
  margin-left: 100px;
}

.addListCheckboxCon .inputCon .postionChecked {
  cursor: pointer;
}

.inputCon input {
  border-radius: 2px;
  width: 20px;
  height: 20px;
  border: 1px solid #707070;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: pointer;
}

.addListCheckboxCon .paraCon {
  flex: 7;
}

.addListCheckboxCon p {
  color: #707070;
  letter-spacing: 1.13px;
  font: normal normal normal 14px/26px Merriweather;
}

/* HrInfo Component */

.HrInfoConAddList {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.HrInfoConAddList hr {
  width: 10%;
  border: 1px solid var(--primary-nav);
  line-height: 1px;
  border: 1px solid #035aa6;
  margin: 7px 0;
}

.HrInfoConAddList h6 {
  color: #222222;
  line-height: 1px;
  letter-spacing: 1.13px;
  font: normal normal normal 15px/25px Merriweather;
}

/* Input component */
.addListingSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.fileInputImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#myAudioPlay {
  display: none;
}
.fileInputErrorP {
  color: red;
  font-size: 10px;
  width: 100%;
  text-align: center;
}

.fileInputImgContainer img {
  width: 80px;
  border-radius: 2px;
  height: 40px;
  object-fit: cover;
}

.fileInputMultipleImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: auto;
}
.fileInputMultipleImg video,
.fileInputMultipleImg img {
  width: 40px;
  border-radius: 2px;
  margin: 2px;
  height: 30px;
  cursor: pointer;
  object-fit: cover;
}

.addListLabel {
  color: #707070;
  margin-top: 10px;
  letter-spacing: 1.13px;
  font: normal normal normal 16px/25px Merriweather;
}

.addListPlaceholderOption {
  color: #bcbcbc;
  letter-spacing: 0.9px;
  font: normal normal normal 16px/26px Merriweather;
}

.addListingOption {
  color: #222222;
  letter-spacing: 0.9px;
  font: normal normal normal 16px/26px Merriweather;
}
.addListingInput {
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  color: #222222;
  letter-spacing: 0.9px;
  font: normal normal normal 14px/24px Merriweather;
  padding: 5px;
  width: 80%;
}

/* ListingItems Component */

.ListingItemConAddList {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  fill: #035aa6;
  color: #035aa6;
}

.activePortalLink {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #035aa6;
  color: white;
  fill: white;
}

.ListingItemConAddList:hover {
  background-color: grey;
  color: white;
  fill: white;
}

.iconBadgeSpan {
  margin-left: 20px;
}

.iconBadgeSpan .badge {
  margin-left: 15px;
  font-size: 10px;
  padding: 2px;
  background-color: #035aa6;
  border: 1px solid black;
  color: white;
  border-radius: 100%;
  position: absolute;
}

.ListingItemConAddList .titleSpanAddListing {
  margin-left: 10px;
}
.activePortalLink .titleSpanAddListing {
  margin-left: 10px;
}

/* OpenButton component */
.openButtonCon {
  width: 70px;
  height: 570px;
  box-shadow: 1px 3px 6px #00000029;
  position: fixed;
  top: 100px;
}
/* OpenButton component */
.openButtonConTenant {
  width: 70px;
  max-height: 380px;
  box-shadow: 1px 3px 6px #00000029;
  position: fixed;
  top: 100px;
}
.openButtonAngelIcon {
  width: 35px;
  height: 35px;
}

/* SaveButton Component */

.saveButtonCon {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.saveButtonCon button {
  border-radius: 5px;
  letter-spacing: 1.13px;
  font: normal normal bold 16px/25px Merriweather;
  color: #ffffff;
  padding: 5px 0;
  width: 40%;
  cursor: pointer;
  background: #035aa6 0% 0% no-repeat padding-box;
  border: #035aa6 1px solid;
  box-shadow: 3px 1px 3px 0.5px black;
  outline: none;
}

.saveButtonCon button:hover {
  background: white;
  border: 1px solid #035aa6;
  color: #035aa6;
}

/* Select component */
.addListSelectLabel {
  color: #707070;
  margin-top: 10px;
  letter-spacing: 1.13px;
  font: normal normal normal 16px/25px Merriweather;
}
.addListSelectLabel50 {
  color: #707070;
  letter-spacing: 1.13px;
  font: normal normal normal 16px/25px Merriweather;
}

.addListingSelect {
  width: 80%;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  border: 1px solid #bcbcbc;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  color: #222222;
  letter-spacing: 0.9px;
  font: normal normal normal 14px/24px Merriweather;
  cursor: pointer;
}

/* Sidebar component */

.sidebarContainer {
  box-shadow: 1px 3px 6px #00000029;
  position: fixed;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 200px;
  min-height: 300px;
  margin-top: 40px;
  z-index: 5;
}

.sidebarMenuSpan {
  color: #707070;
  letter-spacing: 0.9px;
  font: normal normal bold 18px/23px Merriweather;
}

.sidebarMenuLeftAngle {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.sidebarMenuRightAngle {
  width: 25px;
  margin-left: 0px;
  height: 25px;
  cursor: pointer;
}

.sidebarHr {
  width: 60%;
  position: absolute;
  margin: 0px 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .sideBarLinks {
  width: 100%;
  height: 470px;
} */

.sidebarLinkTitle {
  /* color: #707070; */
  letter-spacing: 0.9px;
  font: normal normal normal 16px/23px Segoe UI;
}

.activeSidebarTitle {
  /* color: white; */
  letter-spacing: 0.9px;
  font: normal normal normal 16px/23px Segoe UI;
}

.sidebarLinkTitle:hover {
  color: white;
}

.sidebarIconsClassMenu {
  width: 30px;
  height: 35px;
}

.sidebarIconLink {
  width: 25px;
  height: 30px;
  /* fill: #035aa6; */
}
.sidebarIconLink1 {
  width: 25px;
  height: 30px;
  /* fill: #035aa6; */
}
.sidebarIconLinkActive {
  width: 25px;
  height: 30px;
  /* fill: white; */
}
.sidebarIconLinkActive1 {
  width: 25px;
  height: 30px;
  /* fill: white; */
}

/* Textarea component */

.addListTextareaLabel {
  margin-top: 10px;
  letter-spacing: 1.13px;
  font: normal normal normal 16px/25px Merriweather;
}

.addListTextarea {
  width: 95%;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  border: 1px solid #bcbcbc;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  color: #222222;
  letter-spacing: 0.9px;
  font: normal normal normal 14px/24px Merriweather;
}

/* TitleHeader component */
.addListTitleHeaderCon {
  opacity: 0.8;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #707070;
  background: #035aa6 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-707070);
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.addListTitleHeaderCon h4 {
  font: normal normal bold 20px/27px Merriweather;
  text-align: center;
  letter-spacing: 1.13px;
  color: black;
  opacity: 1;
}

/* WelcomeUser component */

.addListWelcomeCon {
  width: 80%;
  margin: 80px 15% 50px 15%;
}

.addListWelcomeCon h6 {
  color: #222222;
  letter-spacing: 0.9px;
  margin-left: 120px;
  text-align: left;
  font: normal normal bold 18px/25px Merriweather;
}

/* AddListingScreen */
.addListingProgressBarCon {
  margin: 30px 5%;
  width: 90%;
  height: 20px;
}
.addListingInputsFieldCon {
  margin-left: 15px;
}
.addListingScreenOuterCon {
  width: 100%;
  min-height: 2050px;
  padding-bottom: 10px;
}

.addListScreenInnerCon {
  width: 60%;
  min-height: 1930px;
  margin: 50px 25%;
  border: 1px solid #707070;
  box-shadow: 2px 5px 7px black;
  background: #fcfdff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding-bottom: 10px;
}

.addListScreenInnerCon1 {
  width: 70%;
  min-height: 1930px;
  margin: 50px 15%;
  border: 1px solid #707070;
  box-shadow: 2px 5px 7px black;
  background: #fcfdff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding-bottom: 10px;
}

.addListScreenInnerCon .info {
  color: #222222;
  text-align: center;
  margin-top: 20px;
  letter-spacing: 1.13px;
  font: normal normal normal 18px/22px Merriweather;
}

.addListScreenInnerCon1 .info {
  color: #222222;
  text-align: center;
  margin-top: 20px;
  letter-spacing: 1.13px;
  font: normal normal normal 18px/22px Merriweather;
}

.addListScreenInnerCon .row {
  width: 100%;
  padding: 0 50px;
}

.addListScreenInnerCon1 .row {
  width: 100%;
  padding: 0 50px;
}

@media (max-width: 1285px) {
  .addListScreenInnerCon {
    min-height: 1930px;
  }
  .addListScreenInnerCon1 {
    min-height: 1930px;
  }
}

@media (max-width: 991px) {
  .textInputKeywordAdd {
    width: 100%;
  }
  .notFoundOuterCon {
    margin-top: 75px;
    width: 100%;
    height: 50vh;
    padding: 0 10px;
    margin-left: 0%;
  }
  .signBarUpArrow {
    visibility: visible;
    position: relative;
  }
  .addListingInputsFieldCon {
    margin-left: 0px;
  }
  .addListingScreenOuterCon {
    min-height: 3500px;
  }
  .addListScreenInnerCon {
    min-height: 3430px;
    width: 98%;
    margin-left: 1%;
  }
  .addListScreenInnerCon1 {
    min-height: 3430px;
    width: 98%;
    margin-left: 1%;
  }
  .addListScreenInnerCon .row {
    width: 100%;
    padding: 0 30px;
  }

  .addListScreenInnerCon1 .row {
    width: 100%;
    padding: 0 30px;
  }

  .sidebarContainer {
    box-shadow: 1px 3px 6px #00000029;
    position: fixed;
    background: white 0% 0% no-repeat padding-box;
    z-index: 1;
    min-height: 320px;
    margin-top: 35px;
  }

  .openButtonCon {
    background-color: white;
    z-index: 1;
    top: 90px;
  }
  .openButtonConTenant {
    background-color: white;
    z-index: 1;
    max-height: 300px;
    top: 90px;
  }

  .addListCheckboxCon .inputCon {
    margin-left: 5px;
  }
  .addListingSelect,
  .addListingInput {
    width: 100%;
  }

  .addListTextarea {
    height: 200px;
  }

  .addListWelcomeCon h6 {
    margin-left: 20px;
  }
}
