.sliderContainer {
  margin-top: 20px;
  padding-right: 5px;
}
.textInputKeywordCon5089 {
  width: 70%;
  margin: 5px;
  position: absolute;
  z-index: 5;
  padding-left: 5px;
}

.searchListingDisplayCarousel {
  background-color: lightgray;
}
.mapCardDisplayOuterCon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* styling for carousel component */
.containerForTitleBedroomPriceInMap h5 {
  text-align: center;
  font: normal normal bold 18px/19px Merriweather;
  margin-top: 5px;
}

.containerForTitleBedroomPriceInMap p {
  text-align: center;
  font: normal normal normal 14px/19px Merriweather;
}
.imageContainer .theImageForMapsWindow {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.windowContainerForMap {
  width: 200px;
  height: 300px;
  display: flex;
  flex-direction: column;
}
.windowContainerForMap .imageContainer {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.sortContainer {
  width: 8.7%;
}
.sortContainer select {
  cursor: pointer;
  margin-top: 30px;
  margin-left: 10px;
  color: #bcbcbc;
  font: normal normal normal 16px/26px Merriweather;
  height: 50px;
}

.noAvailableListInfoContainerForSearchList .alert {
  text-align: center;
  width: 80%;
  margin: 50px auto;
}
.cardBodyContainerForSearchList {
  background-color: #dddddd;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.rentOrSellContainer {
  width: 15%;
}

.rentOrSellContainer select {
  cursor: pointer;
  margin-top: 30px;
  font: normal normal normal 16px/26px Merriweather;
  letter-spacing: 0.9px;
  color: #bcbcbc;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 50px;
}

.searchCarouselNextBtn,
.searchCarouselPrevBtn {
  width: 20px;
  height: 40px;
  background-color: #000;
  top: 40%;
  opacity: 0.5;
}

.searchListingInputCon {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  justify-content: left;
  align-items: left;
}
.searchListingInputCon .inputCon1SearchList {
  width: 35%;
  margin-right: 5px;
  padding: 30px 0 10px 50px;
  display: flex;
  flex-direction: row;
}

.inputCon1SearchList select {
  width: 98%;
  padding: 5px;
  font: normal normal normal 16px/26px Merriweather;
  letter-spacing: 0.9px;
  color: #bcbcbc;
  margin-top: 30px;
  border-radius: 5px;
  /* border: 1px solid #bcbcbc; */
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  cursor: pointer;
}
.inputCon1SearchList .selectCity {
  margin-left: 5px;
  cursor: pointer;
}
.searchListingInputCon .inputCon2SearchList {
  width: 20%;
  padding: 2px 0;
}

.inputCon2SearchList input {
  width: 95%;
  padding: 7px 5px;
  height: 50px;
  font: normal normal normal 16px/26px Merriweather;
  letter-spacing: 0.9px;
  color: #bcbcbc;
  margin-top: 30px;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.searchListingInputCon .inputCon3SearchList {
  width: 20%;
  padding: 10px 0;
  cursor: pointer;
}

.inputCon3SearchList select {
  width: 50%;
  padding: 5px;
  font: normal normal normal 16px/26px Merriweather;
  letter-spacing: 0.9px;
  color: #bcbcbc;
  margin-top: 30px;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: pointer;
}

.searchListingInputCon .inputCon4SearchList {
  width: 10%;
  padding: 10px 2px;
}

.inputCon4SearchList select {
  width: 100%;
  padding: 5px;
  margin-left: 2px;
  font: normal normal normal 16px/26px Merriweather;
  letter-spacing: 0.9px;
  color: #bcbcbc;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: pointer;
}
.searchListingInputCon .inputCon5SearchList {
  width: 20%;
  padding: 2px 2px;
}
.inputCon5SearchList .card {
  width: 99%;
  padding: 2px;
  font: normal normal normal 14px/19px Merriweather;
  letter-spacing: 0.68px;
  color: white;
  margin-top: 30px;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  background: #035aa6 0% 0% no-repeat padding-box;
  text-align: center;
}

.searchListingDisplayCon {
  width: 100%;
  height: auto;
  padding-left: 10px;
  padding-right: 5px;
}

.searchListingDisplayCardCon {
  padding: 5px;
  box-shadow: 2px 5px 7px black;
  width: 48%;
  margin: 5px 1%;
  border-radius: 5px;
  /* margin: 5px; */
}
.searchListingDisplayCardCon:hover {
  border: 1px solid #035aa6;
  outline: 0;
}
.searchListingDisplayCardCon1 {
  padding: 5px;
  box-shadow: 2px 5px 7px black;
  /* margin: 5px; */
  width: 48%;
  margin: 5px 1%;
  border-radius: 5px;
}
.searchListingDisplayCardCon1:hover {
  border: 1px solid #035aa6;
  outline: 0;
}

.searchListingDisplayCardCon1 .card {
  border: 0px solid white;
  outline: 0;
  /* box-shadow: 2px 5px 7px black; */
}

/* .searchListingDisplayCardCon .card:hover {

} */

.searchListingCarouselImages {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.searchListingMapCon {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
}

.searchListingMapCon h1 {
  position: absolute;
  top: 13rem;
  z-index: 1;
  left: 1rem;
  color: #035aa6;
  margin: 0;
  padding: 0;
}

.searchListingMapCon img {
  width: 100%;
  height: 700px;
  object-fit: cover;
}

.searchListingMapCon img:hover {
  height: 710px;
}

.searchListingConMapSearch {
  margin-top: 45px;
  width: 100%;
  /* height: auto; */
}

.searchListingMapAndListingCon {
  width: 100%;
  height: 1350px;
  display: flex;
  flex-direction: row;
}

.searchListingMapAndListingCon .onlyMapCon {
  flex: 2;
  box-shadow: 2px 5px 7px black;
  margin-right: 15px;
}

.searchListingMapAndListingCon .onlyListingsCon {
  flex: 1.2;
  padding-left: 5px;
  padding-right: 5px;
}

.myPaginationContainerSearch {
  margin: 100px 0 10px 0;
  width: 100%;
  position: relative;
  padding: 20px;
  z-index: 5;
  display: flex;
  justify-content: center;
}

@media (max-width: 991px) {
  .textInputKeywordCon5089 {
    width: 80%;
    margin: 5px;
    position: absolute;
    z-index: 5;
    padding-left: 5px;
    bottom: 5px;
  }

  .sliderContainer {
    margin-top: 0;
  }
  .searchListingDisplayCarousel {
    height: 400px;
    background-color: lightgray;
  }
  .searchListingDisplayCardCon {
    width: 98%;
    margin: 10px 1%;
    padding: 10px;
  }
  .searchListingDisplayCardCon1 {
    padding: 10px;
  }

  .searchListingDisplayCarousel .carousel-item,
  .searchListingDisplayCarousel .carousel-inner {
    width: 100%;
    height: 400px;
  }
  .searchListingCarouselImages {
    height: 100%;
    width: 100%;
  }

  .rentOrSellContainer {
    width: 49%;
    margin-left: 1%;
  }

  .rentOrSellContainer select {
    margin-top: 7px;
  }
  .searchListingConMapSearch {
    height: 4920px;
    position: relative;
    /* margin-bottom: 200px; */
  }
  .searchListingMapAndListingCon {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .searchListingMapAndListingCon .onlyMapCon {
    height: 70vh;
    box-shadow: none;
    margin-right: 0;
  }
  .searchListingMapAndListingCon .onlyListingsCon {
    flex: 1;
  }
  .searchListingMapCon {
    height: 70vh;
  }
  .searchListingMapCon img {
    height: 500px;
    object-fit: cover;
  }

  .myPaginationContainerSearch {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    margin-left: 0%;
  }
  .searchListingInputCon {
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 300px;
  }
  .searchListingInputCon .inputCon5SearchList {
    width: 100%;
  }
  .inputCon5SearchList .card {
    margin-top: 0px;
    width: 100%;
    padding: 7px 5px;
    /* height: 0px; */
    z-index: 1;
  }

  .searchListingInputCon .inputCon4SearchList {
    width: 49%;
    padding: 0px;
    margin-right: 0px;
  }

  .inputCon4SearchList select {
    margin-top: 7px;
    margin-left: 2px;
  }

  .searchListingMapCon h1 {
    top: 22rem;
  }
  .searchListingInputCon .inputCon3SearchList {
    width: 49%;
    padding: 0px 0;
    margin-top: 6px;
  }

  .inputCon3SearchList select {
    width: 100%;
    margin-top: 10px;
  }

  .searchListingInputCon .inputCon2SearchList {
    width: 50%;
    padding-left: 5px;
  }

  .inputCon2SearchList input {
    margin-top: 5px;
  }

  .searchListingInputCon .inputCon1SearchList {
    width: 98%;
    margin-left: 1%;
    margin-top: 0;
    margin-bottom: 12px;
    padding: 0;
  }

  .inputCon1SearchList select {
    width: 100%;
    margin-top: 5px;
  }
  .sortContainer {
    width: 98%;
    margin-bottom: 7px;
    margin-left: 1%;
  }
  .sortContainer select {
    margin-top: 5px;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-left: 0;
  }
}

@media (max-height: 1366px) and (min-height: 1024px) {
  .searchListingInputCon .inputCon2SearchList {
    width: 20%;
    padding: 10px 0;
  }

  .inputCon2SearchList {
    width: 50%;
  }

  .inputCon2SearchList input {
    margin-top: 0;
  }
  .searchListingInputCon .inputCon3SearchList {
    width: 20%;
    padding: 10px 0;
  }

  .inputCon3SearchList select {
    margin-top: 30px;
  }

  .rentOrSellContainer {
    width: 200px;
  }

  .rentOrSellContainer select {
    margin-top: 10px;
  }
}
