.tenantDetailInfoContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 20px auto;
}
.nonMemberPayCancelBtn{
  position: absolute;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nonMemberModalInnerCon{
    width: 40%;
    height: 245px;
    background: white;
    border: 2px solid #035aa6;
    border-radius: 10px;
    padding: 10px;
}

.nonMemberProfileImageContainer1{
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d3d3d3;
  cursor: pointer;
}

.nonMemberModalOuterCon{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    position: fixed;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeInDownConfirm 0.6s ease-in-out;  
}

.tenantDetailsPaymentTableCon{
  box-shadow: 3px 1px 3px 0.5px black; 
  max-height: 630px; 
}
@keyframes fadeInDownConfirm {
  0% {
    opacity: 0;
    transform: translateY(-100vh);
  }
                                   
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  }

.TheContainerThatTellsThatIsFlexible {
  width: 85%;
  margin-left: 15%;
}
.tenantDetailsOuterContainers {
  margin-top: 45px;
  margin-bottom: 200px;
  width: 100%;
  min-height: 1300px;
}
.tenantDetailsOuterContainers4{
  margin-bottom: 200px;
  width: 85%;
  margin-left: 15%;
  min-height: 1300px;
}
.TheContainerThatTellsThatIsFlexible5{
  /* margin-top: 35px; */
  margin-bottom: 200px;
  width: 95%;
  margin-left: 5%;
  min-height: 1300px;
}
.TheContainerThatTellsThatIsFlexible1 {
  width: 95%;
  margin-left: 5%;
}
.modalContentToEditPayment {
  margin-top: 150px;
  width: 700px;
}
.editPaymentTitle {
  font: normal normal bold 16px/25px Merriweather;
}
.EditPaymentSpinalContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.questionHowMuchToEditContainer {
  display: flex;
  flex-direction: row;
  font: normal normal normal 14px/25px Merriweather;
}

.editTheQuestionIsHere {
  margin-top: 20px;
  font: normal normal normal 16px/25px Merriweather;
}

.editTheQuestionIsHere input {
  width: 70%;
}

.editSaveChangeBtn {
  background: #035aa6;
  color: white;
  width: 50%;
  margin: 10px 25%;
  border-radius: 10px;
  font: normal normal normal 20px/25px Merriweather;
  border: #035aa6 solid 1px;
}

.editSaveChangeBtn:hover{
  background: white;
  color: #035aa6;
}

.theErrorWhileEdit {
  text-align: center;
  color: rgba(255, 99, 71, 0.5) ;
  font-size: 12px;
}

.editPayDateContainer {
  flex: 1;
}

.editPayDateContainer .letUseSeparate {
  margin-left: 5px;
}

.editPayDateContainer .letUseSeparate1 {
  margin-left: 10px;
}

.tenantDetailsPaymentTableCon {
  border: 1px solid #035aa6;
  border-radius: 5px;
}

.faChevronCircleUp {
  margin-right: 5px;
}

.editPaymenDetailsContainer {
  width: 100%;
  background-color: grey;
}

.editPayDateContainer .card-body {
  background-color: grey;
}

.downloadingTheReceiptPayment {
  padding: 0;
  border-radius: 5px;
  background: #035aa6;
  color: white;
  padding: 2px 2px;
  border: #035aa6 solid 1px;
}
.downloadingTheReceiptPayment:hover{
  background: white;
  color: #035aa6;
}

.closeTheDetailsBtn {
  background-color: #035aa6;
  color: white;
  cursor: pointer;
  border: #035aa6 1px solid;
}
.closeTheDetailsBtn:hover{
  background-color: white;
  color: #035aa6;
}

@media (max-width: 991px) {
  .nonMemberModalInnerCon{
    width: 98%;
    height: 245px;
    background: white;
    border: 2px solid #035aa6;
    border-radius: 10px;
    padding: 10px;
}
  .nonMemberProfileImageContainer1{
    width: 60px;
    height: 60px;
  }
  
  .tenantDetailsOuterContainers4{
    margin-bottom: 200px;
    width: 98%;
    margin-left: 1%;
  }
  .TheContainerThatTellsThatIsFlexible {
    width: 96%;
    margin-left: 2%;
  }
  .editSaveChangeBtn {
    width: 90%;
    margin: 10px 5%;
  }
  .tenantDetailInfoContainer{
    width: 80%;
    margin-top: 50px;
  }
  .someMadeMargin{
    margin-bottom: 15px;
    margin-left: 30px;
  }
  .tenantDetailsOuterContainers {
    height: 2390px;
  }
  .modalContentToEditPayment {
    margin-top: 150px;
    width: 99%;
  }
  .separateTenantdetailsSearchInput {
    margin-left: 60px;
    margin-bottom: 5px;
  }

  .tenantDetailsPaymentTableCon {
    margin-top: 100px;
    width: 100%;
    margin-bottom: 20px;
    max-height: 440px;
  }
  .editPayDateContainer .letUseSeparate {
    margin-left: 0px;
  }

  .editPayDateContainer .letUseSeparate1 {
    margin-left: 0px;
  }
  .letUsPutSomeMarginInTenantDetailsPayment {
    margin-top: 100px;
  }
}
