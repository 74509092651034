.materialUIImagePres {
  width: 80vw;
  height: 80vh;
  background: white;
  overflow-y: scroll;
  background: white;
}

.cancelImageListingDet {
  position: absolute;
  z-index: 3;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listingDetNumImageCon {
  position: absolute;
  width: 100px;
  height: 50px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px white solid;
  border-bottom: 1px white solid;
  color: white;
  background: black;
  font: normal normal normal 14px/25px Merriweather;
  box-shadow: 3px 1px 3px 0.5px black;
  margin: 5px;
}

.changeImagePresentation {
  position: absolute;
  left: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pictureMod1Image {
  width: 100%;
  height: 100%;
  background: black;
  opacity: 1;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid white;
  animation: fadeIndownConfirm 0.6s ease-in-out;
}
.listDetLargeImage {
  width: 95%;
  height: 90vh;
  opacity: 1;
  padding: 0 7% 50px 7%;
  flex: 1;
}

.picModalCancelIcon {
  color: #035aa6;
  font-size: 35px;
  opacity: 0.8;
  cursor: pointer;
}
.picModalCancelIcon2 {
  color: white;
  font-size: 35px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 3;
}

.picModalCancelIcon:hover {
  color: #035aa6;
}
.picModalOuterContainer1 {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 5;
  margin-top: 40px;
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  animation: fadeIndownConfirm 0.6s ease-in-out;
}

@keyframes fadeIndownConfirm {
  0% {
    opacity: 0;
    transform: translateY(-100vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.otherListTitle {
  color: #707070;
  text-align: center;
  margin-top: 20px;
}
.otherListTitle h3 {
  font: normal normal bold 18px/25px Merriweather;
}

.picModalInnerCon1 {
  height: 80%;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  left: 5px;
}
.picModalInnerCon2 {
  height: 80%;
  display: flex;
  align-items: center;
  margin-left: 5px;
  position: absolute;
  z-index: 2;
  right: 5px;
}

.picModalInnerCon2 .icon,
.picModalInnerCon1 .icon {
  color: white;
  font-size: 70px;
  opacity: 0.8;
  margin-right: 7px;
  cursor: pointer;
}

.picModalInnerCon1 .icon:hover {
  color: #035aa6;
}

.otherCityDisplayListing {
  width: 49%;
  margin: 0 0.5%;
  height: 350px;
}

.otherListMapListContainer {
  width: 100%;
  height: 600px;
  border: 1px solid #707070;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
}

.otherListMapContainer {
  width: 100%;
  height: 250px;
}

.otherListSeparator {
  height: 1px;
  margin: 15px 5%;
  width: 90%;
}

.title_locationCon1 .card-title2 {
  font: normal normal bold 13px/25px Merriweather;
  letter-spacing: 1.13px;
  color: #222222;
}

.title_locationCon1 .card-text {
  font: normal normal normal 11px/20px Merriweather;
  margin-top: 10px;
  letter-spacing: 0.72px;
  color: #222222;
}
.card-body .title_locationCon1 {
  height: 100%;
  flex: 1;
  padding: 0 5px;
}
.cardBodyContainerForSearchList {
  padding: 10px 10px 5px 10px;
}

.price_posted .card-title2 {
  font: normal normal bold 13px/25px Merriweather;
  letter-spacing: 1.13px;
  color: #222222;
}
.price_posted .card-text {
  font: normal normal normal 11px/20px Merriweather;
}

.otherListDisplayList {
  width: 100%;
  height: 290px;
  padding: 0 15px;
}

.otherListDisplayListSmall {
  width: 100%;
  height: 400px;
  padding: 0 15px;
  visibility: hidden;
  position: absolute;
}

.mapSectionListContainer .searchListingCarouselImages1 {
  width: 100%;
  height: 200px;
}
.mapSectionListContainer .searchListingCarouselImages1:hover {
  opacity: 0.5;
}

.windowContainerForMap1 .imageContainer2 {
  height: 180px;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
}
.windowContainerForMap1 {
  width: 200px;
  height: 250px;
  /* display: flex;
  flex-direction: column; */
}
.imageContainer2 .theImageForMapsWindow2 {
  width: 100%;
  height: 100%;
}
.containerForTitleBedroomPriceInMap1 h5 {
  text-align: center;
  font: normal normal bold 12px/19px Merriweather;
  line-height: 0.5;
  margin-top: 0;
}
.containerForTitleBedroomPriceInMap1 .makeTopInCon {
  margin-top: 15px;
}
.containerForTitleBedroomPriceInMap1 p {
  text-align: center;
  font: normal normal normal 10px/19px Merriweather;
  line-height: 0.5;
  margin-bottom: 10px;
}
.myStylingForLeaseSignInDetail {
  color: #707070;
  font: normal normal normal 14px/25px Merriweather;
}

.emtyHeartStyle {
  color: #035aa6;
  cursor: pointer;
  /* margin-left: 5px; */
  font-size: 20px;
}

.listingDetailNoMapAvailable {
  width: 100%;
  text-align: center;
  margin-top: 100px;
}

.carouselImagesCon {
  height: 100%;
  width: 100%;
}
.noImageListingDetCon {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #707070;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noImageListingDetCon .userIcon {
  font-size: 30px;
  color: #707070;
}

/* styling for ListingDetails Screen */
.listingDetailsPrevBtn {
  width: 60px;
  height: 150px;
  background-color: #035aa6;
  top: 40%;
  opacity: 0.5;
}

.DetailsUserIcon {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
  fill: #035aa6;
}

.listingDetailsOuterCon {
  display: flex;
  flex-direction: row;
  min-height: 1450px;
  margin-top: 50px;
}

.listingDetailsOuterCon .infoContainer {
  flex: 1;
  padding: 20px 50px;
  box-shadow: 2px 5px 7px black;
  margin-right: 15px;
}

.mapSeparator {
  width: 30%;
  margin: 40px auto 20px auto;
}

.listingDetailsCarouselCon {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  padding-right: 7px;
}

.reportListingCon {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.reportListingCon .topHr {
  width: 15%;
  border: 1px solid #e1e1e1;
  margin-top: 15px;
}

.reportListingCon .reportListingPara {
  position: absolute;
  margin-top: 10px;
}

.reportListingCon .bottomHr {
  position: absolute;
  margin-top: 30px;
  width: 15%;
  border: 1px solid #e1e1e1;
}

/* styling from address component */

.addressOuterContainer {
  width: 100%;
  margin-top: 20px;
  letter-spacing: 0px;
  line-height: 4px;
  color: #707070;
  font: normal normal normal 20px/28px Segoe UI;
}

/* styling for ApplyButton Component */

.applyBtnOuterCon {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.applyBtnOuterCon1 {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.applyBtnOuterCon button {
  width: 90%;
  padding: 4px;
  color: #fcfdff;
  font: normal normal bold 14px/20px Merriweather;
  background-color: #035aa6;
  border-radius: 30px;
  border: solid 1px #035aa6;
  box-shadow: 1px 1px 1px #d3d3d3;
}

.applyBtnOuterCon button:hover {
  background-color: white;
  color: #035aa6;
}
.applyBtnOuterCon1 button {
  width: 90%;
  padding: 4px;
  color: #035aa6;
  font: normal normal bold 14px/20px Merriweather;
  background-color: #fcfdff;
  border: 1px solid #035aa6;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #d3d3d3;
}
.applyBtnOuterCon1 button:hover {
  background-color: #035aa6;
  color: white;
}

/* styling for carousel */
.listingDetailsChevron {
  width: 80px;
  height: 80px;
}

.carouselOuterCon {
  width: 100%;
  margin-top: 10px;
  height: 100%;
  position: relative;
}

.carouselingTheImage {
  width: 49%;
  height: 250px;
  overflow: hidden;
  padding: 5px;
}

.listingDetailsCarouselImage {
  width: 100%;
  height: 100%;
}

.carouselingTheImage a {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.leaseTypeOuterCon b {
  color: #707070;
  font: normal normal normal 18px/25px Merriweather;
}
.leaseTypeOuterCon {
  margin-top: 20px;
}

.fixedLeaseConditionCon {
  margin-left: 25px;
}

/* styling for Description Component */

.propDetDescriptionCon {
  margin-top: 20px;
}

.propDetDescriptionCon h6 {
  color: #707070;
  font: normal normal normal 18px/25px Merriweather;
}

.propDetDescriptionCon .card {
  width: 90%;
  font: normal normal 300 14px/19px Merriweather;
  color: #707070;
  padding: 20px;
  margin-top: 20px;
  /* height: 120px; */
  opacity: 0.8;
  box-shadow: inset 3px 3px 3px #00000029, 1px 3px 3px #00000029;
}

/* styling for LocationInMap container */

.geolocationMapCon {
  margin-top: 20px;
}
.geolocationMapCon h6 {
  color: #707070;
  font: normal normal normal 18px/25px Merriweather;
}

.geolocationMapCon .imageCon {
  width: 95%;
  height: 350px;
  font: normal normal 300 14px/19px Merriweather;
  color: #707070;
  /* padding: 20px; */
  margin-top: 5px;
  opacity: 0.8;
  overflow: hidden;
  box-shadow: 2px 5px 7px grey;
}

.imageCon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* styling for MoreAboutListing Component */

.moreAboutListCon {
  width: 100%;
}

.rentOrSellSpan {
  color: #222222;
  font: normal normal bold 16px/19px Merriweather;
  line-height: 1px;
}

.bedroomSpan {
  color: #707070;
  font: normal normal normal 14px/18px Merriweather;
}

.kitchenSpan {
  color: #707070;
  font: normal normal normal 14px/18px Merriweather;
}

.availableSpaceSpan {
  color: #035aa6;
  font: normal normal normal 14px/18px Merriweather;
}
.availableSpaceSpan1 {
  color: #707070;
  font: normal normal normal 14px/18px Merriweather;
}

.advancePaySpan {
  color: #707070;
  font: normal normal normal 13px/16px Merriweather;
  line-height: 1px;
}

/* styling for OwnerOtherDetail Component*/

.ownerOtherDetCon {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  width: 100%;
  height: 120px;
  padding-top: 10px;
  border-radius: 5px;
}

.ownerOtherDetCon .listedByCon {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.listedByParaTop {
  color: #707070;
  font: normal normal normal 12px/16px Merriweather;
}

.listedByCon img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.usernamePara {
  color: #707070;
  margin-top: 5px;
  font: normal normal bold 12px/16px Merriweather;
  text-transform: capitalize;
}

.listedSinceCon {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.listedSinceParaTop {
  color: #704040;
  font: normal normal normal 12px/16px Merriweather;
}

.timePara {
  color: #707070;
  margin-top: 5px;
  font: normal normal bold 12px/16px Merriweather;
}

.applRecievedCon {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.applRecievePara {
  color: #707070;
  font: normal normal normal 12px/16px Merriweather;
  text-align: center;
}

.numOfAppPara {
  color: #707070;
  margin-top: 5px;
  font: normal normal bold 12px/16px Merriweather;
}

.likersCon {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.likesAndHeatsPara {
  color: #707070;
  font: normal normal normal 12px/16px Merriweather;
  display: flex;
  flex-direction: row;
}
.share {
  width: 400px;
  background: #707070;
  padding: 0 20px;
  height: 70px;
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 100px;
  right: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  animation: fadeInUp 0.5s ease-in-out 0.4s;
  animation-fill-mode: both;
}
.shareContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fullHeartClass {
  /* margin-left: 5px; */
  color: #035aa6;
  cursor: pointer;
  font-size: 20px;
}
.emptyHeartStyle {
  /* margin-left: 5px; */
  color: #035aa6;
  border: 1px solid #035aa6;
}

.numOfLikesPaara {
  color: #707070;
  margin-left: 7px;
  margin-top: 5px;
  font: normal normal bold 12px/16px Merriweather;
}

/* styling for PictureModal component */
.pictureModalImg {
  width: 100%;
  height: 610px;
}

/* styling for TitlePrice component */
.listingDetailTitleCon {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.listingDetailTitleCon1 {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.listingDetailTitleCon .titleSpan {
  color: #707070;
  letter-spacing: 1.57px;
  margin-left: 5px;
  font: normal normal bold 25px/37px Merriweather;
}
.listingDetailTitleCon1 .titleSpan {
  color: #707070;
  letter-spacing: 1.57px;
  margin-left: 5px;
  font: normal normal bold 25px/37px Merriweather;
}

.pricePerMonthSpan {
  position: absolute;
  right: 55%;
  padding: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  letter-spacing: 1.22px;
  animation: animePrice 1s infinite;
}
.pricePerMonthSpan1 {
  padding: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  letter-spacing: 1.22px;
  animation: animePrice 1s infinite;
  height: 40px;
}

@keyframes animePrice {
  from {
    border: 2px solid #035aa6;
  }
  to {
    border: 2px solid #ffa500;
  }
}

.pricePerMonthSpan .priceSpan {
  font: normal normal 900 22px/25px Merriweather;
}
.pricePerMonthSpan1 .priceSpan {
  font: normal normal 900 16px/25px Merriweather;
}

.pricePerMonthSpan .monthSpan {
  font: normal normal 900 12px/17px Merriweather;
}

.pricePerMonthSpan1 .monthSpan {
  font: normal normal 900 12px/17px Merriweather;
}

.pictureModalContent {
  width: auto;
  margin-top: 50px;
  height: auto;
  left: -20px;
}

#carouselExampleControlsBig .carousel-inner {
  height: auto;
}
.bigImage {
  width: 100%;
  height: auto;
  margin: auto;
  object-fit: cover;
}

.listingDetailSpinnerContainer {
  width: 100%;
  margin: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spamClass {
  width: 15px;
  height: 15px;
  fill: #035aa6;
}

.spamPara {
  color: #035aa6;
  margin-left: 5px;
  font: normal normal 300 12px/14px Merriweather;
}

.reportCollapseTextarea {
  width: 50%;
  margin: 0 auto;
}

.reportCollapseTextarea button {
  margin: 20px auto 0px auto;
  background-color: #707070;
  color: white;
  cursor: pointer;
  border: #707070 solid 1px;
  font: normal normal 300 16px/14px Merriweather;
  padding: 5px 10px;
  border-radius: 10px;
}

.reportCollapseTextarea button:hover {
  background-color: white;
  color: #707070;
}

@media (max-width: 991px) {
  .materialUIImagePres {
    width: 100vw;
    height: 100vh;
    background: white;
    overflow-y: scroll;
    background: white;
    margin-left: -12px;
  }
  .listingDetNumImageCon {
    position: absolute;
    width: 100px;
    height: 50px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px white solid;
    color: white;
    background: black;
    font: normal normal normal 14px/25px Merriweather;
    box-shadow: 1px 1px 1px 0.5px black;
    margin-left: 40%;
  }
  .changeImagePresentation {
    margin-top: 50px;
  }
  .cancelImageListingDet {
    position: absolute;
    z-index: 3;
    right: 15px;
    top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .listDetLargeImage {
    width: 98%;
    height: 90vh;
    opacity: 1;
    padding: 0 0 5px 0;
    flex: 1;
  }
  .picModalOuterContainer1 {
    margin-top: 20px;
    padding-top: 20px;
  }
  .fixedLeaseConditionCon {
    margin-left: 15px;
  }
  .ownerOtherDetCon {
    flex-wrap: wrap;
    height: 250px;
  }
  .ownerOtherDetCon .listedByCon {
    width: 30%;
    flex: none;
  }
  .listedSinceCon {
    width: 30%;
    flex: none;
  }
  .applRecievedCon {
    flex: none;
    width: 30%;
  }
  .picModalInnerCon1 .icon {
    color: white;
    font-size: 30px;
    opacity: 0.8;
    cursor: pointer;
  }
  .picModalInnerCon2 .icon {
    color: white;
    font-size: 30px;
    opacity: 0.8;
    cursor: pointer;
  }
  .picModalInnerCon1 {
    height: 98%;
    display: flex;
    align-items: center;
    margin-left: 0px;
  }
  .picModalInnerCon2 {
    height: 98%;
    display: flex;
    align-items: center;
    margin-left: 0px;
  }

  .share {
    width: 99%;
    background: #707070;
    padding: 0 20px;
    height: 70px;
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 100px;
    right: 0%;
    transform: translateX(-50%);
    border-radius: 10px;
    animation: fadeInUp 0.5s ease-in-out 0.4s;
    animation-fill-mode: both;
  }
  .otherCityDisplayListing {
    width: 100%;
  }
  .otherListMapListContainer {
    height: 950px;
  }
  .otherListSeparator {
    margin-top: 300px;
  }
  .otherListDisplayList {
    position: absolute;
    visibility: hidden;
  }
  .otherListDisplayListSmall {
    visibility: visible;
    position: relative;
  }
  .pictureModalContent {
    width: 98%;
    margin: 50px auto;
    height: auto;
    left: 0;
  }

  .reportCollapseTextarea {
    width: 98%;
  }

  .propDetDescriptionCon .card {
    width: 98%;
    margin-left: 1%;
  }

  #carouselExampleControlsBig .carousel-inner {
    height: auto;
  }
  #exampleModalLong {
    width: 98%;
    height: 600px;
  }
  .bigImage {
    width: 100%;
    height: 580px;
  }
  .listingDetailsOuterCon {
    display: flex;
    flex-direction: column;
    min-height: 3100px;
  }

  .listingDetailsOuterCon .infoContainer {
    flex: 1;
    padding: 5px 5px;
    margin-right: 0;
  }
  .listingDetailsCarouselCon {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 0;
  }
  .geolocationMapCon .imageCon {
    width: 100%;
    font: normal normal 300 14px/19px Merriweather;
    color: #707070;
    padding: 5px;
    margin-top: 5px;
    height: 70vh;
    opacity: 0.8;
    overflow: hidden;
    box-shadow: none;
  }

  .imageCon img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .listingDetailTitleCon1,
  .listingDetailTitleCon {
    width: 100%;
    flex-direction: column;
  }

  .pricePerMonthSpan {
    right: 10px;
    margin-top: 50px;
    position: relative;
    margin-left: 12px;
  }

  .pricePerMonthSpan .priceSpan {
    font: normal normal 900 16px/25px Merriweather;
  }
  .priceMonthContainer {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .leaseStartingAndEndingBox .startDateContainer {
    padding-left: 10px;
    padding-top: 20px;
  }
  .pricePerMonthSpanLease {
    position: relative;
  }
  .carouselingTheImage {
    width: 49%;
    height: 250px;
    overflow: hidden;
  }
}

@media (max-height: 1366px) and (min-height: 1024px) {
  .listingDetailsOuterCon {
    display: flex;
    flex-direction: column;
    min-height: 2200px;
    margin-top: 0px;
  }
}
