.HeadBlogDetCon{
width: 75%;
height: 70vh;
margin: 0 auto;
padding-top: 50px
}

.blogDetBackBtn{
position: absolute;
font-weight: bold;
margin-left: -10%;
width: 150px;
height: 50px
}

.blogDetBackBtn a{
width: 100%;
height: 100%;
font-weight: bold;
background-color: #035aa6;
color: white;
display: flex;
justify-content: center;
align-items: center
}
.blogDetBackBtn a:hover{
  background-color:white ;
  border: 1px solid #035aa6;
  color: #035aa6;
}
.blogDetBackBtn a .icon{
font-size: 30px;
margin-right: 7px
}

.blogDetHeadTitleCon{
position: absolute;
width: 25%;
background: white;
padding: 20px 5px;
margin-left: -10%;
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
margin-top: 50px
}

.blogDetHeadTitleCon h1{
color: #035aa6;
font-weight: bolder;
font-family: 'DM Serif Display', serif;
text-transform: uppercase
}

.blogDetHeadTitleCon p{
margin-top: 10px
}
.blogDetHeadTitleCon .second{
margin-left: 5px;
color: #035aa6;
font-weight: bold;
text-transform: uppercase
}
.blogDetHeadTitleCon .third{
margin-left: 5px
}
.blogDetHeadTitleCon .fourth{
margin-left: 5px;
 color: #035aa6;
  font-weight: bold
}

.blogDetTrashIcon{
color: red;
opacity: 0.8;
font-size: 16px;
margin-left: 7px;
cursor: pointer
}

.blogDetBodyCon{
width: 75%;
margin: 0 auto;
display: flex
}
.blogDetBodyInnerCon{
width: 95%;
padding: 20px;                 
border-left: 1px solid #035aa6;
border-right: 1px solid #035aa6;
margin-bottom: 50px
}

.blogDetBodyInnerCon h4{
text-transform: capitalize;
text-align: center;
margin: 30px auto 0px auto;
color: #035aa6
}

.blogDetBodyInnerCon b{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center
}
.iconssss{
color: #035aa6;
font-size: 16px;
margin-bottom: 20px;           
}

.blogDetBodyCatergory{
margin-top: 0px;
text-align: center;
color: #ffa500
}

.blogDetBodyP{
font-size: 15px;
line-height: 1.8;
text-indent: 50px;
white-space: pre-wrap
}

.blogDetBodyRecentCon{
width: 20%;
padding: 50px 30px 50px 10px;
}

.blogDetBodyRecentCon h5{
text-align: center;
 font-weight: bold;
 color: #ffa500;
 font-size: 14px;
}

@media (max-width: 991px) {
  .HeadBlogDetCon{
    width: 90%;
    padding-top: 150px
    }
    .HeadBlogDetCon img{
      width: 90%;
      padding-top: 70px
      }
    .blogDetBackBtn{
      position: absolute;
      font-weight: bold;
      margin-left: 0%;
      margin-top: -100px;
      width: 150px;
      height: 50px
      }
      .blogDetHeadTitleCon{
        position: absolute;
        width: 98%;
        background: white;
        padding: 5px 5px;
        margin-left: 0%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-top: -50px
        }
        .blogDetBodyCon{
          width:95%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          }
          
.blogDetBodyInnerCon{width: 95%;}
.blogDetBodyRecentCon{
  width: 100%;
  padding: 50px 10px 50px 10px;
  }
        
}