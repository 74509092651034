.listingAnalyticsContainer {
  width: 50vw;
}
.listingAnalyticsContainer1 {
  width: 80vw;
}
.noListingAvailableYetCon {
  width: 50%;
  margin: 10px auto;
}
.noListingAvailableYetCon .alert {
  text-align: center;
  font: normal normal bold 14px/19px Merriweather;
  background-color: whitesmoke;
}
.visibleForMobile {
  visibility: hidden;
  display: none;
}

.searchButtonOhh {
  text-decoration: none;
}

.selectionForSearchListing {
  cursor: pointer;
}

/* Search component */

.listingSearchCon {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 50px;
}
.listingSearchCon1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 50px;
}

.listingSearchCon .addlistCons {
  flex: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.listingSearchCon1 .addlistCons {
  flex: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listingSearchCon .inputFieldCon {
  flex: 2;
}
.listingSearchCon1 .inputFieldCon {
  flex: 2;
}

.listingSearchCon a {
  font: normal normal bold 13px/20px Merriweather;
  border-radius: 5px;
  background: #035aa6 0% 0% no-repeat padding-box;
  padding: 5px 30px;
  color: #ffffff;
  letter-spacing: 1.6px;
}
.listingSearchCon1 a {
  font: normal normal bold 13px/20px Merriweather;
  border-radius: 5px;
  background: #035aa6 0% 0% no-repeat padding-box;
  padding: 5px 30px;
  color: #ffffff;
  letter-spacing: 1.6px;
}

.listingSearchCon a:hover {
  background: white;
  color: #035aa6;
  border: 1px solid #035aa6;
}
.listingSearchCon1 a:hover {
  background: white;
  color: #035aa6;
  border: 1px solid #035aa6;
}

.myListingSearchInput {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 10px;
  color: #bcbcbc;
  letter-spacing: 0.68px;
  font: normal normal bold 12px/16px Merriweather;
}
.listingSortDropdown {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.listingSortDropdown .sortedBySpan {
  color: #222222;
  letter-spacing: 0.68px;
  font: normal normal bold 12px/16px Merriweather;
  margin-left: 5px;
}

.listingSortDropdown .listingSortSelectSpan {
  margin-left: 2px;
}

.listingSortDropdown select {
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #222222;
  padding: 2px 2px;
  letter-spacing: 0.68px;
  font: normal normal bold 12px/17px Merriweather;
}

/* Table component */
.myListTableCon {
  margin-top: 20px;
  width: 100%;
  height: 460px;
}

.myListThead {
  border-radius: 5px 5px 0px 0px;
  background: #035aa6 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 0.5;
}

.table-column:hover {
  background-color: grey;
}

.myListTh {
  opacity: 1;
  color: white;
  text-align: center;
  letter-spacing: 0.9px;
  font: normal normal bold 14px/21px Merriweather;
}

.myListTh1 {
  opacity: 1;
  color: white;
  text-align: center;
  letter-spacing: 0.9px;
  font: normal normal bold 14px/21px Merriweather;
}

.myListTd {
  text-align: center;
  color: #222222;
  letter-spacing: 0.9px;
  font: normal normal bold 14px/19px Merriweather;
}
.myListTd .theListingLink:hover {
  color: #035aa6;
  font: normal normal bold 14px/19px Merriweather;
}
.myListTd .applicationTableAtag {
  text-decoration: none;
  cursor: pointer;
}
.myListTd1 {
  text-align: center;
  color: #035aa6;
  letter-spacing: 0.9px;
  font: normal normal bold 14px/19px Merriweather;
}

.myListTd a {
  color: #222222;
  letter-spacing: 0.9px;
  font: normal normal bold 14px/19px Merriweather;
}

.myListTd1 a {
  color: #035aa6;
  letter-spacing: 0.9px;
  font: normal normal bold 14px/19px Merriweather;
}

.unpublishEditDeleteBtn {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.unpublishEditDeleteBtn .publishBtn {
  flex: 1;
  border-radius: 5px;
  border: #035aa6 solid 1px;
  color: #035aa6;
  letter-spacing: 0.68px;
  background: white 0% 0% no-repeat padding-box;
  padding: 7px 7px;
  font: normal normal bold 12px/16px Merriweather;
  cursor: pointer;
}

.unpublishEditDeleteBtn .publishBtn:hover {
  background: #035aa6;
  color: white;
}

.unpublishEditDeleteBtn .unpublishBtn {
  flex: 1;
  border-radius: 5px;
  border: #035aa6 solid 1px;
  color: white;
  letter-spacing: 0.68px;
  background: #035aa6 0% 0% no-repeat padding-box;
  padding: 7px 7px;
  font: normal normal bold 12px/16px Merriweather;
  cursor: pointer;
}

.unpublishEditDeleteBtn .unpublishBtn:hover {
  background: white;
  color: #035aa6;
}

.unpublishEditDeleteBtn .myEditBtn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
}

.unpublishEditDeleteBtn .myEditBtn a {
  text-decoration: none;
}

/* .myListPublishBtn .publishBtn {

} */
/* .myListPublishBtn button {
  border-radius: 5px;
  color: #ffffff;
  border: 1px solid #035aa6;
  letter-spacing: 0.68px;
  background: #035aa6 0% 0% no-repeat padding-box;
  padding: 7px 7px;
  font: normal normal bold 12px/16px Merriweather;
  cursor: pointer;
} */

.myListDeleteBtn {
  text-align: center;
  display: flex;
  flex-direction: row;
}

.myListDeleteBtn button {
  border-radius: 5px;
  border: 1px solid #ff4141;
  background: #ff4141 0% 0% no-repeat padding-box;
  color: #ffffff;
  letter-spacing: 0.68px;
  padding: 7px 15px;
  font: normal normal bold 12px/16px Merriweather;
  cursor: pointer;
}

.myListDeleteBtn button:hover {
  background: white;
  border: 1px solid #ff4141;
  color: #ff4141;
}

.myEditBtn a {
  border-radius: 5px;
  padding: 9px 25px;
  border: 1px solid #24842e;
  background: #24842e 0% 0% no-repeat padding-box;
  color: #ffffff;
  letter-spacing: 0.68px;
  font: normal normal bold 12px/16px Merriweather;
}

.myEditBtn a:hover {
  background: white;
  color: #24842e;
  border: 1px solid #24842e;
}

/* TitleListing component */
.myListTitle {
  width: 100%;
  text-align: center;
}

.myListTitle h6 {
  color: #222222;
  letter-spacing: 0.9px;
  font: normal normal bold 16px/22px Merriweather;
}

/* ListingScreen component */
.myListingOuterContainer {
  width: 100%;
  margin-top: 47px;
}

.myListingOuterContainer .myListingTableCon {
  width: 84%;
  margin-left: 16%;
}

.myListingOuterContainer .myListingTableCon1 {
  width: 91%;
  margin-left: 6%;
}

@media (max-width: 991px) {
  .listingAnalyticsContainer {
    width: 95vw;
  }
  .listingAnalyticsContainer1 {
    width: 95vw;
  }
  .unpublishEditDeleteBtn .myEditBtn {
    margin: 0 5px;
  }
  .someMarginInListingSearchInput {
    margin-left: 70px;
  }
  .visibleForLargeScreen {
    visibility: hidden;
    position: absolute;
    display: none;
    height: 1px;
    background: red;
  }
  .visibleForMobile {
    visibility: visible;
    display: block;
    position: relative;
  }
  .listingSortDropdown {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .myListingOuterContainer {
    height: 1000px;
  }
  .myListingOuterContainer .myListingTableCon {
    width: 98%;
    margin-left: 1%;
  }
  .myListingOuterContainer .myListingTableCon1 {
    width: 98%;
    margin-left: 1%;
  }
  .myListTableCon {
    width: 100%;
    overflow-x: scroll;
  }

  .myListTableCon table {
    width: 60px;
  }
  .listingSearchCon {
    flex-direction: column;
    height: 140px;
    padding: 0 5px;
    /* background: red; */
  }
  .listingSearchCon1 {
    flex-direction: column;
    height: 170px;
    padding: 0 5px;
  }
  .listingSearchCon a {
    font: normal normal bold 10px/20px Merriweather;
    padding: 5px 10px;
  }
  .listingSearchCon1 a {
    font: normal normal bold 10px/20px Merriweather;
    padding: 5px 10px;
  }
  .listingSearchCon .addlistCons {
    padding: 20px 1px;
    flex: 1;
    /* background: red; */
  }
  .listingSearchCon1 .addlistCons {
    padding: 20px;
    flex: 1;
  }

  .listingSortDropdown {
    flex: 1;
  }
  .listingSortDropdown .sortedBySpan {
    margin-left: 2px;
  }
  .listingSearchCon .inputFieldCon {
    flex: 1.5;
    padding-bottom: 10px;
  }

  .listingSearchCon1 .inputFieldCon {
    flex: 1.5;
    padding-bottom: 10px;
  }

  .myListingSearchInput {
    width: 90%;
  }
}
