@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
/* styling for ModalProfile component */
.profileModalContainer {
  border: 10px;
}

.profileModalContainer .profileModalHeader {
  font: normal normal normal 16px/24px Merriweather;
}

.letMeGiveSomeSeparationOverAccountable {
  width: 100%;
  height: 60px;
}

.container .wrapper {
  position: relative;
  height: 200px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  border: 2px dashed #c2cdda;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.wrapper.active {
  border: none;
}
.wrapper .image {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.wrapper .icon {
  font-size: 30px;
  margin-left: 50px;
  color: #035aa6;
}
.wrapper .text {
  font: normal normal normal 15px/24px Merriweather;
  font-weight: 500;
  margin: 0 auto;
  color: #5b5b7b;
}

.contactInputContactInnerContainer {
  display: flex;
  flex-direction: row;
}
.contactInputInputContainer {
  width: 55%;
}

.contactInputInputContainer input {
  padding: 6px;
  opacity: 1;
  border-radius: 5px;
  font: normal normal normal 15px/24px Merriweather;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  color: #035aa6;
  letter-spacing: 0.68px;
}

#contactUser {
  padding: 6px;
  cursor: pointer;
  opacity: 1;
  border-radius: 5px;
  font: normal normal normal 15px/24px Merriweather;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  color: #035aa6;
  letter-spacing: 0.68px;
  width: 120px;
}

.wrapper #cancel-btn i {
  position: absolute;
  font-size: 20px;
  right: 15px;
  top: 15px;
  color: #9658fe;
  cursor: pointer;
  display: none;
}
.wrapper.active:hover #cancel-btn i {
  display: block;
}
.wrapper #cancel-btn i:hover {
  color: #e74c3c;
}
.wrapper .file-name {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 8px 0;
  font-size: 18px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(135deg, #035aa6 0%, white 100%);
}
.wrapper.active:hover .file-name {
  display: block;
}
.container #custom-btn {
  margin-top: 30px;
  display: block;
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 25px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  background: linear-gradient(135deg, white 0%, #035aa6 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}


.container #custom-btn:hover{
  background: white;
  color: #035aa6;
  border: #035aa6 solid 1px ;
}

input[id='fileProfile'] {
  display: none;
}

.profileImageUploadSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImageErrorCon {
  text-align: center;
  width: 98%;
  margin: 5px auto;
}

.profileImageModalBtn {
  width: 90%;
  border-radius: 5px;
  margin: 0 auto;
  background-color: #035aa6;
  border: #035aa6 solid 1px;
  color: white;
  font: normal normal normal 15px/26px Merriweather;
}
.profileImageModalBtn:hover{
  background-color: white;
  color: #035aa6;
}

/* styling for ProfileSCreen */
.professionInput,
.profileFNInput,
.profileMNInput,
.profileLNInput,
.profileEmailInput,
.profileContactInput,
.profilePasspordInput,
.profileSAInput,
.profileCityInput,
.profileCountryInput {
  opacity: 1;
  border-radius: 5px;
  font: normal normal normal 15px/24px Merriweather;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  width: 80%;
  color: #035aa6;
  letter-spacing: 0.68px;
}

.profileStateInput,
.profileZipCodeInput {
  opacity: 1;
  border-radius: 5px;
  font: normal normal normal 15px/24px Merriweather;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  width: 62%;
  color: #035aa6;
  letter-spacing: 0.68px;
}

.profileOuterCon {
  opacity: 1;
  margin: 90px auto;
  /* box-shadow: inset 3px 3px 6px #00000029, 3px 3px 6px #00000029; */
  background: #fcfdff 0% 0% no-repeat padding-box;
  height: 1650px;
  width: 60%;
    box-shadow: 2px 5px 7px black;
}

.profileheaderTitleCon {
  opacity: 0.8;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #707070;
  background: #035aa6 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-707070);
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.profileheaderTitleCon h5 {
  letter-spacing: var(--unnamed-character-spacing-1-13);
  font: normal normal bold 20px/27px Merriweather;
  text-align: center;
  letter-spacing: 1.13px;
  color: black;
  opacity: 1;
}

.nameContactTitle {
  margin-top: 20px;
  width: 100%;
  height: 25px;
}

.nameContactTitle .name {
  opacity: 1;
  position: absolute;
  margin-left: 25%;
  color: #222222;
  letter-spacing: 0.58px;
  font: normal normal bold 13px/17px Merriweather;
}

.nameContactTitle .btnSpan {
  right: 25%;
  position: absolute;
}

.nameContactCon {
  width: 100%;
  margin-top: 20px;
  height: 500px;
  display: flex;
  flex-direction: row;
}
.profileOuterCon .nameContactCon1{
  width: 100%;
  margin-top: 20px;
  height: 400px;
  display: flex;
  flex-direction: row;
  background: red;
}
.profileOuterCon .nameContactCon2{
  width: 100%;
  margin-top: 20px;
  height: 300px;
  display: flex;
  flex-direction: row;
  padding-left: 50px;

}

.nameContactCon .nameContactInput {
  flex: 2;
  height: 100%;
  padding: 50px;
}

.nameContactCon1 .nameContactInput {
  flex: 2;
  height: 100%;
  padding: 50px;
}

.nameContactCon2 .nameContactInput {
  flex: 2;
  height: 100%;
  padding: 50px;
}

.nameContactInput .alert {
  text-align: center;
  width: 80%;
  margin: 10px auto;
}

.profileImageCon {
  flex: 1;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileOuterCon .nameContactHr {
  width: 87%;
  margin: 100px auto 20px auto;
}

.addressTitleCon {
  margin-top: 30px;
  height: 20px;
  width: 100%;
}

.addressTitleCon .addressSpan {
  opacity: 1;
  position: absolute;
  margin-left: 28%;
  color: #222222;
  letter-spacing: 0.58px;
  font: normal normal bold 13px/17px Merriweather;
}

.addressTitleCon .editBtn {
  right: 25%;
  position: absolute;
}

.addressInputCon {
  width: 100%;
  margin-top: 20px;
  height: 400px;
  display: flex;
  flex-direction: row;
}

.addressInputCon .containInput {
  flex: 2;
  height: 100%;
  padding: 50px;
}

.containInput .alert {
  text-align: center;
  width: 80%;
  margin: 10px auto;
}

.stateZipcodeCon {
  display: flex;
  flex-direction: row;
}

.emptyCon {
  flex: 1;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addressHr {
  width: 87%;
  margin: 0 auto 20px auto;
}

.descriptionTitleCon {
  margin-top: 20px;
  height: 80px;
  width: 100%;
}

.descriptionTitleCon .desSpan {
  opacity: 1;
  margin-left: 26%;
  position: absolute;
  color: #222222;
  letter-spacing: 0.58px;
  font: normal normal bold 13px/17px Merriweather;
}

.descriptionTitleCon .editBtn {
  right: 25%;
  position: absolute;
}

.descriptionTitleCon .paraSpan {
  margin-left: 16%;
  position: absolute;
  margin-top: 20px;
  opacity: 1;
  color: #222222;
  letter-spacing: 0.58px;
  font: normal normal 300 12px/14px Merriweather;
}

.descriptionHr {
  width: 87%;
  margin: 30px auto 20px auto;
}

.deleteContainer {
  text-align: center;
  opacity: 1;
  color: #222222;
  letter-spacing: 0.58px;
  font: normal normal bold 11px/14px Merriweather;
}

.profileTextarea {
  width: 70%;
  margin-left: 15%;
  margin-top: 20px;
  opacity: 1;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #035aa6;
  letter-spacing: 0.68px;
  font: normal normal normal 14px/24px Merriweather;
  text-align: center;
}

.PictureOuterCon {
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background: #bcbcbc 0% 0% no-repeat padding-box;
}
.PictureOuterCon .profileImage {
  width: 150px;
  height: 150px;
  position: absolute;
}

.downloadIcon {
  margin-top: 60px;
  width: 30px;
  height: 30px;
  width: 30px;
  height: 30px;
  fill: #035aa6;
}

.PictureOuterCon p {
  opacity: 1;
  color: #222222;
  letter-spacing: 0.58px;
  font: normal normal normal 10px/15px Merriweather;
}

.PictureOuterCon img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.PictureOuterCon .camaraContainer {
  position: absolute;
  background-color: white;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  margin-top: 125px;
  box-shadow: 2px 5px 7px black;
}

.camaraContainer a {
  cursor: pointer;
}

.camaraIcon {
  width: 20px;
  height: 20px;
  color: #222222;
}

.profileInputOuterCon {
  margin-left: 0%;
  width: 100%;
  z-index: 1;
}

.profileInputOuterCon .passwordLabel {
  font-family: 'Merriweather', serif;
  font-size: 13px;
  color: black;
}
.passwordLabelSaveContainer{
  font-family: 'Merriweather', serif;
  font-size: 13px;
  color: black;
}

.editingBtn {
  opacity: 1;
  border-radius: 5px;
  background: #035aa6 0% 0% no-repeat padding-box;
  color: #ffffff;
  letter-spacing: 0.72px;
  font: normal normal normal 14px/18px Merriweather;
  border: #035aa6 1px solid;
  padding: 3px;
  min-width: 100px;
  cursor: pointer;
}

.editingBtn:hover{
  background: white;
  color: #035aa6;
}

.profileDeleteBtn {
  opacity: 1;
  color: #ffffff;
  letter-spacing: 0.72px;
  font: normal normal bold 12px/20px Merriweather;
  padding: 5px;
  margin-top: 10px;
  width: 100px;
  border-radius: 5px;
  background: #ce7b7b 0% 0% no-repeat padding-box;
  border: #ce7b7b solid 1px;
  cursor: pointer;
}
.profileDeleteBtn:hover{
  background: white;
  color: #ce7b7b;
}

/* ProfileSpinner Component */

.profileSpinnerContainer {
  width: 100px;
  height: 100px;
  color: #035aa6;
}
.updateSpinnerOuterContainer {
  width: 25px;
  height: 25px;
  color: #035aa6;
}

.profileSpinnerOuterContainer {
  min-height: 20vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

/* @keyframes animeSpinner {
  from {
    color: 2px solid #035aa6;
  }
  to {
    color: 2px solid #ffa500;
  }
} */

.profileScreenLoadingSpinner {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 95px;
  align-items: center;
}

@media (max-width: 991px) {
  .profileOuterCon {
    opacity: 1;
    margin: 80px auto;
    box-shadow: inset 3px 3px 6px #00000029, 3px 3px 6px #00000029;
    background: #fcfdff 0% 0% no-repeat padding-box;
    height: 1880px;
    width: 96%;
  }

  /* styling for ProfileSCreen*/

  .profileFNInput,
  .profileMNInput,
  .profileLNInput,
  .profileEmailInput,
  .profileContactInput,
  .profilePasspordInput,
  .profileSAInput,
  .profileCityInput,
  .profileCountryInput {
    opacity: 1;
    border-radius: 5px;
    font: normal normal normal 15px/24px Merriweather;
    color: #035aa6;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    width: 100%;
    letter-spacing: 0.68px;
  }

  .profileStateInput,
  .profileZipCodeInput {
    opacity: 1;
    border-radius: 5px;
    font: normal normal normal 15px/24px Merriweather;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    width: 80%;
    color: #035aa6;
    letter-spacing: 0.68px;
  }

  .profileOuterCon {
    opacity: 1;
    margin: 80px auto;
    box-shadow: inset 3px 3px 6px #00000029, 3px 3px 6px #00000029;
    background: #fcfdff 0% 0% no-repeat padding-box;
    height: 1550px;
    width: 98%;
  }

  .nameContactTitle .name {
    opacity: 1;
    margin-left: 5%;
    color: #222222;
    letter-spacing: 0.58px;
    font: normal normal bold 13px/17px Merriweather;
  }

  .nameContactTitle .btnSpan {
    right: 5%;
  }

  .nameContactTitle .btnSpan {
    margin-left: 15%;
  }

  .nameContactCon {
    width: 100%;
    margin-top: 20px;
    height: 400px;
    display: flex;
    flex-direction: row;
  }
  .nameContactCon1 {
    width: 100%;
    margin-top: 20px;
    height: 400px;
    display: flex;
    flex-direction: row;
  }
  .nameContactCon2 {
    width: 100%;
    margin-top: 20px;
    height: 400px;
    display: flex;
    flex-direction: row;
  }

  .nameContactCon .nameContactInput {
    flex: 2;
    height: 100%;
    padding: 10px 10px;
  }
  .nameContactCon1 .nameContactInput {
    flex: 2;
    height: 100%;
    padding: 10px 10px;
  }
  .nameContactCon2 .nameContactInput {
    flex: 2;
    height: 100%;
    padding: 10px 10px;
  }

  .profileImageCon {
    flex: 1;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .PictureOuterCon {
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background: #bcbcbc 0% 0% no-repeat padding-box;
  }

  .downloadIcon {
    margin-top: 40px;
    width: 20px;
    height: 20px;
  }

  .PictureOuterCon .camaraContainer {
    position: absolute;
    background-color: white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
    margin-top: 130px;
  }

  .camaraIcon {
    width: 15px;
    height: 15px;
    color: #222222;
  }

  .addressTitleCon .addressSpan {
    opacity: 1;
    margin-left: 5%;
    color: #222222;
    letter-spacing: 0.58px;
    font: normal normal bold 13px/17px Merriweather;
  }

  .addressInputCon .containInput {
    flex: 3;
    height: 100%;
    padding: 10px;
  }

  .descriptionTitleCon {
    height: 50px;
  }

  .descriptionTitleCon .desSpan {
    opacity: 1;
    margin-left: 5%;
    color: #222222;
    letter-spacing: 0.58px;
    font: normal normal bold 13px/17px Merriweather;
  }

  .descriptionTitleCon .editBtn {
    right: 5%;
    position: absolute;
  }

  .descriptionTitleCon .paraSpan {
    margin-left: 5%;
    margin-top: 30px;
  }

  .profileTextarea {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    opacity: 1;
    border-radius: 5px;
    background: #f5f5f5 0% 0% no-repeat padding-box;
  }

  .profileInputOuterCon {
    margin-left: 0%;
    width: 100%;
    z-index: 1;
  }

  /* .profileInputOuterCon  .passwordLabel {
    font-family: 'Merriweather', serif;
    font-size: 13px;
    color: black;
  } */

  /* .editingBtn {
    opacity: 1;
    border-radius: 5px;
    background: #035aa6 0% 0% no-repeat padding-box;
    color: #ffffff;
    letter-spacing: 0.72px;
    font: normal normal normal 14px/18px Merriweather;
    padding: 3px;
    width: 100px;
  } */

  /* .profileDeleteBtn {
    opacity: 1;
    color: #ffffff;
    letter-spacing: 0.72px;
    font: normal normal bold 12px/20px Merriweather;
    padding: 5px;
    margin-top: 10px;
    width: 100px;
    border-radius: 5px;
    background: #f8b7b7 0% 0% no-repeat padding-box;
  }  */
}
