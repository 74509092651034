.allLeaseTypesAgreementP {
  margin-top: 150px;
}
.myFixedLeaseCons {
  display: inline;
}
.renewMyFixedAgreement {
  margin-left: 50px;
}
.centerTheAnd {
  margin-left: 42%;
  font-size: 20px;
  font-family: 'Lobster', cursive;
}
.modal-heade1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: black;
}
.agreementSignOuterContainer1 {
  width: 600px;
  height: 1100px;
  font-size: 12px;
  margin: auto;
  /* background: #f4f3f3; */
  padding: 0px;
}

.agreementSignOuterContainer .modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.agreementTitleHeader1 {
  font: normal normal bold 18px/19px Merriweather;
  text-align: center;
  margin-top: 10px;
}

.agreementSignOuterContainer {
  background-color: #f7f6fa;
}

.agreementInnerContainer1 {
  padding: 2px 15px;
  width: 98%;
  height: 98%;
  margin: auto;
  background-color: white;
}

.agreementInnerHeader1 {
  margin-left: 10px;
  font: normal normal bold 25px/19px Merriweather;
  font-family: 'Lobster', cursive;
  margin-bottom: 50px;
}
.agreementInnerHeader {
  font-size: 18px;
  font-family: 'Lobster', cursive;
  margin-bottom: 50px;
}
.agreementInnerHeader1 span {
  font: normal normal bold 25px/19px Merriweather;
}

.agreementPartyContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.logoContainer {
  margin: 50px auto;
  width: 100%;
}

.logoContainer img {
  width: 40px;
  height: 40px;
  margin: 0 42%;
}

.agreementDateMonthCon {
  width: 120px;
  display: inline-block;
}

.agreementDateYearsCon {
  width: 100px;
  display: inline-block;
}

.agreementPartyContainer .inputCon1 {
  width: 150px;
}

.agreementPartyContainer .inputCon2 {
  width: 60px;
}

.agreementLandlordInfoCon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementLandlordInfoCon .inputCon1 {
  width: 250px;
}

.agreementLandlordInfoCon .inputCon5,
.agreementLandlordInfoCon .inputCon4,
.agreementLandlordInfoCon .inputCon3,
.agreementLandlordInfoCon .inputCon2 {
  width: 200px;
}

.agreementTenantInfoCon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementTenantInfoCon .inputCon1 {
  width: 250px;
  display: inline-block;
}

.agreementTenantInfoCon .inputCon2 {
  width: 180px;
  display: inline-block;
}

.agreementFixedLease {
  margin-left: 50px;
}

.agreementFixedLease .inputCon1 {
  width: 160px;
  margin-left: 5px;
}

.agreementFixedLease .inputCon4,
.agreementFixedLease .inputCon2 {
  width: 80px;
}
.agreementFixedLease .inputCon3 {
  width: 160px;
  margin-left: 5px;
}
.agreementFixedLease .inputCon6,
.agreementFixedLease .inputCon5 {
  margin-left: 50px;
}

.agreementMonthToMonth {
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementMonthToMonth .inputCon1 {
  width: 13px;
}
.agreementMonthToMonth .inputCon2 {
  width: 200px;
}
.agreementMonthToMonth .inputCon3 {
  width: 60px;
}
.agreementMonthToMonth .inputCon4 {
  width: 80px;
}

.agreementTheProperty {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementCountryStateCityCon {
  /* display: flex; */
  margin-left: 20px;
  /* flex-direction: row;
  flex-wrap: wrap; */
}

.agreementCountryStateCityCon .countryStateCon {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.agreementListCountryCon {
  width: 150px;
  display: inline;
}
.agreementCountryStateCityCon .inputCon1 {
  width: 150px;
  margin-left: 7px;
}
.agreementCountryStateCityCon .stateCon {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.agreementStateInputCon {
  width: 170px;
}

.agreementCityAddressCon {
  display: flex;
  margin-left: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementCityAddressCon .cityCon {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.agreementCityAddressCon .inputCon1 {
  width: 250px;
  margin-left: 7px;
}

.agreementHandShakeIconCon {
  width: 100%;
  text-align: center;
}
.agreementHandShakeIconCon .icon {
  font-size: 50px;
}

.agreementCityCon {
  width: 170px;
}
.agreementAddressCon {
  width: 400px;
}

.agreementCityAddressCon .addressCon {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.agreementCityAddressCon .inputCon2 {
  width: 250px;
  margin-left: 7px;
}

.agreementKitchen,
.agreementBathroom,
.agreementBedroom,
.agreementAppartNum,
.agreementResidentType1 {
  display: flex;
  margin-left: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}

.agreementKitchen .inputCon1,
.agreementBathroom .inputCon1,
.agreementBedroom .inputCon1,
.agreementResidentType1 .inputCon1 {
  width: 250px;
  margin-left: 7px;
}

.agreementResidentType1 {
  margin-top: 100px;
}

.agreementAppartNum .inputCon1 {
  width: 200px;
  margin-left: 7px;
}

.theAddressOfMyAgreementList {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}
